import { Box } from "@mui/material";
import React from "react";
import PDButton from "src/components/_pdStyle/PDButton";

export default function FullScreenDialogActionButtons({
  activeStep,
  setActiveStep,
  loading,
  actionButtonClick,
  actionButtonLabel,
}) {
  return (
    <Box
      sx={{
        // position: "fixed",
        bottom: 0,
        width: "100%",
        maxWidth: 400,
        display: "grid",
        gridTemplateColumns: activeStep !== 0 ? "150px 1fr 150px" : "150px",
        justifyContent: activeStep !== 0 ? "space-between" : "center",
        gap: 2,
        py: 2,
      }}
    >
      {activeStep !== 0 && (
        <>
          <PDButton
            variant="outline"
            onClick={() => setActiveStep((e) => (e > 0 ? e - 1 : 0))}
          >
            Back
          </PDButton>
          <Box />
        </>
      )}

      {actionButtonClick ? (
        <PDButton loading={loading} onClick={() => actionButtonClick()}>
          {actionButtonLabel || "Next"}
        </PDButton>
      ) : (
        <PDButton loading={loading} type="submit">
          {actionButtonLabel || "Next"}
        </PDButton>
      )}
    </Box>
  );
}
