import { useContext } from "react";
import { Box } from "@mui/material";
import { ThemeContext } from "src/context";
import Header from "src/components/header/Header";
import CPanelSidebar from "./CPanelSidebar";
import CPanelBottomBar from "./CPanelBottomBar";

export default function CPanelContainer(props) {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "rows",
        backgroundColor: "rgba(256,0,0,.1)",
      }}
    >
      {!bp?.md && <CPanelSidebar variant="seller" />}

      <Box
        sx={{
          width: "100%",
          minHeight: "max(100vh, 750px)",
          height: "100%",

          backgroundColor: theme.backgroundColor,
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
        }}
      >
        <Header variant="seller" title={props.title} />

        <Box
          sx={{
            ...props.sx,
            width: "100%",
            maxWidth: "max(90vw, 1500px)",
            p: 2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          {props.children}
        </Box>
      </Box>

      {bp?.md && <CPanelBottomBar />}
    </Box>
  );
}
