import {
  Avatar,
  Box,
  Fade,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useState } from "react";
import SetupStoreDialog from "src/dialogs/setupStoreDialog/SetupStoreDialog";
import { useSnackbar } from "notistack";
import httpClient from "src/libs/httpClient";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import PDSelect from "src/components/_pdStyle/PDSelect";
import ActionDialog from "src/dialogs/ActionDialog";
import isURL from "src/libs/isURL";

const styles = {
  tableCell: {
    p: 0,
    py: 1,

    pl: 1,
  },
};

export default function UsersTable({ stores, getData, loading, perPage }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", border: `1px ${theme.borderColor} solid` }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: "#f2f2f2",
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 54,
                borderRadius: ".25rem",
                backgroundColor: "#f2f2f2",
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {stores?.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Email</TableCell>

                    <TableCell align="center">Role</TableCell>

                    <TableCell align="center">Is Active</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stores.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        key={index}
                        sx={{
                          height: 57,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.backgroundColor,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={row?._id} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "50px 20px",
                  border: "1px #dee2e6 solid",
                }}
              >
                <p style={{ textAlign: "center" }}>Users list is empty.</p>
              </div>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const [showSetupStoreDialog, setShowSetupStoreDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const [emailVerified, setEmailVerified] = useState(row.emailVerified);
  const [isActive, setIsActive] = useState(row.isActive);
  const [role, setRole] = useState({
    value: row.role,
    label: capitalizeWords(row.role),
  });

  const updateState = (e) => {
    if (e.hasOwnProperty("emailVerified")) {
      setEmailVerified(e.emailVerified);
    }
    if (e.hasOwnProperty("isActive")) {
      setIsActive(e.isActive);
    }
    if (e.hasOwnProperty("role")) {
      setRole({
        value: e.role,
        label: capitalizeWords(e.role),
      });
    }

    setLoading(true);
    httpClient()
      .put(`/admin/users/${row._id}`, e)
      .then((res) => {
        enqueueSnackbar(`User status update successfully`, {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        if (e.hasOwnProperty("emailVerified")) {
          setEmailVerified(row.emailVerified);
        }
        if (e.hasOwnProperty("isActive")) {
          setIsActive(row.isActive);
        }
        if (e.hasOwnProperty("role")) {
          setRole({
            value: row.role,
            label: capitalizeWords(row.role),
          });
        }

        console.log(err);
        enqueueSnackbar(`Faild to update user`, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    httpClient()
      .delete(`/admin/users/${row._id}`)
      .then((res) => {
        enqueueSnackbar(`User deleted successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(`Faild to delete user`, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  return loading ? (
    <TableRow
      sx={{
        height: 57,
        backgroundColor: "#f2f2f2",
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.backgroundColor,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.backgroundColor },
        }}
      >
        <TableCell component="th" scope="row" sx={styles.tableCell}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              src={
                isURL(row?.avatar)
                  ? row?.avatar
                  : process.env.REACT_APP_S3_URL + row?.avatar
              }
              alt={row.name}
              sx={{ fontWeight: 600 }}
            >
              {row.firstName[0]}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: theme.textColor,
                  padding: 0,
                }}
              >
                {`${row.firstName} ${row.lastName}`}
              </Typography>
              <Typography
                variant="p"
                sx={{ color: theme.textColor3, padding: 0 }}
              >
                @{row.userName}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left" sx={styles.tableCell}>
          {row.email}

          <IconButton
            sx={{ ml: 1 }}
            onClick={() => updateState({ emailVerified: !row.emailVerified })}
          >
            <VerifiedRoundedIcon
              sx={{ fill: emailVerified ? theme.buttonColor : "red" }}
            />
          </IconButton>
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          {["super", "admin"].includes(user?.role) ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PDSelect
                sx={{ maxWidth: 200 }}
                required
                defaultValue={{
                  value: row.role,
                  label: capitalizeWords(row.role),
                }}
                options={rolesOptions.map((o) => {
                  return { value: o, label: capitalizeWords(o) };
                })}
                value={role}
                onChange={(e) => {
                  updateState({ role: e.value });
                }}
              />
            </Box>
          ) : (
            capitalizeWords(row.role)
          )}
        </TableCell>
        <TableCell align="center" sx={{ ...styles.tableCell }}>
          {/* isActive */}
          <Switch
            checked={isActive}
            onChange={(e) => updateState({ isActive: e.target.checked })}
          />
        </TableCell>

        <TableCell align="right" sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mr: 1,
            }}
          >
            <IconButton onClick={() => setShowConfirmButton((e) => !e)}>
              <DeleteRoundedIcon color="error" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      <SetupStoreDialog
        open={showSetupStoreDialog}
        setOpen={setShowSetupStoreDialog}
        store={row}
        getData={getData}
      />

      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Delete User"
        description={`Are you sure you want to delete this user? This action is irreversible.`}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete();
          setShowConfirmButton(false);
        }}
      />
    </>
  );
};

const rolesOptions = [
  "super",
  "admin",
  "seller",
  "manager",
  "employee",
  "buyer",
];
