import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";

export default function ActionDialog({
  open,
  setOpen,

  title,
  content,
  description,

  primaryButtonOnClick,
  primaryButtonLabel,
  primaryButtonLoading,
  primaryButtonType,

  secondaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonLoading,
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        {title || "title"}
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        {content ? (
          content
        ) : (
          <Typography
            variant="h5"
            fontWeight={400}
            sx={{ color: theme.textColor2 }}
          >
            {description || "description"}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            loading={secondaryButtonLoading}
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => {
              secondaryButtonOnClick
                ? secondaryButtonOnClick()
                : setOpen((e) => !e);
            }}
          >
            {secondaryButtonLabel || "Cancel"}
          </PDButton>
          <PDButton
            disabled={primaryButtonLoading}
            loading={primaryButtonLoading}
            type={primaryButtonType || "submit"}
            onClick={() => {
              if (primaryButtonOnClick) primaryButtonOnClick();

              // primaryButtonOnClick ? primaryButtonOnClick() : () => {};
            }}
          >
            {primaryButtonLabel || "Save"}
          </PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
