import { AuthContext, ThemeContext } from "src/context";
import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import ViewStatisticsSection from "./_components/ViewStatisticsSection";
import CPanelContainer from "../../../components/CPanelContainer";

export default function AdminDashboardPage() {
  const { user } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  return (
    <CPanelContainer title="Dashboard" sx={{ p: 1 }}>
      <Box sx={{ pb: 2.5 }}>
        <Typography
          variant="h5"
          sx={{ color: theme.textColor2, fontWeight: 400 }}
        >
          Welcome Back,
        </Typography>
        <Typography
          variant="h2"
          sx={{ color: theme.textColor, fontWeight: 600 }}
        >
          {`${user?.firstName} ${user?.lastName} `}👏🏻
        </Typography>
      </Box>

      <ViewStatisticsSection />
    </CPanelContainer>
  );
}
