import { useContext, useEffect, useState } from "react";
import { Box, Checkbox, Fade, Typography } from "@mui/material";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import ActionButtons from "../../../components/FullScreenDialogActionButtons";
import httpClient from "src/libs/httpClient";
import PDSelect from "src/components/_pdStyle/PDSelect";
import { useSnackbar } from "notistack";

export default function DeliveryDetailsStep({
  open,
  store,
  setStore,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [selectedDeliveryProvider, setSelectedDeliveryProvider] = useState(
    deliveryProvider[1]
  );
  const [selectedDeliveryProviderError, setSelectedDeliveryProviderError] =
    useState(false);
  const [isOutsideCityDeliveryEnabled, setIsOutsideCityDeliveryEnabled] =
    useState(false);

  useEffect(() => {
    if (open && store) {
      setSelectedDeliveryProvider({
        label: store?.delivery?.deliveryProvider,
        value: store?.delivery?.deliveryProvider,
      });
      setValue("insideCityDeliveryFee", store?.delivery?.insideCityDeliveryFee);
      setIsOutsideCityDeliveryEnabled(
        store?.delivery?.isOutsideCityDeliveryEnabled
      );
      setValue(
        "outsideCityDeliveryFee",
        store?.delivery?.outsideCityDeliveryFee
      );
    }
  }, [open, store]);

  const onSubmit = (data) => {
    const dataToSend = {
      delivery: {
        ...data,
        selectedDeliveryProvider: selectedDeliveryProvider.value,
        isOutsideCityDeliveryEnabled: isOutsideCityDeliveryEnabled,
      },
    };

    setLoading(true);
    httpClient()
      .put(`/sellers/stores/${store?._id}`, dataToSend)
      .then((res) => {
        setActiveStep((e) => e + 1);
        setStore(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocalShippingRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          How do you want to deliver?
        </Typography>

        <PDSelect
          required
          label="Delivery Provider"
          placeholder="Select type"
          defaultValue={deliveryProvider[1]}
          options={deliveryProvider}
          //
          value={selectedDeliveryProvider}
          onChange={(e) => {
            setSelectedDeliveryProvider(e);
            setSelectedDeliveryProviderError(false);
          }}
          error={selectedDeliveryProviderError}
          helperText={
            selectedDeliveryProviderError && "Delivery provider is required"
          }
        />

        <PDInput
          type="number"
          required
          label="Delivery Fee (Inside City)"
          placeholder="60 BDT"
          form={{
            ...register("insideCityDeliveryFee", {
              required: {
                value: true,
                message: "Delivery fee is required",
              },
              max: {
                value: 1000,
                message: "Fee must be less than or equal to 1,000 BDT",
              },
              min: {
                value: 60,
                message: "Fee must be greater than or equal to 60 BDT",
              },
            }),
          }}
          error={errors.insideCityDeliveryFee}
          helperText={errors?.insideCityDeliveryFee?.message}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h6" fontWeight={600} color={theme.textColor2}>
            Delivery Outside Dhaka
          </Typography>

          <Checkbox
            sx={{ mr: -1.5 }}
            checked={isOutsideCityDeliveryEnabled}
            onChange={(e) => setIsOutsideCityDeliveryEnabled(e.target.checked)}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Fade in={isOutsideCityDeliveryEnabled}>
            <Box sx={{ width: "100%" }}>
              <PDInput
                type="number"
                required
                label="Delivery Fee (Outside City)"
                placeholder="120 BDT"
                form={{
                  ...register("outsideCityDeliveryFee", {
                    required: {
                      value: isOutsideCityDeliveryEnabled,
                      message: "Delivery fee is required",
                    },
                    max: {
                      value: 1000,
                      message: "Fee must be less than or equal to 1,000 BDT",
                    },
                    min: {
                      value: 60,
                      message: "Fee must be greater than or equal to 60 BDT",
                    },
                  }),
                }}
                error={errors.outsideCityDeliveryFee}
                helperText={errors?.outsideCityDeliveryFee?.message}
              />
            </Box>
          </Fade>
        </Box>
      </Box>

      <ActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
      />
    </Box>
  );
}

const deliveryProvider = [
  { label: "personal", value: "personal" },
  { label: "redx", value: "redx" },
  { label: "pathao", value: "pathao" },
  { label: "dokan.gg", value: "dokan.gg" },
];
