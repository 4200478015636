// import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { createContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import httpClient from "src/libs/httpClient";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import addToObject from "src/libs/addToObject";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  // const router = useRouter();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState();
  const [stores, setStores] = useState();
  const [role, setRole] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    setLoadingCheck(true);

    const accessToken = await localStorage.getItem("accessToken");
    const userId = await localStorage.getItem("userId");

    if (!accessToken || !userId) {
      setLoadingCheck(false);
      setIsAuthenticated(false);
      console.log("No token or userId found in localStorage.");
      return;
    }

    httpClient()
      .get(`/users/${userId}`)
      .then((res) => {
        saveUserData(res.data);
        setLoadingCheck(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR from ToggleAuth : ", err);
        enqueueSnackbar("Fail to login.", { variant: "error" });

        logout();
        setIsAuthenticated(false);
        setLoadingCheck(false);
        setLoading(false);
      });
  };

  const saveUserData = async (user) => {
    setLoading(true);
    try {
      setUser(user);
      setStores(user?.stores);
      setRole(user.role);
      setToken(user.accessToken);
      setIsAuthenticated(true);

      // gtag("config", "TAG_ID", {
      //   user_id: user?._id,
      // });

      user.accessToken &&
        (await localStorage.setItem("accessToken", user.accessToken));
      await localStorage.setItem("userId", user._id || user.id);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const loginWithEmail = (email, password, dontRedirect) => {
    setLoading(true);
    httpClient()
      .post("/auth/login", { email, password })
      .then((res) => res.data)
      .then((user) => {
        if (user?._id) {
          if (user.role === "buyer") {
            logout();
            enqueueSnackbar(`You don't have access to this website.`, {
              variant: "error",
            });
          } else {
            saveUserData(user);
            enqueueSnackbar(
              `Welcome, ${user?.firstName} ${user?.lastName || ""}`,
              {
                variant: "success",
              }
            );

            navigate("/");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("err : ", err);

        if (err?.response?.data?.redirect) {
          navigate("/signup/confirm");
          enqueueSnackbar(
            removeUndefinedFromString(err?.response?.data?.message),
            "error"
          );
        } else {
          // "You have entered an invalid email or password",
          enqueueSnackbar(
            removeUndefinedFromString(err?.response?.data?.message),
            { variant: "error" }
          );
        }
        setLoading(false);
      });
  };

  const signUpWithEmail = async (
    email,
    password,
    firstName,
    lastName,
    role
  ) => {
    setLoading(true);
    httpClient()
      .post("/auth/signup", {
        firstName,
        lastName,
        role,
        email,
        password,
      })
      .then((res) => res.data)
      .then((user) => {
        // saveUserData(user);
        // enqueueSnackbar(`Welcome, ${user?.firstName || user?.email}`, {
        //   variant: "info",
        // });
        navigate(`/signup/confirm?email=${email}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          {
            variant: "error",
          }
        );
        setLoading(false);
      });
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      setLoading(true);

      let data = {
        access_token: credentialResponse.access_token,
      };

      if (pathname.includes("seller")) {
        data = addToObject(data, "role", "seller");
      }

      httpClient()
        .post(`/auth/google`, data)
        .then((res) => {
          if (res.data.role === "buyer") {
            logout();
            enqueueSnackbar(`You don't have access to this website.`, {
              variant: "error",
            });
          } else {
            saveUserData(res.data);
            enqueueSnackbar(
              `Welcome back, ${
                res.data?.firstName + " " + res.data?.lastName ||
                res.data?.email
              }`,
              { variant: "success" }
            );
            navigate("/");
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            removeUndefinedFromString(err?.response?.data?.message),
            { variant: "error" }
          );
          setLoading(false);
        });
    },
    onError: () => {
      console.log("Login Failed");
      enqueueSnackbar("Google auth faild.", {
        variant: "error",
      });
      setLoading(false);
    },
  });

  const signInWithFacebook = (f) => {
    setLoading(true);
    httpClient()
      .post(`/auth/facebook`, f)
      .then((res) => {
        if (res.data.role === "buyer") {
          logout();
          enqueueSnackbar(`You don't have access to this website.`, {
            variant: "error",
          });
        } else {
          saveUserData(res.data);
          enqueueSnackbar(
            `Welcome back, ${
              res.data?.firstName + " " + res.data?.lastName || res.data?.email
            }`,
            { variant: "success" }
          );
          navigate("/");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
        setLoading(false);
      });
  };

  const forgotPassword = ({ email }) => {
    console.log(email);
    setLoading(true);
    httpClient()
      .post("/auth/forgotPassword/request", { email })
      .then((res) => {
        console.log(res.data);
        enqueueSnackbar("Email sent successfully. Please check your inbox.", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
        setLoading(false);
      });
  };

  const newPassword = ({ token, password }) => {
    setLoading(true);
    httpClient()
      .post(`/auth/forgotPassword/${token}`, { password })
      .then((res) => {
        console.log(res.data);
        saveUserData(res.data);
        enqueueSnackbar(`Welcome, ${user?.firstName} ${user?.lastName}`, {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
        setLoading(false);
      });
  };

  const logout = async () => {
    setLoading(true);
    try {
      console.log("LOGINNG OUT!");
      await localStorage.removeItem("accessToken");
      await localStorage.removeItem("userId");

      setUser();

      navigate("/login");
    } catch (e) {
      console.log("Unable to remove user from localStorage.", e);
      setLoading(false);
    } finally {
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  const value = {
    loadingCheck,
    loading,

    isAuthenticated,
    user,
    stores,
    role,
    token,

    saveUserData,

    checkAuth,
    loginWithEmail,
    signUpWithEmail,
    signInWithGoogle,
    signInWithFacebook,
    forgotPassword,
    newPassword,
    logout,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
