import PDButton from "src/components/_pdStyle/PDButton";

import { useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { Box } from "@mui/material";
import AddBrandDialog from "src/dialogs/AddBrandDialog";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AdminBrandItem from "./_components/BrandItem";
import CPanelContainer from "src/components/CPanelContainer";

export default function BrandsPage() {
  const [showBrandDialog, setShowBrandDialog] = useState(false);

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [searchDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    httpClient()
      .get(`/admin/brands`)
      .then((res) => {
        setBrands(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <CPanelContainer title="Brands" sx={{ p: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* <PDInput
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ backgroundColor: theme.ui }}
          /> */}
          <PDButton
            sx={{ maxWidth: 150 }}
            startIcon={<AddRoundedIcon />}
            onClick={() => setShowBrandDialog((e) => !e)}
          >
            Add Brand
          </PDButton>
        </Box>

        <Box>
          {brands?.map((brand, index) => (
            <AdminBrandItem
              key={brand?._id}
              curIndex={index}
              brand={brand}
              brandsRow={brands}
              getData={getData}
            />
          ))}
        </Box>

        {/*  */}
      </CPanelContainer>

      <AddBrandDialog
        open={showBrandDialog}
        setOpen={setShowBrandDialog}
        getData={getData}
        brandsRow={brands}
      />
    </>
  );
}
