import { useState, useContext } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";

import deletePhoto from "src/libs/deletePhoto";
import { ThemeContext } from "src/context";
import AddCategoryDialog from "src/dialogs/AddCategoryDialog";
import ActionDialog from "src/dialogs/ActionDialog";
import httpClient from "src/libs/httpClient";

export default function CategoryItem({
  category,
  categoriesRow,
  getData,
  curIndex,
  showMiniView,
}) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const [showMore, setShowMore] = useState(true);

  const handleDelete = (category) => {
    setLoading(true);

    if (category.icon) {
      deletePhoto(category?.icon)
        .then((res) => {
          // console.log(res.data);
          httpClient()
            .delete(`/admin/categories/${category?._id}`)
            .then((res) => {
              console.log(res.data);
              setLoading(false);
              getData();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      httpClient()
        .delete(`/admin/categories/${category?._id}`)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          getData();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          ml: category?.parent ? 5 : 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.ui,
            border: `1px ${theme.borderColor} solid`,
            // borderRadius: 1,

            borderTop: curIndex !== 0 && "none",
            // borderBottom: curIndex === 1 && "none",

            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {category?.children?.length > 0 && (
              <IconButton
                sx={{
                  transform: showMore ? "rotate(-90deg)" : "rotate(0deg)",
                  transition: "all 0.2s ease-in-out",
                  color: theme.buttonColor,
                }}
                onClick={() => setShowMore((e) => !e)}
              >
                <KeyboardArrowDownRoundedIcon />
              </IconButton>
            )}

            {!showMiniView && (
              <Avatar
                sx={{ borderRadius: 1 }}
                src={process.env.REACT_APP_S3_URL + category?.icon}
                alt={category?.name}
              />
            )}

            <Box>
              <Typography
                sx={{
                  color: theme.textColor,
                  fontWeight: 500,
                  lineHeight: 1,
                }}
                variant="h6"
              >
                {category?.name}
              </Typography>
              {!showMiniView && (
                <Typography
                  sx={{ color: theme.textColor3, fontWeight: 400 }}
                  variant="p"
                >
                  {category?.slug}
                </Typography>
              )}
            </Box>
          </Box>

          {!showMiniView && (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              {loading && <CircularProgress thickness={5} size={18} />}

              <IconButton
                sx={{ color: theme.buttonColor }}
                onClick={() => setShowCategoryDialog(true)}
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>

              <IconButton
                sx={{ color: theme.accent.red }}
                onClick={() => setShowConfirmButton(true)}
              >
                <DeleteRoundedIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>

        <Collapse in={showMore} mountOnEnter unmountOnExit>
          <Box>
            {category?.children?.map((c) => (
              <CategoryItem
                // curIndex={index}
                key={c?._id}
                category={c}
                categoriesRow={categoriesRow}
                getData={getData}
              />
            ))}
          </Box>
        </Collapse>
      </Box>

      <AddCategoryDialog
        open={showCategoryDialog}
        setOpen={setShowCategoryDialog}
        getData={getData}
        categoriesRow={categoriesRow}
        currentCategory={category}
      />

      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Delete Category"
        description={`Are you sure you want to delete this category? This action is irreversible.`}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete(category);
          setShowConfirmButton(false);
        }}
      />
    </>
  );
}
