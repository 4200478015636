import {
  Box,
  //  Skeleton,
  Typography,
} from "@mui/material";
import {
  useContext,
  //  useState, useEffect
} from "react";
import { AuthContext, ThemeContext } from "src/context";
import CPanelContainer from "../../../components/CPanelContainer";
// import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
// import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import ListRoundedIcon from "@mui/icons-material/ListRounded";
// import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
// import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
// import httpClient from "src/libs/httpClient";

export default function SellerDashboardPage() {
  const { user } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  return (
    <CPanelContainer title="Dashboard">
      <Box sx={{ py: 2.5 }}>
        <Typography
          variant="h5"
          sx={{ color: theme.textColor2, fontWeight: 400 }}
        >
          Welcome Back,
        </Typography>
        <Typography
          variant="h2"
          sx={{ color: theme.textColor, fontWeight: 600 }}
        >
          {`${user?.firstName} ${user?.lastName} `}👏🏻
        </Typography>
      </Box>

      {/* <TopCards /> */}
    </CPanelContainer>
  );
}

// const TopCards = () => {
//   const { theme } = useContext(ThemeContext);

//   const [loading, setLoading] = useState(false);
//   const [card, setCard] = useState();

//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = () => {
//     setLoading(true);
//     httpClient()
//       .get("/sellers/dashboard")
//       .then((res) => {
//         setCard(res.data);
//         setLoading(false);
//       })
//       .catch((err) => {
//         console.log(err);
//         setLoading(false);
//       });
//   };

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         display: "grid",
//         gridTemplateColumns: `repeat(auto-fill, minmax(250px, 1fr))`,
//         gap: 2,
//       }}
//     >
//       <CardItem
//         icon={
//           <SavingsRoundedIcon
//             fontSize="small"
//             sx={{ fill: theme.accent.red }}
//           />
//         }
//         color={theme.accent.red}
//         label="Total Revenue"
//         value={`৳${card?.totalRevenue.toLocaleString()}`}
//         loading={loading}
//       />
//       <CardItem
//         icon={
//           <Groups2RoundedIcon
//             fontSize="small"
//             sx={{ fill: theme.accent.yellow }}
//           />
//         }
//         color={theme.accent.yellow}
//         label="Total Customers"
//         value={card?.totalCustomers.toLocaleString()}
//         loading={loading}
//       />

//       <CardItem
//         icon={
//           <StoreRoundedIcon
//             fontSize="small"
//             sx={{ fill: theme.accent.orange }}
//           />
//         }
//         color={theme.accent.orange}
//         label="Total Stores"
//         value={card?.totalStores.toLocaleString()}
//         loading={loading}
//       />

//       <CardItem
//         icon={
//           <ShoppingBagIcon fontSize="small" sx={{ fill: theme.accent.green }} />
//         }
//         color={theme.accent.green}
//         label="Total Products"
//         value={card?.totalProducts.toLocaleString()}
//         loading={loading}
//       />

//       <CardItem
//         icon={
//           <ListRoundedIcon
//             fontSize="small"
//             sx={{ fill: theme.accent.purple }}
//           />
//         }
//         color={theme.accent.purple}
//         label="Total Orders"
//         value={card?.totalOrders.toLocaleString()}
//         loading={loading}
//       />
//     </Box>
//   );
// };

// const CardItem = ({ icon, color, label, value, loading }) => {
//   const { theme } = useContext(ThemeContext);

//   return (
//     <Box
//       sx={{
//         backgroundColor: theme.ui,
//         p: 2,
//         borderRadius: 1,
//         display: "flex",
//         flexDirection: "column",
//         gap: 2,
//       }}
//     >
//       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//         <Box
//           sx={{
//             backgroundColor: color + 33,
//             height: 35,
//             width: 35,
//             borderRadius: 20,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           {icon}
//         </Box>
//         <Typography
//           variant="h6"
//           fontWeight={500}
//           sx={{ color: theme.textColor3 }}
//         >
//           {label}
//         </Typography>
//       </Box>

//       {loading ? (
//         <Skeleton variant="rectangular" sx={{ mt: 0.5, height: 33 }} />
//       ) : (
//         <Typography
//           variant="h2"
//           fontWeight={700}
//           sx={{ color: theme.textColor }}
//         >
//           {value}
//         </Typography>
//       )}
//     </Box>
//   );
// };
