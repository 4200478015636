import { Box } from "@mui/material";
import { useContext, useState } from "react";

import PDButton from "src/components/_pdStyle/PDButton";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";

export default function FinishStep({ setOpen, readyForReview, _id, type }) {
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const handleSubmitForReview = () => {
    setLoading(true);

    httpClient()
      .post(`/sellers/${type}s/${_id}/submitForReview/`)
      .then((res) => {
        console.log(res.data);
        setOpen((e) => !e);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",

          justifyContent: "center",
          height: "100%",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/3153/3153346.png"
          alt="success"
          style={{ height: 300 }}
        />
      </Box>

      <Box sx={{ py: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        {!readyForReview && (
          <PDButton
            loading={loading}
            sx={{ width: 300 }}
            onClick={() => handleSubmitForReview()}
          >
            Submit for review
          </PDButton>
        )}

        <PDButton
          sx={{
            width: 300,
            backgroundColor: theme.borderColor,
            color: theme.textColor2,
          }}
          onClick={() => setOpen((e) => !e)}
        >
          Finish
        </PDButton>
      </Box>
    </Box>
  );
}
