import {
  Box,
  ButtonBase,
  Checkbox,
  Chip,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import orderStatusOptions from "src/data/orderStatusOptions";
import statusToColor from "src/libs/statusToColor";
import httpClient from "src/libs/httpClient";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import paymentMethodOptions from "src/data/paymentMethodOptions";
import { useSnackbar } from "notistack";
import LoadingPage from "src/components/LoadingPage";
import ActionDialog from "src/dialogs/ActionDialog";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import CPanelContainer from "../../../components/CPanelContainer";
import { useParams } from "react-router-dom";
import OrderDetails from "./_components/OrderDetails";

const customOrderStatusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Processing",
    value: "processing",
  },
  {
    label: "Local Facility",
    value: "localFacility",
  },
  {
    label: "Out For Delivery",
    value: "outForDelivery",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Canceled",
    value: "canceled",
  },
];

export default function OrderPage() {
  // const {
  //   query: { invoiceNumber },
  // } = useRouter();
  let { invoiceNumber } = useParams();

  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();

  useEffect(() => {
    if (invoiceNumber && !order) {
      setLoading(true);
      getData();
    }
  }, [invoiceNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    httpClient()
      .get(`/sellers/orders/${invoiceNumber}`)
      .then((res) => {
        setOrder(res?.data);
        console.log(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return loading ? (
    <LoadingPage fullSize={true} />
  ) : (
    <CPanelContainer
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ color: theme.textColor3 }}
          >
            Order ID:
          </Typography>

          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            {order?.invoiceNumber}
          </Typography>
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ color: theme.textColor }}
          >
            Order Status:
          </Typography>

          <Chip
            label={
              orderStatusOptions?.find((o) => o.value === order?.status)?.label
            }
            sx={{
              backgroundColor: statusToColor(order?.status) + 11,
              color: statusToColor(order?.status),
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ color: theme.textColor }}
          >
            Payment Status:
          </Typography>

          <Tooltip title={order?.payment?.hasPaid ? "Paid" : "Unpaid"}>
            <Chip
              label={
                paymentMethodOptions?.find(
                  (o) => o.value === order?.payment.paymentMethod
                )?.label
              }
              sx={{
                backgroundColor:
                  statusToColor(order?.payment.paymentMethod) + 11,
                color: statusToColor(order?.payment.paymentMethod),
              }}
            />
          </Tooltip>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        {customOrderStatusOptions?.map((option, index) => (
          <StatusOptionItem
            key={index}
            curIndex={index}
            option={option}
            customOrderStatusOptions={customOrderStatusOptions}
            order={order}
            getData={getData}
          />
        ))}
      </Box>

      <OrderDetails
        selectedOrder={order?._id}
        getData={getData}
        isSeller={true}
      />
    </CPanelContainer>
  );
}

const StatusOptionItem = ({
  curIndex,
  option,
  customOrderStatusOptions,
  order,
  getData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);

  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const [isDisabled, setIsDisabled] = useState();
  const [addToStock, setAddToStock] = useState(true);

  useEffect(() => {
    if (order?.status)
      setIsDisabled(
        ["failed", "refunded", "canceled", "onHold", "pendingPayment"].includes(
          order?.status
        )
          ? true
          : false
      );
  }, [order]);

  const [mouseIn, setMouseIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateStatus = (data) => {
    setLoading(true);
    httpClient()
      .put(`/sellers/orders/${order?._id}`, { status: data.value })
      .then((res) => {
        console.log(res.data);

        enqueueSnackbar(`Order updated successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
        setShowConfirmButton(false);
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message) ||
            `Faild to update order`,
          { variant: "error" }
        );

        // setSelectedState(
        //   orderStatusOptions.find((o) => o.value === order?.status)
        // );
        setLoading(false);
        setShowConfirmButton(false);
      });
  };

  return (
    <>
      <ButtonBase
        disabled={isDisabled}
        disableRipple
        sx={{
          p: 2,
          transition: "all .2s ease-in-out",
          backgroundColor:
            curIndex === 0
              ? isDisabled
                ? "#eff0f6"
                : order?.status === option?.value
                ? theme.buttonColor
                : mouseIn
                ? "#dde3fd"
                : "#eaedfd"
              : "transparent",
          overflow:
            curIndex === customOrderStatusOptions?.length - 1
              ? "hidden"
              : "none",
          width: curIndex === 0 ? "calc(100% - 150px)" : "100%",

          "&:before": {
            content: `""`,
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transition: "all .2s ease-in-out",
            backgroundColor: isDisabled
              ? "#eff0f6"
              : order?.status === option?.value
              ? theme.buttonColor
              : mouseIn
              ? "#dde3fd"
              : "#eaedfd",
            transform: `skew(-30deg) translateX(${
              curIndex === customOrderStatusOptions?.length - 1 ? 15 : 15
            }px)`,
            transformOrigin: "left",
          },
        }}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
        onClick={() => setShowConfirmButton(true)}
      >
        <Typography
          variant="h6"
          fontWeight={600}
          sx={{
            color: isDisabled
              ? theme.textColor3 + 99
              : order?.status === option?.value
              ? theme.buttonTextColor
              : theme.buttonColor,
            zIndex: 2,
          }}
        >
          {option?.label}
        </Typography>
      </ButtonBase>

      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Change Status"
        description={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span>
              Are you sure you want to update this order status to &quot;
              {option?.label}&quot; ?
            </span>

            {option?.value === "canceled" && (
              <FormControlLabel
                sx={{ mr: 0 }}
                control={
                  <Checkbox
                    checked={addToStock}
                    onChange={(e) => setAddToStock(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{ color: theme.textColor2 }}
                  >
                    Add items back to stock
                  </Typography>
                }
              />
            )}
          </Box>
        }
        primaryButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          updateStatus(option);
          setShowConfirmButton(false);
        }}
      />
    </>
  );
};
