import { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { AuthContext, ThemeContext } from "src/context";
import { Logout, Settings } from "@mui/icons-material";
import Person4RoundedIcon from "@mui/icons-material/Person4Rounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import StoreMallDirectoryRoundedIcon from "@mui/icons-material/StoreMallDirectoryRounded";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";

import isURL from "src/libs/isURL";
import { Link } from "react-router-dom";

export default function ProfileMenu() {
  const { theme, bp } = useContext(ThemeContext);
  const { logout, user, isAuthenticated } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.error = () => {};

  return (
    <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <IconButton
        // onMouseOver={handleClick}
        onClick={handleClick}
        size="small"
        sx={{ ml: 2, p: user?.avatar && 0 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {user?.avatar ? (
          <Avatar
            src={
              isURL(user?.avatar)
                ? user?.avatar
                : process.env.REACT_APP_S3_URL + user?.avatar
            }
            alt={user?.firstName}
          />
        ) : (
          <Person4RoundedIcon sx={{ color: theme.textColor }} />
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: 225,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user?.stores?.length > 0 && (
          <>
            {user?.stores?.map((store, index) => (
              <Box key={index}>
                <Link to={`/${store?.slug}`}>
                  <MenuItem onClick={handleClose}>
                    <Avatar
                      sx={{ objectFit: "contain" }}
                      src={process.env.REACT_APP_S3_URL + store?.logo}
                      alt={store?.name}
                    >
                      <StoreMallDirectoryRoundedIcon />
                    </Avatar>{" "}
                    {store?.name || "Add Store"}
                  </MenuItem>
                </Link>
              </Box>
            ))}
            <Divider sx={{ my: 1 }} />
          </>
        )}

        <MenuItem onClick={handleClose} disableRipple>
          <Avatar
            src={
              isURL(user?.avatar)
                ? user?.avatar
                : process.env.REACT_APP_S3_URL + user?.avatar
            }
          />{" "}
          {user?.firstName ? `${user?.firstName} ${user?.lastName}` : "Profile"}
        </MenuItem>

        {/* 
          {["super", "admin"]?.includes(user?.role) && (
            <Link to="/admin">
              <MenuItem onClick={handleClose}>
                <Avatar
                  sx={{
                    backgroundColor: theme.buttonColor + 22,
                    color: theme.buttonColor + "bb",
                  }}
                >
                  <DashboardRoundedIcon fontSize="smaill" />
                </Avatar>
                Admin
              </MenuItem>
            </Link>
          )} 
        */}

        <Divider sx={{ my: 1 }} />

        <Link to="/settings">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
