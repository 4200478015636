import PDButton from "src/components/_pdStyle/PDButton";

import { useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { Box } from "@mui/material";
import AddCategoryDialog from "src/dialogs/AddCategoryDialog";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CategoryItem from "./_components/CategoryItem";
import CPanelContainer from "src/components/CPanelContainer";

export default function CategoriesPage() {
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);

  const [showMiniView, setShowMiniView] = useState(false);

  const [categories, setCategories] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [searchDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    httpClient()
      .get(`/admin/categories`)
      .then((res) => {
        console.log(sortCategories(res?.data));
        setCategories([]);
        setSortedCategories([]);

        setCategories(res?.data);
        setSortedCategories(sortCategories(res?.data));
        // setSortedCategories(res?.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <CPanelContainer title="Categories" sx={{ p: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* <PDInput
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ backgroundColor: theme.ui }}
          /> */}
          <PDButton
            sx={{ maxWidth: 150 }}
            startIcon={<AddRoundedIcon />}
            onClick={() => setShowCategoryDialog((e) => !e)}
          >
            Add Category
          </PDButton>

          <PDButton
            sx={{ maxWidth: 150 }}
            // startIcon={<AddRoundedIcon />}
            onClick={() => setShowMiniView((e) => !e)}
          >
            {showMiniView ? "Show Full View" : "Show Mini View"}
          </PDButton>
        </Box>

        <Box>
          {sortedCategories?.map((category, index) => (
            <CategoryItem
              key={category?._id}
              curIndex={index}
              category={category}
              categoriesRow={categories}
              getData={getData}
              showMiniView={showMiniView}
            />
          ))}
        </Box>

        {/*  */}
      </CPanelContainer>

      <AddCategoryDialog
        open={showCategoryDialog}
        setOpen={setShowCategoryDialog}
        getData={getData}
        categoriesRow={categories}
      />
    </>
  );
}

function sortCategories(categories) {
  const sortedCategories = [];
  const parentCategories = {};

  // Create a dictionary of categories using their IDs as keys
  const categoriesDict = {};
  categories.forEach((category) => {
    categoriesDict[category._id] = category;
  });

  // Group child categories under their respective parent categories
  categories.forEach((category) => {
    const parentId = category.parent?._id || category.parent;
    if (parentId) {
      parentCategories[parentId] = parentCategories[parentId] || [];
      parentCategories[parentId].push(category);
    } else {
      sortedCategories.push(category);
    }
  });

  // Sort the parent categories and their child categories recursively
  function sortRecursive(category) {
    const children = parentCategories[category._id] || [];
    category.children = children.sort((a, b) => a.name.localeCompare(b.name));
    category.children.forEach(sortRecursive);
  }

  sortedCategories.forEach(sortRecursive);

  return sortedCategories;
}
