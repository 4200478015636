import { Box, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";
import ProfileMenu from "./_components/ProfileMenu";
import NotificationMenu from "./_components/NotificationMenu";
import { Link } from "react-router-dom";
import logo from "src/assets/logo.svg";

export default function Header({ title }) {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <>
      <Box
        sx={{
          minHeight: 60,
          // minHeight: 100,
          py: 1,
          width: "100%",
          backgroundColor: theme.ui || theme.backgroundColor,
          borderBottom: `1px rgba(256,256,256, .15) solid`,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            px: 2,
            width: "100%",
            maxWidth: "max(90vw, 1500px)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {bp?.md && (
              <Link
                to="/"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ height: 25 }} src={logo} alt="Poket Dokan" />
              </Link>
            )}

            <Typography
              variant="h3"
              sx={{ color: theme.textColor, fontWeight: 600 }}
            >
              {title}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <NotificationMenu />

            {/* 
              <IconButton>
                <Badge badgeContent={0} color="primary">
                  <NotificationsRoundedIcon />
                </Badge>
              </IconButton> 
            */}

            <ProfileMenu />
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{ backgroundColor: "rgba(255,255,255,.15)", width: "100%" }}
      />
    </>
  );
}
