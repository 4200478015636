import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { ThemeContext } from "src/context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function JobStatisticsOverviewTab({
  viewType,
  jobView,
  jobApplied,
}) {
  const max580 = useMediaQuery("(max-width:580px)");
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ display: "flex", flexDirection: max580 ? "column" : "row" }}>
      <div style={{ flex: 1 }}>
        <div style={{ paddingBottom: 20 }} />
        {!jobView ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{
              margin: "20px 20px 0 20px",
              maxWidth: "calc(100% - 40px)",
              height: 360,
            }}
          />
        ) : (
          <CustomChart
            viewType={viewType}
            jobView={jobView}
            jobApplied={jobApplied}
          />
        )}

        <div
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            padding: 20,
          }}
        >
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <div
              style={{
                backgroundColor: theme.accent.yellow,
                width: 20,
                height: 20,
                borderRadius: 5,
              }}
            />
            <Typography
              variant="button_small"
              style={{ color: theme.textColor2 }}
            >
              Job Views
            </Typography>
          </div>

          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "rgb(123, 97, 255, 1)",
                width: 20,
                height: 20,
                borderRadius: 5,
              }}
            />
            <Typography
              variant="button_small"
              style={{ color: theme.textColor2 }}
            >
              Job Applied
            </Typography>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: 20,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <DataBox
          label="Job Views"
          value={
            0 ||
            jobView?.reduce((acc, cur) => {
              return acc + cur.count;
            }, 0)
          }
          icon={
            <RemoveRedEyeOutlinedIcon
              style={{
                color: "#FFF",
                fontSize: "1.25rem",
              }}
            />
          }
          iconColor="#FFB836"
        />
        <DataBox
          label="Job Applied"
          value={jobApplied?.reduce((acc, cur) => {
            return acc + cur.count;
          }, 0)}
          icon={<ListIcon />}
          iconColor="rgb(123, 97, 255, 1)"
        />
      </div>
    </div>
  );
}

const CustomChart = ({ viewType, jobView, jobApplied }) => {
  const options = {
    plugins: {
      title: {
        display: false,
        text: "",
      },
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      gridLines: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
        display: false,
      },
    },
  };

  const data = {
    labels:
      viewType === 7
        ? jobView?.map((item) => moment(item.date).format("ddd"))
        : jobView?.map((item) => item.date),
    datasets: [
      {
        label: "Job Applied",
        data: jobApplied?.map((item) => item.count),
        backgroundColor: "rgb(123, 97, 255, .75)",
      },
      {
        label: "Job View",
        data: jobView?.map((item) => item.count),
        backgroundColor: "rgb(255, 184, 54, 1.75)",
      },
    ],
  };

  console.log({ viewType }, { jobView });

  return viewType && jobView ? (
    <Bar options={options} data={data} />
  ) : (
    <div>Failed to load VIEW TYPE AND JOB VIEW!</div>
  );
};

export const DataBox = ({ label, value, icon, iconColor }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        border: `1px ${theme?.brand?.secondary} solid`,
        width: "100%",
        padding: 20,

        display: "flex",
        justifyContent: "space-between",
        minWidth: 200,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          paddingTop: 5,
        }}
      >
        <Typography variant="display_2" sx={{ fontSize: 18 }}>
          {label}
        </Typography>

        <Typography variant="display_2" sx={{ fontSize: 32 }}>
          {value?.toLocaleString()}
        </Typography>

        <Typography variant="display_2" sx={{ fontSize: 16, fontWeight: 400 }}>
          This Week
        </Typography>
      </div>

      <div>
        <div
          style={{
            backgroundColor: iconColor,
            height: 30,
            width: 30,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 25,
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export const ListIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_772_39244)">
      <path
        d="M7.49984 4.1665H5.83317C5.39114 4.1665 4.96722 4.3421 4.65466 4.65466C4.3421 4.96722 4.1665 5.39114 4.1665 5.83317V15.8332C4.1665 16.2752 4.3421 16.6991 4.65466 17.0117C4.96722 17.3242 5.39114 17.4998 5.83317 17.4998H14.1665C14.6085 17.4998 15.0325 17.3242 15.345 17.0117C15.6576 16.6991 15.8332 16.2752 15.8332 15.8332V5.83317C15.8332 5.39114 15.6576 4.96722 15.345 4.65466C15.0325 4.3421 14.6085 4.1665 14.1665 4.1665H12.4998"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8333 2.5H9.16667C8.24619 2.5 7.5 3.24619 7.5 4.16667C7.5 5.08714 8.24619 5.83333 9.16667 5.83333H10.8333C11.7538 5.83333 12.5 5.08714 12.5 4.16667C12.5 3.24619 11.7538 2.5 10.8333 2.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 10H7.50833"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8335 10H12.5002"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 13.3335H7.50833"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8335 13.3335H12.5002"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_772_39244">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
