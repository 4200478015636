import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import uploadPhoto from "src/libs/uploadPhoto";
import slugify from "src/libs/slugify";
import httpClient from "src/libs/httpClient";
import addToObject from "src/libs/addToObject";

export default function AddBrandDialog({
  open,
  setOpen,
  getData,
  currentBrand,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [icon, setIcon] = useState([]);

  const name = useRef({});
  name.current = watch("name", "");

  useEffect(() => {
    if (currentBrand && open) {
      setValue("name", currentBrand?.name);
    }
  }, [currentBrand, open]);

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    if (icon.length > 0) {
      handleUploadImage(data.name);
    } else {
      handleAddBrand(data.name);
    }
  };

  const handleUploadImage = async (name) => {
    await uploadPhoto(icon[0], `brands/${slugify(name)}`, {
      maxWidth: 512,
      maxHeight: 512,
    })
      .then((data) => {
        setLoading(true);
        handleAddBrand(name, data);
      })
      .catch((err) => {
        console.error("Error uploading photos: ", err);
        setLoading(false);
        // Handle the error here
      });
  };

  const handleAddBrand = (name, iconUrl) => {
    let data = {
      name,
      slug: slugify(name),
    };

    if (iconUrl) {
      data = addToObject(data, "icon", iconUrl);
    }

    setLoading(true);

    if (currentBrand) {
      httpClient()
        .put(`/admin/brands/${currentBrand?._id}`, data)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setOpen(false);
          reset();
          setIcon([]);
          getData();
          enqueueSnackbar(
            "Brand added successfully. Please allow us to review your request.",
            { variant: "info" }
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            err?.response?.data?.message || "Faild to add brand",
            {
              variant: "error",
            }
          );
        });
    } else {
      httpClient()
        .post(`/admin/brands`, data)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setOpen(false);
          reset();
          setIcon([]);
          getData();
          enqueueSnackbar(
            "Brand added successfully. Please allow us to review your request.",
            { variant: "info" }
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            err?.response?.data?.message || "Faild to add brand",
            {
              variant: "error",
            }
          );
        });
    }
  };

  // function handleDelete(url) {
  //   deletePhoto(url?.icon || url?.coverArt)
  //     .then((data) => {
  //       setLoading(true);

  //       httpClient()
  //         .put(
  //           `sellers/stores/${store?._id}`,
  //           url?.icon ? { icon: "" } : { coverArt: "" }
  //         )
  //         .then((res) => {
  //           console.log(res);
  //           setStore(res.data);
  //           setLoading(false);
  //           enqueueSnackbar(`Image deleted successfully`, {
  //             variant: "success",
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setLoading(false);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err, err.stack);
  //     });
  // }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        {`${currentBrand ? "Update" : "Add"} Brand`}
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        {/*  */}
        <PDDropzone
          label="Logo"
          maxFiles={1}
          value={icon}
          setValue={setIcon}
          // oldFiles={store?.icon && [store?.icon]}
          oldFiles={[]}
          // // fileToDelete={handleDelete}
          fileToDelete={(e) => {}}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <PDInput
            required
            label="Name"
            placeholder="Enter name here"
            form={{ ...register("name", { required: true }) }}
            error={errors.name}
            helperText={errors.name ? "Name is required" : ""}
          />

          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ color: theme.textColor2, fontWeight: 500 }}
            >
              Slug:
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: theme.textColor3, fontWeight: 400 }}
            >
              {slugify(name.current || "")}
            </Typography>
          </Box>
        </Box>
        {/*  */}
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => {
              setOpen((e) => !e);
            }}
          >
            Cancel
          </PDButton>
          <PDButton loading={loading} type="submit">
            {`${currentBrand ? "Update" : "Add"}`}
          </PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
