import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import { ThemeContext, AuthContext } from "src/context";
import httpClient from "src/libs/httpClient";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";

export default function ChangePasswordSection() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { theme, bp } = useContext(ThemeContext);
  const { user, checkAuth } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleChangePassword = (data) => {
    setLoading(true);

    console.log(data);

    httpClient()
      .post(`/users/changePassword`, data)
      .then((res) => {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        setLoading(false);
        checkAuth();
        reset();
      })
      .catch((err) => {
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message) || "",
          { variant: "error" }
        );
        console.log("ERROR from MyProfileSection : ", err.message);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        border: `1px ${theme.borderColor} solid`,
        borderRadius: 1,
        p: 2,
        overflow: "hidden",
        width: "100%",
        backgroundColor: theme.ui,
      }}
    >
      <Typography variant="h3" fontWeight={600} sx={{ color: theme.textColor }}>
        Change Password
      </Typography>

      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        component="form"
        onSubmit={handleSubmit(handleChangePassword)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: bp?.sm ? "column" : "row",
            gap: bp?.sm ? 3 : 2,
          }}
        >
          {user?.password && (
            <PDInput
              required
              type="password"
              label="Old Password"
              placeholder="Enter password here"
              form={{ ...register("oldPassword", { required: true }) }}
              error={errors.oldPassword}
              helperText={errors.oldPassword ? "Password is required" : ""}
            />
          )}
          <PDInput
            required
            type="password"
            label="New Password"
            placeholder="Enter password here"
            form={{ ...register("newPassword", { required: true }) }}
            error={errors.newPassword}
            helperText={errors.newPassword ? "Password is required" : ""}
          />
        </Box>

        <PDButton
          disabled={loading}
          type="submit"
          style={{ maxWidth: 150 }}
          loading={loading}
        >
          Save Changes
        </PDButton>
      </Box>
    </Box>
  );
}
