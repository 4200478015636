import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";

import LoadingPage from "./components/LoadingPage";

// Main Router

// const Blog = React.lazy(() => import('./Pages/Blog')))

import ProductsPage from "./pages/productsPage/ProductsPage";
import StoresPage from "./pages/StoresPage/StoresPage";
import UsersPage from "./pages/admin/usersPage/UsersPage";
import CategoriesPage from "./pages/admin/categoriesPage/CategoriesPage";
import BrandsPage from "./pages/admin/brandsPage/BrandsPage";
import OrdersPage from "./pages/orders/ordersPage/OrdersPage";
import OrderPage from "./pages/orders/orderPage/OrderPage";

// Root Router
import LoginPage from "./pages/auth/LoginPage";
import ConfirmEmailPage from "./pages/auth/signup/ConfirmEmailPage";
import SellerSignupPage from "./pages/auth/signup/SellerSignupPage";
import SellerDashboardPage from "./pages/dashboard/sellerDashboardPage/SellerDashboardPage";
import AdminDashboardPage from "./pages/dashboard/adminDashboardPage/AdminDashboardPage";
import SettingsPage from "./pages/settingsPage/SettingsPage";
import Custom404Page from "./pages/errorPages/Custom404Page";

export default function MyRouter() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {isAuthenticated === null ? (
        <LoadingPage fullSize={true} />
      ) : isAuthenticated ? (
        <MainRouter />
      ) : (
        <RootRouter />
      )}
    </>
  );
}

const MainRouter = () => {
  const { user } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          user?.role === "seller" ? (
            <SellerDashboardPage />
          ) : (
            <AdminDashboardPage />
          )
        }
      />
      <Route exact path="/orders" element={<OrdersPage />} />
      <Route exact path="/orders/:invoiceNumber" element={<OrderPage />} />

      <Route exact path="/stores" element={<StoresPage />} />
      <Route exact path="/products" element={<ProductsPage />} />

      <Route exact path="/users" element={<UsersPage />} />
      <Route exact path="/categories" element={<CategoriesPage />} />
      <Route exact path="/brands" element={<BrandsPage />} />

      <Route exact path="/settings" element={<SettingsPage />} />

      <Route exact path="/build" element={<BuildPage />} />
      <Route path="*" element={<Custom404Page />} />
    </Routes>
  );
};

const RootRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />

      <Route exact path="/signup" element={<SellerSignupPage />} />
      <Route exact path="/signup/confirm" element={<ConfirmEmailPage />} />

      <Route exact path="/login" element={<LoginPage />} />

      <Route exact path="/build" element={<BuildPage />} />
      <Route path="*" element={<Custom404Page />} />
    </Routes>
  );
};

const BuildPage = () => {
  return (
    <div>
      <p>Build Number: 1.0.3</p>
    </div>
  );
};
