import { cache, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import ActionButtons from "../../../components/FullScreenDialogActionButtons";
import httpClient from "src/libs/httpClient";
import PDSelect from "src/components/_pdStyle/PDSelect";
import { useSnackbar } from "notistack";
import capitalizeWords from "src/libs/capitalizeWords";

import PDButton from "src/components/_pdStyle/PDButton";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import { useDebounce } from "use-debounce";
import slugify from "src/libs/slugify";
import MDEditor from "@uiw/react-md-editor";

export default function BusinessDetailsStep({
  open,
  store,
  setStore,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [showSocialLinksDialog, setShowSocialLinksDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [socialLinks, setSocialLinks] = useState();

  const [selectedType, setSelectedType] = useState(storeType[1]);
  const [selectedTypeError, setSelectedTypeError] = useState(false);

  useEffect(() => {
    if (open && store) {
      setValue("name", store?.name);
      setDescription(store?.description);
      setSocialLinks(store?.socialLinks);

      setSelectedType({
        label: capitalizeWords(store?.type),
        value: store?.type,
      });

      setSlug(store?.slug);
    }
  }, [open, store]);

  const onSubmit = (data) => {
    if (!selectedType) {
      setSelectedTypeError(true);
      return;
    }
    if (description?.length <= 0) {
      enqueueSnackbar("Description is required", { variant: "error" });
      return;
    }

    setLoading(true);
    if (store) {
      httpClient()
        .put(`/sellers/stores/${store?._id}`, {
          ...data,
          slug: slug,
          description,
          socialLinks,
          type: selectedType.value,
        })
        .then((res) => {
          setStore(res.data);
          setLoading(false);
          setActiveStep((e) => e + 1);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            removeUndefinedFromString(err?.response?.data?.message),
            { variant: "error" }
          );
        });
    } else {
      httpClient()
        .post(`/sellers/stores`, {
          ...data,
          slug: slug,
          description,
          socialLinks,
          type: selectedType.value,
        })
        .then((res) => {
          setActiveStep((e) => e + 1);
          console.log(data);
          setLoading(false);
          setStore(res.data);
          // setNewStore(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            removeUndefinedFromString(err?.response?.data?.message),
            { variant: "error" }
          );
        });
    }
  };

  // slug
  const [loadingSlug, setLoadingSlug] = useState(false);
  const [slug, setSlug] = useState("");
  const [slugDebounce] = useDebounce(slug, 500);
  const [isSlugAvailable, setIsSlugAvailable] = useState(null);

  useEffect(() => {
    slug.length > 2 && handleSlug();
  }, [slugDebounce]);

  const handleSlug = () => {
    setLoadingSlug(true);
    httpClient()
      .get(`/sellers/stores/checkSlug/${slug}`)
      .then((res) => {
        if (res.data.slug) {
          if (res.data.slug === store?.slug) {
            setIsSlugAvailable(true);
          } else {
            setIsSlugAvailable(false);
          }
        } else {
          setIsSlugAvailable(true);
        }

        setLoadingSlug(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSlugAvailable(false);
        setLoadingSlug(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 600,
          width: "100%",
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          mb: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.backgroundColor,
              color: theme.textColor,
              border: `1px ${theme.borderColor} solid`,
              borderRadius: 1,
              minHeight: 50,
              height: 50,
              minWidth: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StoreRoundedIcon sx={{ color: theme.buttonColor }} />
          </Box>
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Tell as about your busness!
          </Typography>
          <PDInput
            required
            label="Store Name"
            placeholder="Enter your store name here"
            form={{ ...register("name", { required: true }) }}
            error={errors.name}
            helperText={errors.name ? "Name is required" : ""}
          />

          <PDInput
            required
            label="Store URL"
            placeholder="/abc-xyz"
            value={slug}
            onChange={(e) => {
              setSlug(slugify(e.target.value));
            }}
            error={!isSlugAvailable && "error"}
            helperText={
              (loadingSlug && "Loading...") ||
              (!isSlugAvailable && "URL Taken. Try another url")
            }
          />

          <PDSelect
            required
            label="Store Type"
            placeholder="Select type"
            defaultValue={storeType[1]}
            options={storeType}
            //
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e);
              setSelectedTypeError(false);
            }}
            error={selectedTypeError}
            helperText={selectedTypeError && "Type is required"}
          />

          <Box sx={{ width: "100%" }}>
            <Button
              startIcon={<AddRoundedIcon />}
              onClick={() => setShowSocialLinksDialog((e) => !e)}
            >
              Add Social Links
            </Button>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.textColor2, fontWeight: 600 }}
              >
                Description:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: "#bc002d",
                }}
              >
                *
              </Typography>
            </Box>

            <MDEditor
              style={{
                color: theme.textColor,
                backgroundColor: theme.backgroundColor,
                borderRadius: 4,
              }}
              value={description}
              onChange={setDescription}
              height="250px"
              visibleDragbar={false}
              hideToolbar={true}
              preview="edit"
            />
          </Box>
        </Box>

        <ActionButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          loading={loading}
        />
      </Box>

      <SocialLinksDialog
        open={showSocialLinksDialog}
        setOpen={setShowSocialLinksDialog}
        socialLinks={socialLinks}
        setSocialLinks={setSocialLinks}
      />
    </>
  );
}

const SocialLinksDialog = ({ open, setOpen, socialLinks, setSocialLinks }) => {
  const { theme } = useContext(ThemeContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (open && socialLinks) {
      setValue("facebook", socialLinks?.facebook || "");
      setValue("instagram", socialLinks?.instagram || "");
      setValue("whatsApp", socialLinks?.whatsApp || "");
      setValue("twitter", socialLinks?.twitter || "");
      setValue("tiktok", socialLinks?.tiktok || "");
      setValue("linkedin", socialLinks?.linkedin || "");
      setValue("website", socialLinks?.website || "");
    }
  }, [open, socialLinks]);

  const onSubmit = (data) => {
    setSocialLinks(data);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
      //
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        Social Links
      </DialogTitle>
      <DialogContent
        sx={{
          px: 2,
          pb: 3,

          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <PDInput
          label="Facebook URL"
          placeholder="Facebook URL"
          form={{
            ...register("facebook", {
              required: false,

              validate: (value) => {
                if (value === "" || value.includes("facebook.com/")) {
                  return true;
                } else {
                  return "Invalid Facebook page URL";
                }
              },
            }),
          }}
          error={errors?.facebook}
          helperText={errors?.facebook?.message}
        />
        <PDInput
          label="Instagram URL"
          placeholder="Instagram URL"
          form={{
            ...register("instagram", {
              required: false,

              validate: (value) => {
                if (value === "" || value.includes("instagram.com/")) {
                  return true;
                } else {
                  return "Invalid Instagram page URL";
                }
              },
            }),
          }}
          error={errors?.instagram}
          helperText={errors?.instagram?.message}
        />
        <PDInput
          label="WhatsApp URL"
          placeholder="WhatsApp URL"
          form={{
            ...register("whatsApp", {
              required: false,

              pattern: {
                value: /^(?:(?:\+|00)88|01)?\d{11}$/,
                message:
                  "The number must start with 01 and be 11 characters long",
              },
              maxLength: {
                value: 11,
                message: "The maximum length is 11",
              },
            }),
          }}
          error={errors?.whatsApp}
          helperText={errors?.whatsApp?.message}
        />
        <PDInput
          label="Twitter URL"
          placeholder="Twitter URL"
          form={{
            ...register("twitter", {
              required: false,

              validate: (value) => {
                if (value === "" || value.includes("twitter.com/")) {
                  return true;
                } else {
                  return "Invalid Twitter page URL";
                }
              },
            }),
          }}
          error={errors?.twitter}
          helperText={errors?.twitter?.message}
        />
        <PDInput
          label="Tiktok URL"
          placeholder="Tiktok URL"
          form={{
            ...register("tiktok", {
              required: false,

              validate: (value) => {
                if (value === "" || value.includes("tiktok.com/")) {
                  return true;
                } else {
                  return "Invalid Tiktok page URL";
                }
              },
            }),
          }}
          error={errors?.tiktok}
          helperText={errors?.tiktok?.message}
        />
        <PDInput
          label="Linkedin URL"
          placeholder="Linkedin URL"
          form={{
            ...register("linkedin", {
              required: false,

              validate: (value) => {
                if (value === "" || value.includes("linkedin.com/in/")) {
                  return true;
                } else {
                  return "Invalid Linkedin page URL";
                }
              },
            }),
          }}
          error={errors?.linkedin}
          helperText={errors?.linkedin?.message}
        />
        <PDInput
          label="Website URL"
          placeholder="Website URL"
          form={{
            ...register("website", {
              required: false,

              validate: (value) => {
                const urlPattern =
                  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$/;
                if (value === "" || urlPattern.test(value)) {
                  return true;
                } else {
                  return "Invalid URL";
                }
              },
            }),
          }}
          error={errors?.website}
          helperText={errors?.website?.message}
        />
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => setOpen((e) => !e)}
          >
            Cancel
          </PDButton>
          <PDButton type="submit">Save</PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const storeType = [
  { label: "Physical", value: "physical" },
  { label: "Facebook", value: "facebook" },
  { label: "Instagram", value: "instagram" },
  { label: "WhatsApp", value: "whatsApp" },
  { label: "Twitter", value: "twitter" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Website", value: "website" },
  { label: "Others", value: "others" },
];
