import { useContext } from "react";
import moment from "moment";

import { Box, Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from "@mui/icons-material/Inventory";
import ListRoundedIcon from "@mui/icons-material/ListRounded";

import { ThemeContext } from "src/context";
import { Link } from "react-router-dom";

export default function NotificationItems({ notification, boxSx }) {
  const { theme } = useContext(ThemeContext);

  const menuItemStyle = {
    color: theme.textColor,
    padding: "0px",
    transition: "all .2s ease-in-out",
    textDecoration: "none",
    textTransform: "none",
  };

  return (
    <Box
      sx={{
        ...menuItemStyle,
        width: "100%",
        backgroundColor: notification?.seen
          ? theme.ui
          : theme.buttonColor + "1f",
        display: "flex",
        flexDirection: "column",
        ...boxSx,
        "&:hover": {
          backdropFilter: "",
        },
        borderRadius: 0,
      }}
      component={Button}
      disableRipple={true}
    >
      {(notification?.type === "system" && (
        <NotiBtn
          to="#"
          icon={<SettingsIcon sx={{ color: theme.buttonColor }} />}
          createdAt={notification?.createdAt}
          message={notification?.content?.message}
        />
      )) ||
        (notification?.type === "product" &&
          ["super", "admin"].includes(notification?.receiverRole) && (
            <NotiBtn
              to={`/admin/products`}
              icon={<InventoryIcon sx={{ color: theme.buttonColor }} />}
              createdAt={notification?.createdAt}
              message={notification?.content?.message}
            />
          )) ||
        (notification?.type === "store" &&
          ["super", "admin"].includes(notification?.receiverRole) && (
            <NotiBtn
              to={`/admin/stores`}
              icon={<InventoryIcon sx={{ color: theme.buttonColor }} />}
              createdAt={notification?.createdAt}
              message={notification?.content?.message}
            />
          )) ||
        (notification?.type === "order" &&
          ["super", "admin"].includes(notification?.receiverRole) && (
            <NotiBtn
              to="/admin/orders"
              icon={<ListRoundedIcon sx={{ color: theme.buttonColor }} />}
              createdAt={notification?.createdAt}
              message={notification?.content?.message}
            />
          ))}
    </Box>
  );
}

const NotiBtn = ({ to, icon, createdAt, message }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Link to={to || "#"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          //   minWidth: "100%",
          minWidth: 360,
          width: "100%",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.buttonColor + "1f",
            minWidth: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
          }}
        >
          {icon}
        </Box>

        <div
          style={{
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",

            color: theme.textColor2,
          }}
        >
          {message}

          <h5
            style={{
              color: theme.textColor3,
              fontWeight: 500,
              fontSize: ".7rem",
              textAlign: "left",
            }}
          >
            {createdAt && moment(createdAt).fromNow(true)} ago
          </h5>
        </div>
      </div>
    </Link>
  );
};
