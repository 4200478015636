import Compressor from "compressorjs";
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
});

const uploadPhotos = (files, dir, config) => {
  return new Promise((resolve, reject) => {
    let imagePairs = [];

    files.forEach((image, index) => {
      new Compressor(image, {
        size: 1,
        quality: 0.8,
        ...config,
        success(largeResult) {
          new Compressor(image, {
            size: 1,
            quality: 0.8,
            maxWidth: 300,
            maxHeight: 300,
            ...config,
            success(smallResult) {
              imagePairs.push({ large: largeResult, small: smallResult });

              if (index === files.length - 1) {
                let uploadPromises = [];
                let locations = [];

                imagePairs.forEach(({ large, small }, index) => {
                  const baseFileName = generateFileName(image);
                  const largeFileName = `large/${baseFileName}`;
                  const smallFileName = `thumb/${baseFileName}`;

                  const largeParams = createS3Params(dir, largeFileName, large);
                  const smallParams = createS3Params(dir, smallFileName, small);

                  uploadPromises.push(s3.upload(largeParams).promise());
                  uploadPromises.push(s3.upload(smallParams).promise());

                  locations.push({
                    large: largeParams.Key,
                    thumb: smallParams.Key,
                  });
                });

                Promise.all(uploadPromises)
                  .then(() => resolve(locations))
                  .catch((error) => reject(error));
              }
            },
            error(error) {
              reject(error);
            },
          });
        },
        error(error) {
          reject(error);
        },
      });
    });
  });
};

function generateFileName(image, suffix = "") {
  return (
    (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase() +
    suffix +
    "." +
    image?.name.slice(image?.name.lastIndexOf(".") + 1)
  );
}

function createS3Params(dir, fileName, body) {
  return {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: dir ? `${dir}/${fileName}` : fileName,
    Body: body,
  };
}

export default uploadPhotos;
