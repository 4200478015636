import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export default function ConnectedAccountsSection() {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  return user?.googleId || user?.facebookId ? (
    <Box
      sx={{
        border: `1px ${theme.borderColor} solid`,
        borderRadius: 1,
        p: 2,
        overflow: "hidden",
        width: "100%",
        height: "100%",
        backgroundColor: theme.ui,
      }}
    >
      <Typography variant="h3" fontWeight={600} sx={{ color: theme.textColor }}>
        Connected Accounts
      </Typography>

      <Box
        sx={{
          mt: 2,
          gap: 2,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {user?.googleId && (
          <ConnectItem
            logo="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            name="Google"
          />
        )}

        {user?.facebookId && (
          <ConnectItem
            logo="https://upload.wikimedia.org/wikipedia/en/0/04/Facebook_f_logo_%282021%29.svg"
            name="Facebook"
          />
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
}

const ConnectItem = ({ logo, name }) => {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        border: `1px solid ${theme.textColor3 + "80"}`,
        borderRadius: 10,
        padding: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        // maxWidth: 300,
        width: "100%",
        // maxWidth: bp.sm ? "100%" : 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ width: 25, height: 25 }}
          src={logo}
          alt={`${name} logo`}
        />

        <Typography
          variant="h6"
          sx={{
            marginLeft: 1,
            color: theme.textColor,
            fontWeight: 600,
          }}
        >
          {name} is Connected
        </Typography>
      </Box>

      <Tooltip title={`Your ${name} account is connected.`}>
        <InfoRoundedIcon style={{ color: theme.textColor, opacity: 0.25 }} />
      </Tooltip>
    </Box>
  );
};
