import { useContext, useEffect, useState } from "react";

import { Box, Menu, Badge, IconButton, Typography } from "@mui/material";

import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";

import { ThemeContext } from "src/context/ThemeContext";
import { AuthContext } from "src/context/AuthContext";

import httpClient from "src/libs/httpClient";

import NotificationItems from "./NotificationItems";
import ActionDialog from "src/dialogs/ActionDialog";

export default function NotificationMenu() {
  const { theme, bp } = useContext(ThemeContext);
  const { logout, isAuthenticated } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);

  // Get Notifications
  useEffect(() => {
    console.log("Initial setInterval");
    setLoading(true);
    getNotifications();

    let interval;
    interval = setInterval(
      () => {
        console.log("In setInterval");
        getNotifications();
      },
      process.env.REACT_APP_ENV !== "production" ? 300 * 1000 : 60 * 1000
    );

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotifications = () => {
    if (!isAuthenticated) return;

    httpClient()
      .get(`/notifications?limit=10`)
      .then((res) => {
        // console.log("getNotifications :", res.data);

        if (res.data !== "No Token found") {
          setNotifications(res?.data ? res?.data : []);

          let count = 0;
          res?.data?.map((item) => {
            if (!item.seen) {
              count++;
            }
          });
          setUnseenCount(count);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("Error from notifications : ", err.message);
        setLoading(false);
      });
  };

  // Mark as seen
  useEffect(() => {
    if (anchorEl) {
      handleMarkAsSeen();
    } else {
      console.log("Loading Notifications...");
      getNotifications();
    }
  }, [anchorEl]);
  const handleMarkAsSeen = () => {
    setLoading(true);
    httpClient()
      .post(`/notifications`, {
        ids: notifications?.map((noti) => noti?._id),
      })
      .then((res) => {
        // console.log("handleMarkAsSeen :", res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error from handleMarkAsSeen : ", err.message);
        setLoading(false);
      });
  };

  return (
    <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: bp?.sm ? 0 : 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Badge badgeContent={unseenCount} color="error">
          <NotificationsRoundedIcon sx={{ color: theme.textColor2 }} />
        </Badge>
      </IconButton>

      <Menu
        className="dropdownMenu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     overflow: "visible",
        //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //     // mt: 1.5,
        //     mt: 0.75,
        //     minWidth: 325,

        //     maxWidth: 380,
        //     maxHeight: 450,

        //     backgroundColor: theme.ui,
        //     overflowY: "auto",

        //     "& .MuiAvatar-root": {
        //       width: 32,
        //       height: 32,
        //       ml: -0.5,
        //       mr: 1,
        //     },
        //     "&:before": {
        //       content: '""',
        //       display: "block",
        //       position: "absolute",
        //       top: 0,
        //       right: 14,
        //       width: 10,
        //       height: 10,
        //       bgcolor: "background.paper",
        //       transform: "translateY(-50%) rotate(45deg)",
        //       zIndex: 0,
        //     },
        //   },
        // }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: 325,
            maxWidth: 380,
            maxHeight: 450,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}

        // MenuListProps={{ "aria-labelledby": "basic-button" }}
        // PaperProps={{
        //   style: {
        //     borderRadius: theme.borderRadius,
        //     marginTop: -10,
        //     marginLeft: 10,
        //     width: "100%",
        //     maxWidth: 350,

        //     // minHeight: 400,
        //     border: `1px solid rgba(0,0,0,.25)`,
        //     backgroundColor: theme.ui,
        //   },
        // }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 15px 0px 15px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.textColor, fontWeight: 500 }}
          >
            Notifications
          </Typography>
        </Box>

        {notifications?.length > 0 ? (
          notifications?.map((notification, index) => (
            <NotificationItems
              key={index}
              notification={notification}
              handleClose={handleClose}
            />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 350,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: theme.textColor3 }}>No notifications</p>
          </Box>
        )}

        {/* {notifications?.length > 9 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              sx={{
                textTransform: "none",
                borderRadius: theme.borderRadius,
                transition: "all .2s ease-in-out",
                "&:hover": {
                  backdropFilter: "contrast(90%)",
                },
              }}
              // component={Link}
              to="/notifications"
            >
              <Button
                style={{
                  color: theme.buttonColor,
                  fontWeight: 600,
                  fontWeight: 500,
                  textTransform: "none",
                  paddingInline: 20,
                  borderRadius: theme.borderRadius,
                }}
              >
                See more
              </Button>
            </Link>
          </Box>
        )} */}
      </Menu>

      <ActionDialog
        open={showAlertDialog}
        setOpen={setShowAlertDialog}
        label="Logout"
        description="Are you sure you want to logout?"
        actionButtonLabel="Logout"
        onClick={() => logout()}
      />
    </div>
  );
}
