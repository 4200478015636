const statusToColor = (status) => {
  let color;

  if (status === "refunded") {
    color = "#bc002d";
  } else if (status === "canceled") {
    color = "#7C8493";
  } else if (status === "onHold") {
    color = "#FF6550";
  } else if (status === "shipped") {
    color = "#26A4FF";
  } else if (status === "delivered") {
    color = "#4640DE";
  } else if (status === "completed") {
    color = "#023c29";
  } else if (status === "processing") {
    color = "#4640DE";
  } else if (status === "failed") {
    color = "#bc002d";
  } else if (status === "pendingPayment") {
    color = "#FF6550";
  } else if (status === "delete") {
    color = "#7C8493";
  } else if (status === "active") {
    color = "#26A4FF";
  } else if (status === "reviewing") {
    color = "#FFB836";
  } else if (status === "pending") {
    color = "#4640DE";
  }

  return color;
};

export default statusToColor;
