import {
  Avatar,
  Box,
  Chip,
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import statusToColor from "src/libs/statusToColor";
import SetupStoreDialog from "src/dialogs/setupStoreDialog/SetupStoreDialog";
import httpClient from "src/libs/httpClient";
import PDMenu from "src/components/_pdStyle/PDMenu";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import { useSnackbar } from "notistack";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import PDSelect from "src/components/_pdStyle/PDSelect";
import PDButton from "src/components/_pdStyle/PDButton";

const styles = {
  tableCell: {
    p: 0,
    py: 1,

    pl: 1,
  },
};

export default function StoresTable({ stores, getData, loading, perPage }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: `1px ${theme.borderColor} solid`,
        borderTop: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        mb: 2,
      }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: "#f2f2f2",
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 56,
                borderRadius: ".25rem",
                backgroundColor: "#f2f2f2",
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {stores?.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>Name</TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      Type
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Owner
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      State
                    </TableCell>
                    <TableCell align="right" sx={styles.tableCell} />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stores.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        key={index}
                        sx={{
                          height: 57,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.backgroundColor,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={index} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  py: 10,
                  px: 2,
                }}
              >
                <Typography align="center" sx={{ color: theme.textColor3 }}>
                  You don&apos;t have any store. Please create one.
                </Typography>
              </Box>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const [showSetupStoreDialog, setShowSetupStoreDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    value: row?.status,
    label: capitalizeWords(row?.status),
  });
  const [isArchived, setIsArchived] = useState(row?.isArchived);
  const [isDeleted, setIsDeleted] = useState(row?.isDeleted);

  const handleChangeStatus = (e) => {
    setLoading(true);

    if (e?.hasOwnProperty("isArchived")) {
      setIsArchived(e?.isArchived);
    }
    if (e?.hasOwnProperty("isDeleted")) {
      setIsDeleted(e?.isDeleted);
    }
    if (e?.hasOwnProperty("status")) {
      setStatus({
        value: e?.status,
        label: capitalizeWords(e?.status),
      });
    }

    httpClient()
      .put(`/sellers/stores/${row?._id}`, e)
      .then((res) => {
        console.log(res.data);
        enqueueSnackbar(`Status updated successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );

        if (e?.hasOwnProperty("isArchived")) {
          setIsArchived(row?.isArchived);
        }
        if (e?.hasOwnProperty("isDeleted")) {
          setIsDeleted(row?.isDeleted);
        }
        if (e?.hasOwnProperty("status")) {
          setStatus({
            value: row?.status,
            label: capitalizeWords(row?.status),
          });
        }

        getData();
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);

    httpClient()
      .delete(`/sellers/stores/${row?._id}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [loadingSubmitForReview, setLoadingSubmitForReview] = useState(false);
  const handleSubmitForReview = () => {
    setLoadingSubmitForReview(true);

    httpClient()
      .post(`/sellers/stores/${row?._id}/submitForReview`)
      .then((res) => {
        console.log(res.data);
        setLoadingSubmitForReview(false);
        enqueueSnackbar(`Review requested successfully`, {
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoadingSubmitForReview(false);
      });
  };

  return loading ? (
    <TableRow
      sx={{
        height: 57,
        backgroundColor: "#f2f2f2",
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.backgroundColor,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.backgroundColor },
        }}
      >
        <TableCell component="th" scope="row" sx={styles.tableCell}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              src={process.env.REACT_APP_S3_URL + row?.logo}
              alt={row.name}
              sx={{ fontWeight: 600, borderRadius: 1 }}
            >
              {row.name[0]}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <a
                href={
                  row?.status === "active"
                    ? `https://dokan.gg/stores/${row?.slug}`
                    : "#"
                }
                target="_blank"
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: theme.textColor,
                    padding: 0,
                  }}
                >
                  {row.name}
                </Typography>
              </a>
              <Typography
                variant="p"
                sx={{ color: theme.textColor3, padding: 0 }}
              >
                @{row.slug}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {capitalizeWords(row.type)}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {row.owner?.email}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {["admin", "super"].includes(user?.role) ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PDSelect
                sx={{ maxWidth: 200 }}
                required
                options={statusOptions.map((o) => {
                  return { value: o, label: capitalizeWords(o) };
                })}
                value={status}
                onChange={(e) => {
                  setStatus(e);
                  handleChangeStatus({ status: e.value });
                }}
              />
            </Box>
          ) : !row?.readyForReview && row?.status !== "active" ? (
            <PDButton
              loading={loadingSubmitForReview}
              sx={{ width: 150 }}
              onClick={() => handleSubmitForReview()}
            >
              Submit for review
            </PDButton>
          ) : (
            <Chip
              label={capitalizeWords(row.status)}
              sx={{
                backgroundColor: statusToColor(row.status) + 11,
                color: statusToColor(row.status),
              }}
            />
          )}
        </TableCell>

        <TableCell align="right" sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mr: 1,
            }}
          >
            <PDMenu
              menuStyle={{ width: 150 }}
              items={[
                {
                  label: "Edit",
                  onClick: () => {
                    if (
                      user?.role === "admin" ||
                      [
                        row?.owner?._id,
                        row?.managers?.map((m) => m._id),
                      ].includes(user?._id)
                    ) {
                      setShowSetupStoreDialog((e) => !e);
                    } else {
                      enqueueSnackbar(
                        "You are not authorized to modify this store",
                        { variant: "error" }
                      );
                    }
                  },
                  startIcon: <EditRoundedIcon fontSize="small" />,
                },
                {
                  label: isDeleted ? "Undelete" : "Delete",
                  onClick: () => {
                    if (["super", "admin"].includes(user.role)) {
                      handleDelete();
                    } else {
                      handleChangeStatus({
                        isDeleted: isDeleted === true ? false : true,
                      });
                    }
                  },
                  startIcon: <DeleteRoundedIcon fontSize="small" />,
                },
                {
                  label: isArchived === true ? "Unarchive" : "Archive",
                  onClick: () =>
                    handleChangeStatus({
                      isArchived: isArchived === true ? false : true,
                    }),
                  startIcon:
                    isArchived === true ? (
                      <UnarchiveRoundedIcon fontSize="small" />
                    ) : (
                      <ArchiveRoundedIcon fontSize="small" />
                    ),
                },
              ]}
            />
          </Box>
        </TableCell>
      </TableRow>

      <SetupStoreDialog
        open={showSetupStoreDialog}
        setOpen={setShowSetupStoreDialog}
        storeId={row?._id}
        getData={getData}
      />
    </>
  );
};

const statusOptions = [
  "active",
  "pending",
  "reviewing",
  "onHold",
  "deactivate",
];
