import { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import ActionButtons from "../../../components/FullScreenDialogActionButtons";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";

export default function PaymentOptionsStep({
  open,
  store,
  setStore,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && store) {
      setValue("bkash", store?.paymentOptions?.bkash);
      setValue("rocket", store?.paymentOptions?.rocket);
      setValue("nogod", store?.paymentOptions?.nogod);
    }
  }, [open, store]);

  const onSubmit = (data) => {
    const dataToSend = {
      paymentOptions: {
        ...data,
      },
    };

    console.log(dataToSend);

    setLoading(true);
    httpClient()
      .put(`/sellers/stores/${store?._id}`, dataToSend)
      .then((res) => {
        setActiveStep((e) => e + 1);
        setStore(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AccountBalanceRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          How do you want get paid?
        </Typography>

        <PDInput
          type="number"
          required
          label="bKash Number"
          placeholder="Enter your bkash number"
          maxlength={11}
          form={{
            ...register("bkash", {
              required: {
                value: true,
                message: "Number is required",
              },
              pattern: {
                value: /^(?:(?:\+|00)88|01)?\d{11}$/,
                message:
                  "The number must start with 01 and be 11 characters long",
              },
              maxLength: {
                value: 11,
                message: "The maximum length is 11",
              },
            }),
          }}
          error={errors.bkash}
          helperText={errors.bkash && errors.bkash.message}
        />

        <PDInput
          type="number"
          label="Rocket Number"
          placeholder="Enter your rocket number"
          maxlength={12}
          form={{
            ...register("rocket", {
              pattern: {
                value: /^(?:(?:\+|00)88|01)?\d{12}$/,
                message:
                  "The number must start with 01 and be 12 characters long",
              },
              maxLength: {
                value: 12,
                message: "The maximum length is 12",
              },
            }),
          }}
          error={errors.rocket}
          helperText={errors.rocket && errors.rocket.message}
        />

        <PDInput
          type="number"
          label="Nogod Number"
          placeholder="Enter your nogod number"
          maxlength={11}
          form={{
            ...register("nogod", {
              pattern: {
                value: /^(?:(?:\+|00)88|01)?\d{11}$/,
                message:
                  "The number must start with 01 and be 11 characters long",
              },
              maxLength: {
                value: 11,
                message: "The maximum length is 11",
              },
            }),
          }}
          error={errors.nogod}
          helperText={errors.nogod && errors.nogod.message}
        />
      </Box>

      <ActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
      />
    </Box>
  );
}
