import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

export default function PDMenu({
  icon,
  text,
  iconStyle,
  buttonStyle,
  menuStyle,
  menuItemStyle,

  hideArrow,

  items,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {text ? (
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={buttonStyle}
        >
          {text}
        </Button>
      ) : (
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={iconStyle}
        >
          {icon ? icon : <MoreVertIcon />}
        </IconButton>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ...menuStyle,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: hideArrow ? "none" : "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {items?.map((item, index) => {
          return item === undefined || item === false ? null : item.divider ? (
            <Divider key={index} />
          ) : (
            <PDMenuItem
              key={index}
              {...item}
              onClick={() => {
                if (item?.onlyOnClick) {
                  item?.onlyOnClick();
                } else {
                  item?.onClick();
                  handleClose();
                }
              }}
              menuItemStyle={menuItemStyle}
            />
          );
        })}
      </Menu>
    </>
  );
}

const PDMenuItem = ({
  startIcon,
  label,
  endIcon,
  onClick,
  sx,
  menuItemStyle,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <MenuItem
      onClick={onClick}
      sx={{
        display: "flex",
        gap: 2,
        color: "#707070",
        ...menuItemStyle,
        ...sx,
      }}
    >
      <div style={{ display: "flex", gap: 0, alignItems: "center" }}>
        {startIcon && (
          <ListItemIcon sx={{ color: theme.textColor2 }}>
            {startIcon}
          </ListItemIcon>
        )}

        <ListItemText>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, color: theme.textColor2 }}
          >
            {label}
          </Typography>
        </ListItemText>
      </div>

      {endIcon && (
        <div
          style={{
            color: "currentColor" || "#707070" || theme.textColor3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {endIcon}
        </div>
      )}
    </MenuItem>
  );
};
