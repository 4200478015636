import { useContext } from "react";
import { ThemeContext } from "src/context/ThemeContext";
import { CircularProgress } from "@mui/material";

export default function LoadingPage({ fullSize, size, thickness, msg }) {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 16,
        minHeight: fullSize ? "100vh" : "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.ui,
      }}
    >
      <CircularProgress
        // style={{ color: theme.buttonColor }}
        size={size || 64}
        thickness={thickness || 5}
      />

      {msg && <p style={{ color: theme.textColor3, marginTop: 20 }}>{msg}</p>}
    </div>
  );
}
