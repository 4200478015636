export default [
  {
    label: "Bank",
    value: "bank",
  },
  {
    label: "bKash",
    value: "bkash",
  },
  {
    label: "Rocket",
    value: "rocket",
  },
  {
    label: "Nogod",
    value: "nogod",
  },
  {
    label: "Cash on Delivery",
    value: "cashOnDelivery",
  },
  {
    label: "Other",
    value: "other",
  },
];
