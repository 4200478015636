import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { ThemeContext } from "src/context";
import { useForm } from "react-hook-form";
import PDInput from "src/components/_pdStyle/PDInput";
import PDButton from "src/components/_pdStyle/PDButton";
import httpClient from "src/libs/httpClient";
import { useEffect } from "react";
import PDSelect from "src/components/_pdStyle/PDSelect";
import addToObject from "src/libs/addToObject";

export default function AddressBookDialog({
  open,
  setOpen,

  address,
  setAddress,
  // setAddressBook,
}) {
  const { theme, bp } = useContext(ThemeContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const selectedProvinceInputRef = useRef();
  const selectedCityInputRef = useRef();
  const selectedAreaInputRef = useRef();

  useEffect(() => {
    if (open && address) {
      setValue("fullName", address?.fullName);
      setValue("mobile", address?.mobile);
      setValue("email", address?.email);
      setValue("address", address?.address);

      setSelectedProvince({
        label: address?.province,
        value: address?.province,
      });

      setSelectedCity({ label: address?.city, value: address?.city });
      setSelectedArea({ label: address?.area, value: address?.area });
    }
  }, [open, address]);

  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedProvinceError, setSelectedProvinceError] = useState(false);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedCityError, setSelectedCityError] = useState(false);
  const [selectedArea, setSelectedArea] = useState();
  const [selectedAreaError, setSelectedAreaError] = useState(false);

  const onSubmit = (data) => {
    if (!selectedProvince || !selectedCity || !selectedArea) {
      if (!selectedProvince) {
        setSelectedProvinceError(true);
      }
      if (!selectedCity) {
        setSelectedCityError(true);
      }
      if (!selectedArea) {
        setSelectedAreaError(true);
      }
    } else {
      let dataToSend = {
        ...data,

        province: selectedProvince?.value?.name || selectedProvince?.label,
        city: selectedCity?.value?.name || selectedCity?.label,
        area: selectedArea?.value?.name || selectedArea?.label,
      };

      if (address?._id) {
        dataToSend = addToObject(dataToSend, "_id", address?._id);
      }

      setAddress(dataToSend);
      setOpen(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullScreen={bp.md ? true : false}
      fullWidth
      scroll="body"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
      //
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        {address?.type ? "Update Address" : "Add Address"}
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        {/* Name, Address, City, Zip Code, Mobile, Email */}
        <PDInput
          required
          label="Full Name"
          placeholder="Enter your full name here"
          form={{ ...register("fullName", { required: true }) }}
          error={errors.fullName}
          helperText={errors.fullName ? "Name is required" : ""}
        />

        <Box sx={{ display: "flex", gap: 2 }}>
          <PDInput
            required
            type="number"
            label="Mobile"
            placeholder="Enter mobile here"
            form={{ ...register("mobile", { required: true }) }}
            error={errors.mobile}
            helperText={errors.mobile ? "Mobile number is required" : ""}
          />
          <PDInput
            required
            type="email"
            label="Email"
            placeholder="Enter email here"
            form={{ ...register("email", { required: true }) }}
            error={errors.email}
            helperText={errors.email ? "Email is required" : ""}
          />
        </Box>

        <PDInput
          required
          label="Address"
          placeholder="Enter address here"
          form={{ ...register("address", { required: true }) }}
          error={errors.address}
          helperText={errors.address ? "Address is required" : ""}
        />

        <Selecters
          open={open}
          //
          selectedProvinceInputRef={selectedProvinceInputRef}
          selectedCityInputRef={selectedCityInputRef}
          selectedAreaInputRef={selectedAreaInputRef}
          //
          selectedProvince={selectedProvince}
          setSelectedProvince={setSelectedProvince}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          //
          selectedProvinceError={selectedProvinceError}
          setSelectedProvinceError={setSelectedProvinceError}
          selectedCityError={selectedCityError}
          setSelectedCityError={setSelectedCityError}
          selectedAreaError={selectedAreaError}
          setSelectedAreaError={setSelectedAreaError}
        />
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => setOpen((e) => !e)}
          >
            Cancel
          </PDButton>
          <PDButton type="submit">
            {address?.fullName ? "Update" : "Add"}
          </PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const Selecters = ({
  open,

  selectedProvinceInputRef,
  selectedCityInputRef,
  selectedAreaInputRef,

  selectedProvince,
  setSelectedProvince,
  selectedCity,
  setSelectedCity,
  selectedArea,
  setSelectedArea,

  selectedProvinceError,
  setSelectedProvinceError,
  selectedCityError,
  setSelectedCityError,
  selectedAreaError,
  setSelectedAreaError,
}) => {
  const [province, setProvince] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const [loadingProvince, setLoadingProvince] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);

  useEffect(() => {
    open && getProvince();
  }, [open]);

  const getProvince = () => {
    setLoadingProvince(true);
    httpClient()
      .get(`/locationTree/province`)
      .then((res) => {
        setLoadingProvince(false);
        setProvince(
          res.data?.map((i) => {
            return { label: i.name, value: i };
          })
        );
      })
      .catch((err) => {
        setLoadingProvince(false);
        console.log(err);
      });
  };
  const getCities = (value) => {
    setSelectedProvince(value);
    setSelectedProvinceError(false);

    selectedCityInputRef?.current?.clearValue();
    selectedAreaInputRef?.current?.clearValue();
    setSelectedCity();
    setSelectedArea();

    //
    setLoadingCities(true);
    httpClient()
      .get(`/locationTree/cities/${value?.value?.id}`)
      .then((res) => {
        setCities(
          res.data?.map((i) => {
            return { label: i.name, value: i };
          })
        );
        setLoadingCities(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCities(false);
      });
  };
  const getAreas = (value) => {
    setSelectedCity(value);
    setSelectedCityError(false);

    selectedAreaInputRef?.current?.clearValue();
    setSelectedArea();

    //
    setLoadingAreas(true);
    httpClient()
      .get(`/locationTree/areas/${value?.value?.id}`)
      .then((res) => {
        setLoadingAreas(false);
        setAreas(
          res.data?.map((i) => {
            return { label: i.name, value: i };
          })
        );
      })
      .catch((err) => {
        setLoadingAreas(false);
        console.log(err);
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <PDSelect
          selectInputRef={selectedProvinceInputRef}
          //
          required
          label="Province"
          placeholder="Select province"
          defaultValue={province[0]}
          options={province}
          //
          isLoading={loadingProvince}
          value={selectedProvince}
          onChange={getCities}
          error={selectedProvinceError}
          helperText={selectedProvinceError && "Province is required"}
        />

        <PDSelect
          selectInputRef={selectedCityInputRef}
          //
          required
          label="City"
          placeholder="Select city"
          defaultValue={cities[0]}
          options={cities}
          //
          isLoading={loadingCities}
          value={selectedCity}
          onChange={getAreas}
          error={selectedCityError}
          helperText={selectedCityError && "City is required"}
        />
      </Box>

      <PDSelect
        selectInputRef={selectedAreaInputRef}
        //
        required
        label="Area"
        placeholder="Select area"
        defaultValue={areas[0]}
        options={areas}
        //
        isLoading={loadingAreas}
        value={selectedArea}
        onChange={(value) => {
          setSelectedArea(value);
          setSelectedAreaError(false);
        }}
        error={selectedAreaError}
        helperText={selectedAreaError && "Area is required"}
      />
    </>
  );
};
