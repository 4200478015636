import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  IconButton,
  Typography,
} from "@mui/material";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ThemeContext } from "src/context";
import { useForm } from "react-hook-form";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import FullScreenDialogActionButtons from "src/components/FullScreenDialogActionButtons";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import uploadPhotos from "src/dialogs/addProductDialog/steps/picturesAndColorsStep/_components/uploadPhotos";
import deletePhoto from "src/libs/deletePhoto";
import ColorPickerDialog from "src/dialogs/ColorPickerDialog";
import PDButton from "src/components/_pdStyle/PDButton";
import addToObject from "src/libs/addToObject";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ActionDialog from "src/dialogs/ActionDialog";

export default function PicturesAndColorsStep({
  product,
  setProduct,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [productImages, setProductImages] = useState([]);

  const [colors, setColors] = useState([]);

  const [showColorPickerDialog, setShowColorPickerDialog] = useState(false);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);

  useEffect(() => {
    if (product) {
      setColors(product.colors || []);

      setProductImages([]);
      if (product?.images?.length > 0) {
        product?.images?.map((e, index) => {
          setProductImages((x) => [
            ...x,
            { ...e, id: e.large || index.toString() },
          ]);
        });
      }
    }
  }, [product]);

  const uploadImages = () => {
    setLoading(true);
    uploadPhotos(files, `stores/${product?.store}/products/${product?._id}`, {
      width: 1000,
      height: 1250,
    })
      .then((data) => {
        console.log(data);
        onSubmit(data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onSubmit = (images) => {
    let data = {};

    if (images) {
      data = addToObject(data, "images", [...productImages, ...images]);
    } else {
      data = addToObject(data, "images", productImages);
    }

    if (colors) {
      data = addToObject(data, "colors", colors);
    }

    setLoading(true);
    httpClient()
      .put(`/sellers/products/${product?._id}`, data)
      .then((res) => {
        if (!images) {
          setActiveStep((e) => e + 1);
        } else {
          setFiles([]);
          setShowUploadImageDialog(false);
        }

        setProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  function handleDelete(item) {
    console.log(item?.large);
    if (item?.large) {
      deletePhoto(item?.large).then((res) => {
        console.log("item?.large deleted");
      });
    }

    if (item?.thumb) {
      deletePhoto(item?.thumb).then((res) => {
        console.log("item?.thumb : deleted");
      });
    }

    setLoading(true);
    console.log(product.images, item);
    console.log(product.images?.filter((img) => img?.large !== item?.large));
    httpClient()
      .put(`sellers/products/${product?._id}`, {
        images: product.images?.filter((img) => img?.large !== item?.large),
      })
      .then((res) => {
        console.log(res.data);
        setProduct(res.data);
        setLoading(false);
        enqueueSnackbar(`Image deleted successfully`, {
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  }

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SmartphoneRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>
        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          Pictures and Colors
        </Typography>

        {/*  */}
        {/* <PDDropzone
          required
          label="Images"
          value={files}
          setValue={setFiles}
          maxFiles={5 - product?.images?.length}
          // oldFiles={product.images?.filter((item) => item?.large !== null)}
          fileToDelete={(e) => handleDelete(e)}
        /> */}

        <ImagesSection
          product={product}
          uploadImages={uploadImages}
          handleDelete={handleDelete}
          productImages={productImages}
          setProductImages={setProductImages}
          files={files}
          setFiles={setFiles}
          loading={loading}
          //
          showUploadImageDialog={showUploadImageDialog}
          setShowUploadImageDialog={setShowUploadImageDialog}
        />
        {/*  */}

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 0.5,
              gap: 0.5,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ color: theme.textColor2 }}
            >
              Colors:
            </Typography>

            {colors?.length > 0 && (
              <Button onClick={() => setShowColorPickerDialog((e) => !e)}>
                Add Color
              </Button>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {colors?.length > 0 ? (
              colors?.map((item, index) => (
                <ColorItem key={index} item={item} setColors={setColors} />
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <PDButton
                  sx={{
                    backgroundColor: theme.buttonColor,
                    color: theme.buttonTextColor,
                    maxWidth: 125,
                  }}
                  onClick={() => setShowColorPickerDialog((e) => !e)}
                >
                  Add Color
                </PDButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <FullScreenDialogActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
        actionButtonClick={() => {
          // if (files.length > 0) {
          //   uploadImages();
          // } else {
          onSubmit();
          // setActiveStep((e) => e + 1);
          // }
        }}
      />

      <ColorPickerDialog
        open={showColorPickerDialog}
        setOpen={setShowColorPickerDialog}
        colors={colors}
        setColors={setColors}
      />
    </Box>
  );
}

const ImagesSection = ({
  product,
  uploadImages,
  handleDelete,
  productImages,
  setProductImages,

  files,
  setFiles,
  loading,
  //
  showUploadImageDialog,
  setShowUploadImageDialog,
}) => {
  const { theme } = useContext(ThemeContext);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (files?.length > 0) {
      setShowUploadImageDialog(true);
    }
  }, [files]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(productImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProductImages(items);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {productImages && productImages?.length < 5 && (
          <Button
            sx={{
              width: "100%",
              backgroundColor: theme.borderColor + 66,
              border: `1px ${theme.borderColor} solid`,
              px: 2,
              py: 4,
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              "&:hover": {
                backgroundColor: theme.borderColor + 99,
              },
            }}
            onClick={() => setShowUploadImageDialog(true)}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: theme.textColor }}
            >
              Click here to add Images.
            </Typography>

            <Typography
              variant="p"
              fontWeight={500}
              sx={{ color: theme.textColor3 }}
            >
              {`You can add upto ${
                productImages?.length > 0
                  ? `${5 - productImages?.length} more `
                  : 5
              } images.`}
            </Typography>
          </Button>
        )}

        {productImages?.length > 0 && (
          <Box>
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ mt: 2, color: theme.textColor2 }}
            >
              Images:
            </Typography>

            <Box
              sx={{
                mt: 0.5,
                backgroundColor: theme.backgroundColor,
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="imageList">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {productImages?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box
                                sx={{
                                  backgroundColor: theme.ui,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  border: `1px ${theme.borderColor} solid`,
                                  borderBottom:
                                    index + 1 === productImages?.length
                                      ? `1px ${theme.borderColor} solid`
                                      : "none",

                                  borderTopLeftRadius: index === 0 ? 4 : 0,
                                  borderTopRightRadius: index === 0 ? 4 : 0,
                                  borderBottomLeftRadius:
                                    index + 1 === productImages?.length ? 4 : 0,
                                  borderBottomRightRadius:
                                    index + 1 === productImages?.length ? 4 : 0,

                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <DragIndicatorRoundedIcon
                                    fontSize="small"
                                    sx={{ fill: theme.textColor3, mx: 1 }}
                                  />
                                  <Avatar
                                    key={index}
                                    sx={{ borderRadius: 1 }}
                                    src={
                                      process.env.REACT_APP_S3_URL +
                                      (item?.thumb || item?.large)
                                    }
                                    alt={item?.id}
                                  />

                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: 500,
                                      color: theme.textColor,
                                    }}
                                  >
                                    Image: {index + 1}
                                  </Typography>
                                </Box>

                                <Typography
                                  variant="p"
                                  sx={{
                                    fontWeight: 400,
                                    color: theme.textColor3,
                                  }}
                                >
                                  (Click and drag to reorder)
                                </Typography>

                                <IconButton
                                  size="small"
                                  sx={{
                                    mr: 0.5,
                                    color: theme.accent.red,
                                    backgroundColor: theme.accent.red + 11,
                                  }}
                                  onClick={() => setShowDeleteDialog(item)}
                                >
                                  <CloseRoundedIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        )}
      </Box>

      <ActionDialog
        open={showUploadImageDialog ? true : false}
        setOpen={setShowUploadImageDialog}
        title="Upload Images"
        // description={`Are you sure you want to upload these (${files?.length}) images?`}
        content={
          <>
            <PDDropzone
              required
              label="Add Images"
              value={files}
              setValue={setFiles}
              maxFiles={5 - product?.images?.length}
            />
          </>
        }
        primaryButtonLabel="Upload"
        primaryButtonType="button"
        primaryButtonLoading={loading}
        primaryButtonOnClick={() => uploadImages()}
      />

      <ActionDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        title="Delete Image"
        description={`Are you sure you want to delete this image?`}
        primaryButtonLabel="Delete"
        primaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete(showDeleteDialog);
          setShowDeleteDialog(false);
        }}
      />
    </>
  );
};

const ColorItem = ({ item, setColors }) => {
  const [showColorPickerDialog, setShowColorPickerDialog] = useState(false);

  return (
    <>
      <ButtonBase
        sx={{
          width: 40,
          maxWidth: 40,
          p: 0,
          m: 0,
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => setShowColorPickerDialog((e) => !e)}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            backgroundColor: item.color,
            borderRadius: 1,
          }}
        />

        <input
          disabled
          style={{ maxWidth: 40, textAlign: "center" }}
          type="number"
          value={item.imageIndex}
          maxLength={5}
          onChange={(e) => {
            item.imageIndex = e.target.value;

            setColors((e) => [...e]);
          }}
        />
      </ButtonBase>

      <ColorPickerDialog
        open={showColorPickerDialog}
        setOpen={setShowColorPickerDialog}
        selectedColor={item}
        setColors={setColors}
      />
    </>
  );
};
