import { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import ActionButtons from "../../../components/FullScreenDialogActionButtons";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import { useSnackbar } from "notistack";
import uploadPhoto from "src/libs/uploadPhoto";
import httpClient from "src/libs/httpClient";

import deletePhoto from "src/libs/deletePhoto";
import { ThemeContext } from "src/context";

export default function LogoAndCoverArtStep({
  store,
  setStore,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [logo, setLogo] = useState([]);
  const [coverArt, setCoverArt] = useState([]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (logo.length > 0) {
        await uploadPhoto(logo[0], `stores/${store?._id}/logo`, {
          maxWidth: 512,
          maxHeight: 512,
        })
          .then(async (data) => {
            setLoading(true);
            console.log(data);
            await httpClient()
              .put(`/sellers/stores/${store?._id}`, { logo: data })
              .then((res) => {
                console.log(res.data);
                setStore(res.data);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                enqueueSnackbar(err?.response?.data?.message, {
                  variant: "error",
                });
              });
          })
          .catch((err) => {
            console.error("Error uploading photos: ", err);
            setLoading(false);
            // Handle the error here
          });
      }
      if (coverArt.length > 0) {
        await uploadPhoto(coverArt[0], `stores/${store?._id}/coverArt`, {
          maxWidth: 1500,
        })
          .then(async (data) => {
            setLoading(true);
            console.log(data);
            await httpClient()
              .put(`/sellers/stores/${store?._id}`, {
                coverArt: data,
              })
              .then((res) => {
                console.log(res.data);
                setStore(res.data);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                enqueueSnackbar(err?.response?.data?.message, {
                  variant: "error",
                });
              });
          })
          .catch((err) => {
            console.error("Error uploading photos: ", err);
            setLoading(false);
            // Handle the error here
          });
      }
    } finally {
      setLoading(false);
      setActiveStep((e) => e + 1);
    }
  };

  function handleDelete(url) {
    deletePhoto(url?.logo || url?.coverArt)
      .then((data) => {
        setLoading(true);

        httpClient()
          .put(
            `/sellers/stores/${store?._id}`,
            url?.logo ? { logo: "" } : { coverArt: "" }
          )
          .then((res) => {
            console.log(res.data);
            setStore(res.data);
            setLoading(false);
            enqueueSnackbar(`Image deleted successfully`, {
              variant: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            // enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
          });
      })
      .catch((err) => {
        console.log(err, err.stack);
      });
  }

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RemoveRedEyeRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          How does it look like?
        </Typography>

        <PDDropzone
          required
          label="Logo"
          maxFiles={1}
          value={logo}
          setValue={setLogo}
          oldFiles={store?.logo?.length > 0 && [store?.logo]}
          // fileToDelete={handleDelete}
          fileToDelete={(e) => handleDelete({ logo: e })}
        />
        <PDDropzone
          required
          label="Cover Art"
          maxFiles={1}
          value={coverArt}
          setValue={setCoverArt}
          oldFiles={store?.coverArt?.length > 0 && [store?.coverArt]}
          // fileToDelete={handleDelete}
          fileToDelete={(e) => handleDelete({ coverArt: e })}
        />
      </Box>

      <ActionButtons
        loading={loading}
        setActiveStep={setActiveStep}
        actionButtonClick={() => onSubmit()}
      />
    </Box>
  );
}
