import { useContext, useEffect, useState } from "react";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
// import EditRoundedIcon from "@mui/icons-material/EditRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import FullScreenDialogActionButtons from "src/components/FullScreenDialogActionButtons";
// import MdEditor from "md-editor-rt";
// import "md-editor-rt/lib/style.css";

import MDEditor from "@uiw/react-md-editor";

import httpClient from "src/libs/httpClient";
// import generateRandomString from "src/libs/generateRandomString";
// import { useDebounce, useDebouncedCallback } from "use-debounce";

export default function DetaildDescriptionStep({
  open,
  product,
  setProduct,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [showShortDescription, setShowShortDescription] = useState(
    product.shortDescription?.length > 0 ? true : false
  );

  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");

  const [specifications, setSpecifications] = useState([]);
  // const [addSpecificationItem, setAddSpecificationItem] = useState()

  // const addSpecificationItem = () => {
  //   if (specifications) {
  //     setSpecifications(
  //       (e) =>
  //         [
  //           ...e,
  //           {
  //             id: generateRandomString(10),
  //             key: "",
  //             value: "",
  //           },
  //         ] || []
  //     );
  //   } else {
  //     setSpecifications([{ id: generateRandomString(10), key: "", value: "" }]);
  //   }
  // };

  // useEffect(() => {
  //   console.log(`Specifications: `, specifications);
  // }, [specifications]);

  useEffect(() => {
    if (open && product) {
      setValue("videoUrl", product?.videoUrl);
      setSpecifications(
        product?.specifications?.map((s) => {
          return { key: s.key, value: s.value };
        })
      );

      setShortDescription(product.shortDescription);
      setDescription(product.description);
    }
  }, [open, product]);

  const onSubmit = (data) => {
    const dataToSend = {
      ...data,
      shortDescription,
      description,
      specifications,
    };

    // console.log(dataToSend);

    setLoading(true);
    httpClient()
      .put(`/sellers/products/${product?._id}`, dataToSend)
      .then((res) => {
        setProduct(res.data);
        setLoading(false);
        setActiveStep((e) => e + 1);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SmartphoneRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          Tell us more about your product
        </Typography>

        <Box sx={{ width: "100%" }}>
          <PDInput
            label="Youtube Video URL"
            placeholder="Enter product's youtube video here url"
            form={{
              ...register("videoUrl", {
                pattern: {
                  value: /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
                  message: "Please enter a valid YouTube video URL",
                },
              }),
            }}
            error={errors?.videoUrl}
            helperText={errors?.videoUrl?.message}
          />

          {/* Short Description */}
          <Collapse in={!showShortDescription} mountOnEnter unmountOnExit>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Button
                startIcon={<AddRoundedIcon />}
                onClick={() => setShowShortDescription((e) => !e)}
              >
                Add Short Description
              </Button>
            </Box>
          </Collapse>

          <Collapse in={showShortDescription} mountOnEnter unmountOnExit>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Typography
                variant="h6"
                sx={{ color: theme.textColor2, fontWeight: 600, mb: 0.5 }}
              >
                Short Description:
              </Typography>

              <div data-color-mode="light">
                <MDEditor
                  style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.textColor,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: theme.borderColor,
                  }}
                  value={shortDescription}
                  onChange={setShortDescription}
                  height="150px"
                  visibleDragbar={false}
                  hideToolbar={true}
                  preview="edit"
                />
              </div>
            </Box>
          </Collapse>
        </Box>

        {/* Description */}
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
          >
            <Typography
              variant="h6"
              sx={{ color: theme.textColor2, fontWeight: 600 }}
            >
              Description:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "#bc002d",
              }}
            >
              *
            </Typography>
          </Box>

          <div data-color-mode="light">
            <MDEditor
              style={{
                backgroundColor: theme.backgroundColor,
                color: theme.textColor,
                height: 250,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: theme.borderColor,
                // fontFamily: `Inter, sans-serif`,
              }}
              value={description}
              onChange={setDescription}
              height="250px"
              visibleDragbar={false}
              hideToolbar={true}
              preview="edit"
            />
          </div>
        </Box>

        {/* Specifications  */}
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ color: theme.textColor2, fontWeight: 600, mb: 0.5 }}
          >
            Specifications (upto 10):
          </Typography>

          <SpecificationSection
            specifications={specifications}
            setSpecifications={setSpecifications}
          />
        </Box>
      </Box>

      <FullScreenDialogActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
      />
    </Box>
  );
}

const SpecificationSection = ({ specifications, setSpecifications }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);

  const [key, setKey] = useState();
  const [value, setValue] = useState();

  const addSpecification = (key, value) => {
    if (specifications.length >= 10) {
      enqueueSnackbar("Can not add more then 10 items", { variant: "error" });
      return;
    }

    if (key?.length <= 2 || value?.length <= 2) {
      enqueueSnackbar("Fields must be at least 3 characters long", {
        variant: "info",
      });
      return;
    }

    setSpecifications((oldArray) => [...oldArray, { key, value }]);
    setKey("");
    setValue("");
  };

  // const addSpecification = (name, data) => {
  //   if (name != undefined && data != undefined) {

  //     if (specifications.length <= 9) {

  //       if (name.length > 2 && data.length > 2) {

  //         if (specifications == undefined) {
  //           setSpecifications({ name, data });
  //           console.log(specifications);
  //         } else {
  //           setSpecifications((oldArray) => [...oldArray, { name, data }]);
  //           console.log(specifications);
  //         }
  //         setKey("");
  //         setValue("");
  //         // setNotification("")

  //       } else {
  //         enqueueSnackbar("Fields must be at least 3 characters long", {
  //           variant: "info",
  //         });
  //       }

  //     } else {
  //       enqueueSnackbar("Can not add more then 10 items", { variant: "error" });
  //     }
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: `1px ${theme.borderColor} solid`,

        // backgroundColor: theme.backgroundColor,
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1.5,
          alignItems: "flex-end",
          px: 1.5,
          pt: 1,
          pb: 1.5,

          backgroundColor: theme.backgroundColor,
          borderBottom:
            specifications?.length > 0
              ? `1px ${theme.borderColor} solid`
              : "none",
        }}
      >
        <PDInput
          label="Key"
          placeholder="Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
        <PDInput
          label="Value"
          placeholder="Value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />

        <IconButton
          // type="submit"
          sx={{
            height: 38,
            width: 38,
            color: theme.buttonColor,
            backgroundColor: theme.backgroundColor,
            border: `1px ${theme.borderColor} solid`,
          }}
          onClick={() => addSpecification(key, value)}
        >
          <NavigateNextRoundedIcon />
        </IconButton>
      </Box>
      {specifications?.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {specifications.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                backgroundColor: index % 2 ? theme.backgroundColor : theme.ui,
                justifyContent: "space-between",
                padding: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  style={{ color: theme.textColor2 }}
                >
                  {index + 1}: {data.key}:
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  style={{ color: theme.textColor3 }}
                >
                  {data.value}
                </Typography>
              </Box>

              <IconButton
                sx={{
                  width: 28,
                  height: 28,
                  backgroundColor: theme.backgroundColor2,
                  color: theme.buttonColor,

                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    backgroundColor: theme.buttonColor,
                    color: theme.buttonTextColor,
                  },
                }}
                onClick={() => {
                  setSpecifications(
                    specifications.filter((item) => item.key !== data.key)
                  );
                }}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
