import {
  Box,
  Chip,
  Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import statusToColor from "src/libs/statusToColor";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import PDSelect from "src/components/_pdStyle/PDSelect";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import AddProductDialog from "src/dialogs/addProductDialog/AddProductDialog";
import moment from "moment";
import orderStatusOptions from "src/data/orderStatusOptions";
import { Link } from "react-router-dom";

const styles = {
  tableCell: { p: 0, py: 1, pl: 1 },
};

export default function OrdersTable({ orders, getData, loading, perPage }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: `1px ${theme.borderColor} solid`,
        mb: 2,
      }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: "#f2f2f2",
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 57,
                borderRadius: ".25rem",
                backgroundColor: "#f2f2f2",
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {orders?.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>Invoice Number</TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      Delivery Fee
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Total
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Order Date
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Status
                    </TableCell>

                    <TableCell align="left" sx={styles.tableCell}>
                      Shipping Address
                    </TableCell>

                    <TableCell align="right" sx={styles.tableCell} />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orders.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        sx={{
                          height: 57,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.backgroundColor,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={index} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  py: 10,
                  px: 2,
                }}
              >
                <Typography align="center" sx={{ color: theme.textColor3 }}>
                  You don&apos;t have any orders.
                </Typography>
              </Box>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    value: row?.status,
    label: capitalizeWords(row?.status),
  });

  const handleChangeStatus = (e) => {
    setLoading(true);

    if (e?.hasOwnProperty("status")) {
      setStatus({
        value: e?.status,
        label: capitalizeWords(e?.status),
      });
    }

    httpClient()
      .put(`/sellers/orders/${row?._id}`, e)
      .then((res) => {
        console.log(res.data);
        enqueueSnackbar(`Products updated successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message) ||
            `Faild to update product`,
          { variant: "error" }
        );

        if (e?.hasOwnProperty("status")) {
          setStatus({
            value: row?.status,
            label: capitalizeWords(row?.status),
          });
        }

        getData();
        setLoading(false);
      });
  };

  return loading ? (
    <TableRow
      sx={{
        height: 57,
        backgroundColor: "#f2f2f2",
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.backgroundColor,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        // href={`/seller/orders/${row?.invoiceNumber}`}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.backgroundColor },
        }}
      >
        <TableCell component="th" scope="row" sx={{ ...styles.tableCell }}>
          {row.invoiceNumber}
        </TableCell>
        <TableCell align="center" sx={{ ...styles.tableCell, fontWeight: 500 }}>
          ৳{row.deliveryFee?.toLocaleString()}
        </TableCell>

        <TableCell align="center" sx={{ ...styles.tableCell, fontWeight: 500 }}>
          ৳{row?.total?.toLocaleString()}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {moment(row.createdAt).fromNow()}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {user?.role === "admin" ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PDSelect
                sx={{ maxWidth: 200 }}
                required
                defaultValue={{
                  value: row.status,
                  label: capitalizeWords(row.status),
                }}
                options={statusOptions.map((o) => {
                  return { value: o, label: capitalizeWords(o) };
                })}
                value={status}
                onChange={(e) => {
                  setStatus(e);
                  handleChangeStatus({ status: e.value });
                }}
              />
            </Box>
          ) : (
            <Chip
              label={
                orderStatusOptions?.find((o) => o.value === row.status).label
              }
              sx={{
                backgroundColor: statusToColor(row.status) + 11,
                color: statusToColor(row.status),
              }}
            />
          )}
        </TableCell>

        <TableCell align="left" sx={{ ...styles.tableCell, maxWidth: 300 }}>
          {row?.deliveryAddress?.address}
        </TableCell>

        <TableCell align="right" sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mr: 1,
            }}
          >
            <Link
              // to={`/${
              //   ["super", "admin"].includes(user?.role) ? "admin" : "seller"
              // }/orders/${row?.invoiceNumber}`}
              to={`/orders/${row?.invoiceNumber}`}
            >
              <IconButton
                sx={{
                  translate: "all .2s ease-in-out",
                  "&:hover": {
                    color: theme.buttonColor,
                  },
                }}
              >
                <VisibilityRoundedIcon />
              </IconButton>
            </Link>
          </Box>
        </TableCell>
      </TableRow>

      <AddProductDialog
        open={showAddProductDialog}
        setOpen={setShowAddProductDialog}
        productId={row?._id}
        getData={getData}
      />
    </>
  );
};

const statusOptions = [
  "pendingPayment",
  "onHold",
  "failed",

  "pending",
  "processing",
  "shipped",
  "localFacility",
  "outForDelivery",
  "delivered",
  "completed",

  "canceled",

  "refunded",
];
