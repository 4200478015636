export default [
  {
    label: "Pending Payment",
    value: "pendingPayment",
  },
  {
    label: "On Hold",
    value: "onHold",
  },
  {
    label: "Failed",
    value: "failed",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Processing",
    value: "processing",
  },
  {
    label: "Shipped",
    value: "shipped",
  },
  {
    label: "Local Facility",
    value: "localFacility",
  },
  {
    label: "Out For Delivery",
    value: "outForDelivery",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Canceled",
    value: "canceled",
  },
  {
    label: "Refunded",
    value: "refunded",
  },
];
