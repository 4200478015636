import { Box, Fade, IconButton, Typography } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useState, useContext } from "react";
import { ThemeContext } from "src/context";
import AddressBookDialog from "src/dialogs/AddressBookDialog";

export default function AddressBookItem({ item, setItem, hideEdit }) {
  const { theme } = useContext(ThemeContext);

  const [showButtons, setShowButtons] = useState(false);
  const [showAddressBookDialog, setShowAddressBookDialog] = useState(false);

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          border: `1px ${theme.borderColor} solid`,
          borderRadius: 1,
          position: "relative",
          width: "100%",
        }}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        <Fade in={showButtons}>
          <Box
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              display: "flex",
              gap: 1,
            }}
          >
            {!hideEdit && (
              <IconButton
                size="small"
                color="primary"
                onClick={() => setShowAddressBookDialog(true)}
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            )}

            {/* {!hideDelete && (
              <IconButton
                size="small"
                color="error"
                onClick={() => getDelete()}
              >
                {loading ? (
                  <CircularProgress thickness={7} size={16} />
                ) : (
                  <DeleteRoundedIcon fontSize="small" />
                )}
              </IconButton>
            )} */}
          </Box>
        </Fade>

        <TextItem label="Full Name" value={item?.fullName} />
        <TextItem label="Email" value={item?.email} />
        <TextItem label="Mobile" value={item?.mobile} />
        <TextItem label="Address" value={item?.address} />
        <TextItem label="Area" value={item?.area} />
        <TextItem label="City" value={item?.city} />
        <TextItem label="Province" value={item?.province} />
      </Box>

      <AddressBookDialog
        open={showAddressBookDialog}
        setOpen={setShowAddressBookDialog}
        address={item}
        setAddress={setItem}
      />
    </>
  );
}

const TextItem = ({ label, value }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "100px 5px 1fr",
        gap: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{ width: 100, fontWeight: 600, color: theme.textColor }}
      >
        {label}
      </Typography>

      <Typography
        variant="h6"
        sx={{ width: 100, fontWeight: 600, color: theme.textColor }}
      >
        :
      </Typography>

      <Typography
        variant="h6"
        sx={{ flex: 1, fontWeight: 400, color: theme.textColor2 }}
      >
        {value}
      </Typography>
    </Box>
  );
};
