import { useContext, useEffect, useState } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { ThemeContext } from "src/context";
import AddressBookDialog from "src/dialogs/AddressBookDialog";
import httpClient from "src/libs/httpClient";
import ActionButtons from "src/components/FullScreenDialogActionButtons";
import AddressBookItem from "src/components/AddressBookItem";
import { enqueueSnackbar } from "notistack";

export default function AddressDetailsStep({
  open,
  store,
  setStore,

  activeStep,
  setActiveStep,
}) {
  const { theme } = useContext(ThemeContext);

  const [address, setAddress] = useState(store?.address);

  useEffect(() => {
    setAddress(store?.address);
  }, [store?.address]);

  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    httpClient()
      .put(`/sellers/stores/${store?._id}`, { address })
      .then((res) => {
        setActiveStep((e) => e + 1);
        setStore(res.data);
        setLoading(false);
        setAddress(res.data?.address);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  // const add

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 1,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BusinessRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          Where is your business?
        </Typography>

        {address?.fullName ? (
          <AddressBookItem item={address} setItem={setAddress} />
        ) : (
          <AddNew address={address} setAddress={setAddress} />
        )}
      </Box>

      <ActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
        actionButtonClick={() => onSubmit()}
      />
    </Box>
  );
}

const AddNew = ({ address, setAddress }) => {
  const { theme } = useContext(ThemeContext);

  const [showAddressBookDialog, setShowAddressBookDialog] = useState(false);

  return (
    <>
      <ButtonBase
        sx={{
          backgroundColor: theme.backgroundColor,
          width: "100%",
          // height: 125,
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          p: 2,
          gap: 2,
          "&:hover": {
            backgroundColor: theme.backgroundColor2 + 55,
          },
        }}
        onClick={() => setShowAddressBookDialog((e) => !e)}
      >
        <Box
          sx={{
            backgroundColor: theme.buttonColor + 11,
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 1,
          }}
        >
          <AddRoundedIcon sx={{ fontSize: "2rem", fill: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: theme.buttonColor }}
        >
          Add Address
        </Typography>
      </ButtonBase>

      <AddressBookDialog
        open={showAddressBookDialog}
        setOpen={setShowAddressBookDialog}
        address={address}
        setAddress={setAddress}
      />
    </>
  );
};
