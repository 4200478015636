import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import FullScreenDialogActionButtons from "src/components/FullScreenDialogActionButtons";
import PDSelect from "src/components/_pdStyle/PDSelect";
import { stringify } from "querystring";
import AddBrandDialog from "src/dialogs/AddBrandDialog";

// // Function to retrieve the category path
// function getCategoryPath(categoryId) {
//   const category = categories.find((cat) => cat._id === categoryId);
//   if (!category) {
//     return null; // Category not found
//   }

//   let path = [category.name];
//   let parentId = category.parent;

//   while (parentId) {
//     const parentCategory = categories.find((cat) => cat._id === parentId);
//     if (!parentCategory) {
//       break; // Parent category not found
//     }
//     path.unshift(parentCategory.name);
//     parentId = parentCategory.parent;
//   }

//   return path.join(" / ");
// }

// // Generate paths for all categories
// const categoryPaths = categories.map((category) => {
//   console.log(category);
//   const categoryPath = getCategoryPath(category._id);
//   return categoryPath;
// });

export default function BasicInformationsStep({
  open,
  product,
  setProduct,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [showSearchTags, setShowSearchTags] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedStore, setSelectedStore] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedBrand, setSelectedBrand] = useState();

  useEffect(() => {
    if (open && product) {
      setValue("name", product?.name);
      setValue("model", product?.model);

      if (product?.searchTags?.length > 0) {
        setShowSearchTags(true);
        setSearchTags(
          product?.searchTags?.map((i) => {
            return { label: i, value: i };
          })
        );
      }
    }
  }, [open, product]);

  const onSubmit = (data) => {
    if (!selectedCategory?.value) {
      enqueueSnackbar("Category is required", { variant: "error" });
      return;
    }
    if (!selectedBrand?.value) {
      enqueueSnackbar("Brand is required", { variant: "error" });
      return;
    }

    const dataToSend = {
      store: selectedStore?.value,
      ...data,
      searchTags: searchTags?.map((sx) => sx.value),
      categories: selectedCategory?.value,
      brand: selectedBrand?.value,
    };

    console.log(dataToSend);

    // setActiveStep((e) => e + 1);
    setLoading(true);
    if (product?._id) {
      httpClient()
        .put(`/sellers/products/${product?._id}`, dataToSend)
        .then((res) => {
          console.log(res.data);
          setProduct(res.data);
          setLoading(false);
          setActiveStep((e) => e + 1);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(err?.response?.data?.message || "", {
            variant: "error",
          });
        });
    } else {
      httpClient()
        .post(`/sellers/products`, dataToSend)
        .then((res) => {
          console.log(res.data);
          setProduct(res.data);
          setLoading(false);
          setActiveStep((e) => e + 1);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(err?.response?.data?.message || "", {
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 600,
          width: "100%",
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          mb: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.backgroundColor,
              color: theme.textColor,
              border: `1px ${theme.borderColor} solid`,
              borderRadius: 1,
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SmartphoneRoundedIcon sx={{ color: theme.buttonColor }} />
          </Box>

          <Typography
            variant="h3"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            What are you selling?
          </Typography>

          <SelectStore
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            curStoreId={product?.store}
          />

          <Divider sx={{ width: "100%" }} />

          <Box sx={{ width: "100%" }}>
            <PDInput
              required
              label="Name"
              placeholder="Enter product name here"
              form={{ ...register("name", { required: true }) }}
              error={errors.name}
              helperText={errors.name ? "Name is required" : ""}
            />
            <Collapse in={!showSearchTags} mountOnEnter unmountOnExit>
              <Box sx={{ width: "100%", mt: 3 }}>
                <Button
                  startIcon={<AddRoundedIcon />}
                  onClick={() => setShowSearchTags((e) => !e)}
                >
                  Add Search Tags
                </Button>
              </Box>
            </Collapse>

            <Collapse in={showSearchTags} mountOnEnter unmountOnExit>
              <Box sx={{ width: "100%", mt: 3 }}>
                <PDSelect
                  label="Search Tags"
                  placeholder="Add search tags"
                  isCreatable
                  isMulti
                  value={searchTags}
                  onChange={setSearchTags}
                />
              </Box>
            </Collapse>
          </Box>

          <SelectCategory
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            curCategories={product?.categories}
          />

          <SelectBrand
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            curBrandId={product?.brand}
          />

          <PDInput
            label="Model"
            placeholder="Enter model here"
            form={{ ...register("model") }}
            error={errors?.model}
            helperText={errors?.model?.message}
          />
        </Box>

        <FullScreenDialogActionButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          loading={loading}
        />
      </Box>
    </>
  );
}

const SelectStore = ({ selectedStore, setSelectedStore, curStoreId }) => {
  const [stores, setStores] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (curStoreId && stores) {
      setSelectedStore(stores.find((s) => s.value === curStoreId));
    }
  }, [curStoreId, stores]);

  const getData = () => {
    setLoading(true);

    const query = {
      range: JSON.stringify([0, 100]),
      filter: JSON.stringify({
        status: "active",
        search: "",
        isArchived: false,
        isDeleted: false,
      }),
    };

    httpClient()
      .get(`/sellers/stores?${stringify(query)}`)
      .then((res, i) => {
        let data = res?.data?.result || [];

        setStores(
          data?.map((item, index) => {
            let sx = { label: item.name, value: item._id };

            index === 0 && setSelectedStore(sx);
            return sx;
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <PDSelect
      required
      label="Select store"
      placeholder="Select a store"
      isLoading={loading}
      defaultValue={stores[0]}
      options={stores}
      value={selectedStore}
      onChange={setSelectedStore}
    />
  );
};

const SelectCategory = ({
  selectedCategory,
  setSelectedCategory,
  curCategories,
}) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (curCategories) {
      organizeCategories(curCategories)?.map((obj) => {
        let parents = getAllParents(obj);

        console.log("parents  : ", parents);
        setSelectedCategory({
          label: parents
            ?.reverse()
            ?.map((i) => i.name)
            .join(" / "),
          value: parents,
        });
      });
    }
  }, [curCategories]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    httpClient()
      .get(`/sellers/categories`)
      .then((res, i) => {
        let categories = res?.data || [];

        let sx = [];

        categories.map((obj) => {
          let parents = getAllParents(obj);
          // console.log("parents  : ", parents);

          sx.push({
            label: parents
              ?.reverse()
              ?.map((i) => i.name)
              .join(" / "),
            value: parents,
          });
        });

        setCategories(sx);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <PDSelect
      required
      label="Select Category"
      placeholder="Select a Category"
      isLoading={loading}
      options={categories}
      value={selectedCategory}
      onChange={setSelectedCategory}
    />
  );
};

const SelectBrand = ({ selectedBrand, setSelectedBrand, curBrandId }) => {
  const { theme } = useContext(ThemeContext);
  const [brands, setBrands] = useState([]);

  const [showAddBrandDialog, setShowAddBrandDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (curBrandId && brands) {
      setSelectedBrand(brands.find((s) => s.value === curBrandId));
    }
  }, [curBrandId, brands]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    httpClient()
      .get(`/sellers/brands`)
      .then((res) => {
        // console.log(res.data);
        let data = res?.data || [];

        setBrands(
          data?.map((item) => {
            let sx = {
              label: item.name,
              value: item._id,
              image: process.env.REACT_APP_S3_URL + item.icon,
            };
            return sx;
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const Option = ({ innerProps, label, data, isFocused, isSelected }) => (
    <Box
      {...innerProps}
      sx={{
        backgroundColor: isSelected
          ? theme.buttonColor
          : isFocused
          ? theme.buttonColor + 11
          : theme.buttonTextColor,
        color: isSelected ? theme.buttonTextColor : theme.textColor2,

        p: 1,
        display: "flex",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
      }}
    >
      <Avatar
        sx={{
          width: 20,
          height: 20,
          backgroundColor: isSelected ? theme.buttonTextColor : "transparent",
          borderRadius: 0.5,
        }}
        src={data.image}
        alt={label}
      />

      <Typography variant="h5" fontWeight={400} sx={{ color: "inherit" }}>
        {label}
      </Typography>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          gap: 1,
        }}
      >
        <PDSelect
          required
          label="Select Brand"
          placeholder="Select a brand"
          isLoading={loading}
          // defaultValue={brands[0]}
          options={brands}
          value={selectedBrand}
          onChange={setSelectedBrand}
          components={{ Option }}
        />
        <IconButton
          color="primary"
          sx={{
            height: 38,
            width: 38,
            backgroundColor: "rgb(248,248,253)",
            border: `1px ${theme.borderColor} solid`,
          }}
          onClick={() => setShowAddBrandDialog((e) => !e)}
        >
          <AddRoundedIcon />
        </IconButton>
      </Box>

      <AddBrandDialog
        open={showAddBrandDialog}
        setOpen={setShowAddBrandDialog}
        getData={getData}
      />
    </>
  );
};

// function generateData(items) {
//   const generateValue = (item) => {
//     const { parent, ...rest } = item;
//     if (parent) {
//       const parentValue = generateValue(parent);
//       return [...parentValue, rest];
//     }
//     return [rest];
//   };

//   const label = items[items.length - 1].name;
//   const value = generateValue(items[items.length - 1]);

//   return {
//     label,
//     value,
//   };
// }

// function sortData(data) {
//   const result = [];

//   function traverse(item, parents = []) {
//     const { name, parent } = item;
//     const label = [...parents.map((p) => p.name), name].join(" / ");
//     const value = parents.reduceRight(
//       (arr, p) => [...arr, p, p.parent],
//       [item]
//     );

//     result.push({ label, value });

//     if (parent && parent.parent) {
//       traverse(parent, [...parents, item]);
//     }
//   }

//   for (const item of data) {
//     traverse(item);
//   }

//   return result;
// }

// // Function to retrieve the category path
// function getCategoryPath(categoryId) {
//   const category = categories.find((cat) => cat._id === categoryId);
//   if (!category) {
//     return null;
//   }
//   let path = [category.name];
//   let parentId = category.parent;
//   while (parentId) {
//     const parentCategory = categories.find((cat) => cat._id === parentId);
//     if (!parentCategory) {
//       break;
//     }
//     path.unshift(parentCategory.name);
//     parentId = parentCategory.parent;
//   }
//   return path.join(" / ");
// }
// const categoryData = categories
//   .filter((category) => !categories.some((cat) => cat.parent === category._id))
//   .map((category) => {
//     const categoryPath = getCategoryPath(category._id);
//     const categoriesInPath = categories.filter(
//       (cat) =>
//         categoryPath.includes(cat.name) &&
//         categoryPath.split(" / ").includes(cat.name)
//     );
//     return { label: categoryPath, value: categoriesInPath };
//   });

// function getCategoryPath(categoryId, categories) {
//   const category = categories.find((cat) => cat._id === categoryId);
//   if (!category) {
//     return null;
//   }
//   let path = [category.name];
//   let parentId = category.parent;
//   while (parentId) {
//     const parentCategory = categories.find((cat) => cat._id === parentId);
//     if (!parentCategory) {
//       break;
//     }
//     path.unshift(parentCategory.name);
//     parentId = parentCategory.parent;
//   }
//   return path.join(" / ");
// }

// function getAllParents(obj, parents = []) {
//   if (obj.parent) {
//     parents.push(obj.parent);
//     getAllParents(obj.parent, parents);
//   }
//   return parents;
// }

function getAllParents(obj, parentNames = []) {
  if (obj) parentNames.push(obj);

  if (obj.parent) getAllParents(obj.parent, parentNames);

  return parentNames;
}

function organizeCategories(items = []) {
  if (items?.length < 1) {
    console.log("itemsitemsitemsitemsitemsitems : ", items);
    return [];
  }

  const organizedItems = [];
  const itemMap = {};

  // Create a map of items using their IDs
  for (const item of items) {
    itemMap[item._id] = item;
  }

  // Traverse the items and move them under their respective parent
  for (const item of items) {
    const parentId = item.parent;
    if (parentId && itemMap[parentId]) {
      const parent = itemMap[parentId];
      parent.parent = item;
    } else {
      organizedItems.push(item);
    }
  }

  return organizedItems;
}
