import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Slide,
} from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import httpClient from "src/libs/httpClient";

import StepperSidebar from "src/components/StepperSidebar";
import { ThemeContext } from "src/context";
import BasicInformationsStep from "./steps/BasicInformationsStep";
import DetaildDescriptionStep from "./steps/DetaildDescriptionStep";
import DeliveryStep from "./steps/DeliveryStep";
import PicturesAndColorsStep from "./steps/picturesAndColorsStep/PicturesAndColorsStep";
import PriceAndStockStep from "./steps/PriceAndStockStep";
import FinishStep from "../setupStoreDialog/steps/FinishStep";

const steps = [
  {
    title: "Basic Informations",
    description: "What are you selling?",
  },
  {
    title: "Detaild Description",
    description: "Tell us more about your product",
  },
  {
    title: "Service & Delivery",
    description: "How do you want to deliver?",
  },
  {
    title: "Pictures & Colors",
    description: "How does it look like?",
  },
  {
    title: "Price & Stock",
    description: "How does it look like?",
  },

  {
    title: "Finish!",
    description: "Your all done.",
  },
];

export default function AddProductDialog({
  productId,
  open,
  setOpen,
  getData,
}) {
  const { theme, bp } = useContext(ThemeContext);

  const [activeStep, setActiveStep] = useState(0);
  const [product, setProduct] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      if (productId) getProduct();
      else setProduct({});
    }
  }, [open, productId]);

  const getProduct = () => {
    setLoading(true);
    httpClient()
      .get(`/sellers/products/${productId}`)
      .then((res) => {
        console.log(" getProduct : ", res.data);
        setProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          p: 0,
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "350px 1fr",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
        }}
      >
        {!bp?.md && (
          <StepperSidebar
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            existingId={productId}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 2,
            position: "relative",

            height: "100%",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 40 }} />
            <LinearProgress
              sx={{
                m: 3,
                maxWidth: 400,
                width: "100%",
                minHeight: 5,
                borderRadius: 5,
              }}
              color="primary"
              variant="determinate"
              value={(activeStep / (steps?.length - 1)) * 100}
            />

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {loading && <CircularProgress thickness={5} size={24} />}

              <IconButton
                sx={{
                  width: 40,
                  color: theme.buttonColor,
                  backgroundColor: theme.buttonColor + "22",
                }}
                onClick={() => {
                  setOpen(false);
                  getData();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          </Box>

          {activeStep === 0 && (
            <BasicInformationsStep
              product={product}
              setProduct={setProduct}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              open={open}
            />
          )}
          {activeStep === 1 && (
            <DetaildDescriptionStep
              product={product}
              setProduct={setProduct}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              open={open}
            />
          )}
          {activeStep === 2 && (
            <DeliveryStep
              product={product}
              setProduct={setProduct}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              open={open}
            />
          )}
          {activeStep === 3 && (
            <PicturesAndColorsStep
              product={product}
              setProduct={setProduct}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              open={open}
            />
          )}
          {activeStep === 4 && (
            <PriceAndStockStep
              product={product}
              setProduct={setProduct}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              open={open}
            />
          )}
          {activeStep === 5 && (
            <FinishStep
              setOpen={() => {
                setOpen();
                getData();
              }}
              readyForReview={product?.readyForReview}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
