import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import capitalizeWords from "src/libs/capitalizeWords";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";

export default function OrderDetails({ selectedOrder }) {
  const { theme, bp } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [order, setOrders] = useState();

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    if (selectedOrder) {
      getData();
    }
  }, [selectedOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    setLoading(true);

    httpClient()
      .get(`/orders/${selectedOrder}`)
      .then((res) => {
        console.log(res.data);

        const status = res.data.status;

        if (
          [
            "pending",
            "pendingPayment",
            "processing",
            "shipped",
            "localFacility",
            "outForDelivery",
            "delivered",
            "completed",
          ].includes(status)
        ) {
          if (["pending", "pendingPayment"].includes(status)) {
            setActiveStep(1);
          } else if (["processing"].includes(status)) {
            setActiveStep(2);
          } else if (["shipped"].includes(status)) {
            setActiveStep(3);
          } else if (["delivered"].includes(status)) {
            setActiveStep(4);
          } else if (["completed"].includes(status)) {
            setActiveStep(5);
          }

          setSteps([
            "Order Received",
            "Processing",
            "Local Facility",
            "Out For Delivery",
            "Order on the way",
            "Order Delivered",
          ]);
        } else if (status === "onHold") {
          setSteps([
            "Order Received",
            "Processing",
            "On Hold",
            "Order on the way",
            "Order Delivered",
          ]);
          setActiveStep(3);
        } else if (["canceled", "refunded"].includes(status)) {
          setSteps(["Order Received", "Processing", capitalizeWords(status)]);
          setActiveStep(3);
        }

        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.ui,
        border: `1px ${theme.borderColor} solid`,
        borderRadius: 1,
        py: 2,
        overflow: "hidden",
        top: 16,
        position: "sticky",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Order Details
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              variant="p"
              fontWeight={500}
              sx={{ color: theme.textColor2 }}
            >
              Invoice Number:{" "}
            </Typography>
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={100}
                height={10}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <Typography
                variant="p"
                fontWeight={500}
                sx={{ color: theme.textColor }}
              >
                {order?.invoiceNumber}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mt: 2 }} />

      <Box
        sx={{
          px: 2,
          display: "grid",
          gridTemplateColumns: bp.md ? "1fr" : "4fr 1px 1fr",
        }}
      >
        <Box
          sx={{
            py: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Delivery Address
          </Typography>

          {loading ? (
            <Skeleton
              variant="rectangular"
              width="75%"
              height={15}
              sx={{ borderRadius: 1, mt: 0.5 }}
            />
          ) : (
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ color: theme.textColor3 }}
            >
              {order?.deliveryAddress?.address}
            </Typography>
          )}

          <Box sx={{ py: 0.5 }} />

          <Typography
            variant="h5"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Billing Address
          </Typography>

          {loading ? (
            <Skeleton
              variant="rectangular"
              width="75%"
              height={15}
              sx={{ borderRadius: 1, mt: 0.5 }}
            />
          ) : (
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ color: theme.textColor3 }}
            >
              {order?.billingAddress?.address ||
                order?.deliveryAddress?.address}
            </Typography>
          )}
        </Box>

        <Divider orientation="vertical" />

        <Box
          sx={{
            p: 2,
            pr: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="p" sx={{ color: theme.textColor2 }}>
              Sub Total
            </Typography>

            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={15}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <Typography
                variant="p"
                sx={{ fontWeight: 600, color: theme.textColor }}
              >
                ৳{order?.subTotal?.toLocaleString()}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="p" sx={{ color: theme.textColor2 }}>
              Discount
            </Typography>

            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={15}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <Typography
                variant="p"
                sx={{ fontWeight: 600, color: theme.textColor }}
              >
                ৳{order?.discount?.toLocaleString()}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="p" sx={{ color: theme.textColor2 }}>
              Delivery Fee
            </Typography>

            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={15}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <Typography
                variant="p"
                sx={{ fontWeight: 600, color: theme.textColor }}
              >
                ৳{order?.deliveryFee?.toLocaleString()}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: 600, color: theme.textColor }}
            >
              Total
            </Typography>

            {loading ? (
              <Skeleton
                variant="rectangular"
                width={35}
                height={15}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <Typography
                variant="p"
                sx={{ fontWeight: 700, color: theme.textColor }}
              >
                ৳{order?.total?.toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
        }}
      >
        {loading ? (
          <Skeleton
            variant="rectangular"
            width="75%"
            height={75}
            sx={{ borderRadius: 1 }}
          />
        ) : (
          <Stepper
            sx={{ width: "100%", color: "red" }}
            activeStep={activeStep}
            alternativeLabel
            color="secondary"
          >
            {steps.map((label, index) => (
              <Step key={label}>
                {/* <StepIcon></StepIcon> */}
                {/* <StepIcon icon={<CloseRoundedIcon />}></StepIcon> */}
                <StepLabel
                  icon={
                    <Box
                      sx={{
                        backgroundColor:
                          activeStep < index + 1
                            ? theme.textColor3
                            : order?.status === "onHold"
                            ? theme.accent.yollow
                            : ["canceled", "refunded"].includes(order?.status)
                            ? theme.accent.red
                            : theme.buttonColor,
                        color: theme.buttonTextColor,
                        borderRadius: 10,
                        height: 26,
                        width: 26,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {activeStep <= index + 1 ? (
                        index + 1
                      ) : order?.status === "onHold" ? (
                        <PauseRoundedIcon
                          fontSize="small"
                          sx={{ fill: theme.buttonTextColor }}
                        />
                      ) : ["canceled", "refunded"].includes(order?.status) ? (
                        <CloseRoundedIcon
                          fontSize="small"
                          sx={{ fill: theme.buttonTextColor }}
                        />
                      ) : (
                        <CheckRoundedIcon
                          fontSize="small"
                          sx={{ fill: theme.buttonTextColor }}
                        />
                      )}
                    </Box>
                  }
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>

      {/* <Divider sx={{ mb: 2 }} /> */}

      <Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCell} />
              <TableCell align="left" sx={styles.tableCell}>
                Items
              </TableCell>
              <TableCell align="center" sx={styles.tableCell}>
                Quantity
              </TableCell>

              <TableCell align="right" sx={{ ...styles.tableCell, pr: 2 }}>
                Price
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {order?.products.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    height: 57,
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-child(odd)": {
                      backgroundColor: theme.backgroundColor,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      src={
                        process.env.REACT_APP_S3_URL +
                        (row?.product?.images[0]?.thumb ||
                          row?.product?.images[0]?.large)
                      }
                      sx={{ borderRadius: 0 }}
                    >
                      {row?.product?.name[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    {row?.product?.name}
                  </TableCell>
                  <TableCell align="center" sx={styles.tableCell}>
                    {row?.quantity}
                  </TableCell>
                  <TableCell align="right" sx={{ ...styles.tableCell, pr: 2 }}>
                    {row?.price}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

const styles = { tableCell: { p: 0, py: 1, pl: 1 } };
