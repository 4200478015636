import { useState, useEffect, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);

  const [enableDarkMode, setEnableDarkMode] = useState(false);

  const accent = {
    red: "#bc002d",
    yellow: "#FFB836",
    green: "#56CDAD",
    orange: "#FF6550",
    blue: "#2E5BFF",
    purple: "#4640DE",

    purpleOld: "#7B61FF",
  };
  const breakpoints = {
    sm: useMediaQuery("(max-width:560px)"),
    md: useMediaQuery("(max-width:720px)"),
    xl: useMediaQuery("(max-width:1250px)"),
  };

  const light = {
    textColor: "#25324B",
    textColor2: "#515B6F",
    textColor3: "#7C8493",

    buttonColor2: "#023c29",
    // buttonColor: "#2E5BFF",
    buttonColor: "#FF6550",
    buttonTextColor: "#FFFfff",

    buttonColor3: "#7B61FF",
    // buttonColor3: "#01291c",
    // #7B61FF

    ui: "#ffffff",
    backgroundColor: "#F8F8FD",
    backgroundColor2: "#D6DDEB",

    borderRadius: 5,
    borderColor: "#e0e0e0",
    accent,
    name: "light",
  };

  const dark = {};

  const [hideNavigation, setHideNavigation] = useState([]);
  useEffect(() => {
    let match = ["/login", "/signup", "/forgot-password", "/admin", "/seller"];

    const data = match?.filter((url) => {
      return pathname.includes(url);
    });
    setHideNavigation(data?.length > 0 ? true : false);
  }, [pathname]);

  useEffect(() => {
    if (showSidebar && breakpoints.md) {
      setShowSidebar(false);
    }
  }, [showSidebar, breakpoints]);

  return (
    <ThemeContext.Provider
      value={{
        enableDarkMode,
        toggleDarkMode: () => setEnableDarkMode((e) => !e),
        showSidebar,
        setShowSidebar,

        hideNavigation,

        breakpoints,
        bp: breakpoints,

        light,
        dark,
        theme: enableDarkMode ? dark : light,
        accent,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: "#2E5BFF",
    },
    secondary: {
      main: "#023c29",
    },
    tertiary: {
      main: "#33AC2E",
    },
    error: {
      main: "#bc002d",
    },
    background: {
      default: "#FFF",
    },
    success: {
      main: "#26A4FF",
    },
    info: {
      main: "#4640DE",
    },
  },

  typography: {
    fontFamily: ["Inter", "Public Sans", "sans-serif", "Arial", "Roboto"].join(
      ","
    ),

    big: {
      fontSize: 72,
      fontWeight: 700,
    },
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
    },

    p: {
      fontSize: 12,
      fontWeight: 500,
    },
    p2: {
      fontSize: 11,
      fontWeight: 500,
    },
    p3: {
      fontSize: 10,
      fontWeight: 500,
    },

    // Buttons
    button1: {
      fontSize: 18,
      fontWeight: 700,
    },
    button2: {
      fontSize: 16,
      fontWeight: 600,
    },
    button3: {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  // spacing: 1,
  // shape: {
  //   borderRadius: 1,
  // },
});
