import { Bar, Line } from "react-chartjs-2";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";

export default function ProductTab({ startDate, endDate, setGrouping }) {
  const max580 = useMediaQuery("(max-width:580px)");
  const { theme } = useContext(ThemeContext);

  const [data, setData] = useState();

  useEffect(() => {
    if (!startDate || !endDate) return;
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    httpClient()
      .get(
        `/admin/analytics/view/products?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      )
      .then((res) => {
        console.log("Product Tab: ", res.data);

        const resxxx = formatChartData(res.data.data, res.data.grouping);
        console.log("Res from getData : ", resxxx);

        setGrouping(res.data.grouping);
        setData(resxxx);
      })
      .catch((err) => {
        console.log("Error from getData : ", err.message);
      });
  };

  const formatChartData = (data, grouping = "daily") => {
    const productData = {};
    const periods = new Set();
    console.log("Data : ", data, " - Grouping : ", grouping);

    // Define a helper function to format period labels
    const formatLabel = (date, grouping) => {
      switch (grouping) {
        case "weekly":
          const [year, week] = date?.split("-"); // Assuming date comes in 'year-week' format
          return `Week ${week}, ${year}`;
        case "monthly":
          return date; // Assuming date is already in 'YYYY-MM' format
        default:
          return date; // 'YYYY-MM-DD' format for daily
      }
    };

    // Process each entry
    data.forEach(({ _id: { date, product }, count }) => {
      if (!productData[product]) {
        productData[product] = {
          label: product,
          data: [],
          stack: "Products",
          backgroundColor: getRandomColor(),

          borderWidth: 1,
          pointStyle: "rectRot",
          pointRadius: 5,
          pointBorderColor: "rgb(0, 0, 0)",
        };
      }
      productData[product].data.push({
        x: formatLabel(date, grouping),
        y: count,
      });
      periods.add(date);
    });

    // Sort periods depending on grouping
    const sortedPeriods = Array.from(periods)
      .sort((a, b) => {
        if (grouping === "daily") {
          return new Date(a) - new Date(b);
        } else {
          return a.localeCompare(b); // Simple string comparison should suffice for 'YYYY-WW' or 'YYYY-MM'
        }
      })
      .map((date) => formatLabel(date, grouping)); // Apply formatting to each period label for display

    Object.values(productData).forEach((dataset) => {
      const dataByPeriod = dataset.data.reduce((acc, item) => {
        acc[item.x] = item.y;
        return acc;
      }, {});
      dataset.data = sortedPeriods.map((period) => ({
        x: period,
        y: dataByPeriod[period] || 0,
      }));
    });

    return {
      labels: sortedPeriods,
      datasets: Object.values(productData),
    };
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <div style={{ display: "flex", flexDirection: max580 ? "column" : "row" }}>
      <div style={{ flex: 1 }}>
        <div style={{ paddingBottom: 20 }} />
        {data?.datasets?.length > 0 ? (
          <CustomChart data={data} />
        ) : (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{
              margin: "20px 20px 0 20px",
              maxWidth: "calc(100% - 40px)",
              height: 360,
            }}
          />
        )}

        <Table
          sx={{
            backgroundColor: theme.backgroundColor,
            border: `1px ${theme.borderColor} solid`,
            mt: 8,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ color: theme.textColor2 }}>Legend</TableCell>

              <TableCell style={{ color: theme.textColor2 }}>Product</TableCell>
              <TableCell style={{ color: theme.textColor2 }} align="center">
                Total Views
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.datasets?.map((dataset, index) => (
              <TableRow
                key={index}
                sx={{
                  height: 57,
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:nth-child(odd)": {
                    backgroundColor: theme.backgroundColor,
                  },
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: dataset.backgroundColor,
                    width: 20,
                    height: 20,
                    // borderRadius: 5,
                    aspectRatio: "1/1",
                  }}
                />
                <TableCell style={{ color: theme.textColor2 }}>
                  {dataset.label}
                </TableCell>

                <TableCell style={{ color: theme.textColor2 }} align="center">
                  {dataset.data.reduce((acc, cur) => {
                    return acc + cur.y;
                  }, 0)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {/* <div
        style={{
          padding: 20,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <DataBox
          label="Job Views"
          value={totalViews?.reduce((acc, cur) => {
            return acc + cur.count;
          }, 0)}
          icon={
            <RemoveRedEyeOutlinedIcon
              style={{
                color: "#FFF",
                fontSize: "1.25rem",
              }}
            />
          }
          iconColor="#FFB836"
        />
      </div> */}
    </div>
  );
}

const CustomChart = ({ data }) => {
  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: { usePointStyle: true },
      },
    },
  };

  // accumulateDataForLine should be a funtion that is same as data but its datasets valus should be this value + previous value
  const accumulateDataForLine = (data) => {
    const datasets = data.datasets.map((dataset) => {
      const data = dataset.data.map((d, i) => {
        const y = dataset.data.slice(0, i + 1).reduce((acc, cur) => {
          return acc + cur.y;
        }, 0);
        return { x: d.x, y };
      });
      return { ...dataset, data };
    });
    return { ...data, datasets };
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 16,
      }}
    >
      <div>
        <Bar options={options} data={data} />
      </div>
      <div>
        <Line options={options} data={accumulateDataForLine(data)} />
      </div>
    </div>
  );
};
