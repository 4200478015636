import React from "react";
import MyRouter from "./Router";
import { theme } from "src/context/ThemeContext";
import { ThemeContextProvider, AuthContextProvider } from "src/context";

import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "@mui/material/styles";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export default function App(props) {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ThemeContextProvider>
            <AuthContextProvider>
              <MyRouter />
            </AuthContextProvider>
          </ThemeContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
