import { useState, useContext } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";

import deletePhoto from "src/libs/deletePhoto";
import { ThemeContext } from "src/context";
import AddBrandDialog from "src/dialogs/AddBrandDialog";
import httpClient from "src/libs/httpClient";
import ActionDialog from "src/dialogs/ActionDialog";

export default function BrandItem({ brand, getData, curIndex }) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [showBrandDialog, setShowBrandDialog] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const handleDelete = (brand) => {
    setLoading(true);

    if (brand.icon) {
      deletePhoto(brand?.icon)
        .then((res) => {
          // console.log(res.data);
          httpClient()
            .delete(`/admin/brands/${brand?._id}`)
            .then((res) => {
              console.log(res.data);
              setLoading(false);
              getData();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      httpClient()
        .delete(`/admin/brands/${brand?._id}`)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          getData();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          ml: brand?.parent ? 5 : 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.ui,
            border: `1px ${theme.borderColor} solid`,
            // borderRadius: 1,

            borderTop: curIndex !== 0 && "none",
            // borderBottom: curIndex === 1 && "none",

            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar
              sx={{ borderRadius: 1 }}
              src={process.env.REACT_APP_S3_URL + brand?.icon}
              alt={brand?.name}
            />

            <Box>
              <Typography
                sx={{
                  color: theme.textColor,
                  fontWeight: 500,
                  lineHeight: 1,
                }}
                variant="h6"
              >
                {brand?.name}
              </Typography>
              <Typography
                sx={{ color: theme.textColor3, fontWeight: 400 }}
                variant="p"
              >
                {brand?.slug}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {loading && <CircularProgress thickness={5} size={18} />}

            <IconButton
              sx={{ color: theme.buttonColor }}
              onClick={() => setShowBrandDialog(true)}
            >
              <EditRoundedIcon fontSize="small" />
            </IconButton>

            <IconButton
              sx={{ color: theme.accent.red }}
              onClick={() => setShowConfirmButton(true)}
            >
              <DeleteRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {/* {brand?.children?.map((c) => (
          <AdminBrandItem
            // curIndex={index}
            key={c?._id}
            brand={c}
            getData={getData}
          />
        ))} */}
      </Box>

      <AddBrandDialog
        open={showBrandDialog}
        setOpen={setShowBrandDialog}
        getData={getData}
        currentBrand={brand}
      />

      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Delete Brand"
        description={`Are you sure you want to delete this brand? This action is irreversible.`}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete(brand);
          setShowConfirmButton(false);
        }}
      />
    </>
  );
}
