import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";

import { ThemeContext } from "src/context";

export default function StepperSidebar({
  existingId,
  steps,
  activeStep,
  setActiveStep,
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        backgroundColor: theme.backgroundColor,
        py: 5,
        px: 2,
      }}
    >
      <Stepper
        activeStep={activeStep}
        //   alternativeLabel
        orientation="vertical"
      >
        {steps.map((item, index) => {
          const stepProps = {};

          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography
          //       variant="p"
          //       fontWeight={500}
          //       sx={{ color: theme.textColor3 }}
          //     >
          //       Optional
          //     </Typography>
          //   );
          // }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step
              key={index}
              {...stepProps}
              onClick={() => {
                if (existingId === true) {
                  setActiveStep(index);
                }
              }}
            >
              <StepLabel {...labelProps}>{item?.title}</StepLabel>

              <StepContent>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ color: theme.textColor3 }}
                >
                  {item.description}
                </Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
