import { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import FullScreenDialogActionButtons from "src/components/FullScreenDialogActionButtons";
import PDSelect from "src/components/_pdStyle/PDSelect";
import capitalizeWords from "src/libs/capitalizeWords";
import httpClient from "src/libs/httpClient";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";

export default function PriceAndStockStep({
  open,
  product,
  setProduct,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [selectedCondition, setSelectedCondition] = useState();

  useEffect(() => {
    if (open && product) {
      setSelectedCondition(
        product?.condition
          ? {
              label: capitalizeWords(product?.condition),
              value: product?.condition,
            }
          : conditions[0]
      );
      setValue("stock", product?.stock);
      setValue("price", product?.price);
    }
  }, [open, product]);

  const onSubmit = (data) => {
    const dataToSend = {
      ...data,
      condition: selectedCondition.value,
    };
    setLoading(true);
    httpClient()
      .put(`/sellers/products/${product?._id}`, dataToSend)
      .then((res) => {
        setActiveStep((e) => e + 1);
        setProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
      });
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 600,
          width: "100%",
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          mb: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.backgroundColor,
              color: theme.textColor,
              border: `1px ${theme.borderColor} solid`,
              borderRadius: 1,
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AttachMoneyRoundedIcon sx={{ color: theme.buttonColor }} />
          </Box>

          <Typography
            variant="h3"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Price & Stock
          </Typography>

          <PDInput
            type="number"
            label="Stock"
            placeholder="Enter available quantity here"
            form={{
              ...register("stock", {
                min: {
                  value: 0,
                  message: "Must be greater than 0 BDT",
                },
              }),
            }}
            error={errors?.stock}
            helperText={errors?.stock?.message}
          />

          <PDSelect
            required
            label="Select Condition"
            placeholder="Select a Condition"
            isLoading={loading}
            options={conditions}
            value={selectedCondition}
            onChange={setSelectedCondition}
          />

          <PDInput
            type="number"
            label="Price"
            placeholder="Enter price here"
            form={{
              ...register("price", {
                min: {
                  value: 0,
                  message: "Must be greater than 0 BDT",
                },
              }),
            }}
            error={errors?.price}
            helperText={errors?.price?.message}
          />
        </Box>

        <FullScreenDialogActionButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          loading={loading}
        />
      </Box>
    </>
  );
}

const conditions = [
  {
    label: capitalizeWords("new"),
    value: "new",
  },
  {
    label: capitalizeWords("likeNew"),
    value: "likeNew",
  },
  {
    label: capitalizeWords("used"),
    value: "used",
  },
  {
    label: capitalizeWords("refurbished"),
    value: "refurbished",
  },
];
