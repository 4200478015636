import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import MenuIcon from "@mui/icons-material/Menu";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import FitbitRoundedIcon from "@mui/icons-material/FitbitRounded";

import { useContext, useEffect, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import { Link, useLocation } from "react-router-dom";

import logo from "src/assets/logo.svg";

const drawerWidth = 240;

const adminMenuPrimary = [
  {
    id: 1,
    label: "Dashboard",
    to: "/",
    icon: <DashboardRoundedIcon fontSize="small" />,
  },
  {
    id: 2,
    label: "Orders",
    to: "/orders",
    icon: <ListRoundedIcon fontSize="small" />,
  },
  {
    id: 3,
    label: "Stores",
    to: "/stores",
    icon: <StoreRoundedIcon fontSize="small" />,
  },
  {
    id: 4,
    label: "Products",
    to: "/products",
    icon: <Inventory2RoundedIcon fontSize="small" />,
  },
  {
    id: 5,
    label: "Users",
    to: "/users",
    icon: <PeopleRoundedIcon fontSize="small" />,
  },
  {
    id: 6,
    label: "Categories",
    to: "/categories",
    icon: <CategoryRoundedIcon fontSize="small" />,
  },
  {
    id: 7,
    label: "Brands",
    to: "/brands",
    icon: <FitbitRoundedIcon fontSize="small" />,
  },
];

const sellerMenuPrimary = [
  {
    id: 1,
    label: "Dashboard",
    to: "/",
    icon: <DashboardRoundedIcon fontSize="small" />,
  },
  {
    id: 2,
    label: "Orders",
    to: "/orders",
    icon: <ListRoundedIcon fontSize="small" />,
  },
  {
    id: 2,
    label: "Stores",
    to: "/stores",
    icon: <StoreRoundedIcon fontSize="small" />,
  },
  {
    id: 3,
    label: "Products",
    to: "/products",
    icon: <Inventory2RoundedIcon fontSize="small" />,
  },
  {
    id: 4,
    label: "Employees",
    to: "/employees",
    icon: <PeopleRoundedIcon fontSize="small" />,
  },
];

export default function CPanelSidebar({ variant }) {
  const { theme, showSidebar, setShowSidebar } = useContext(ThemeContext);
  const { user, logout } = useContext(AuthContext);

  return (
    <Drawer
      // "rgb(0, 46, 28)"
      PaperProps={{
        sx: {
          backgroundColor: theme.ui || theme.buttonColor2,
          color: "#FFF",
          justifyContent: "space-between",
        },
      }}
      variant="permanent"
      open={showSidebar}
    >
      <Box>
        <Box
          sx={{
            height: 60,
            pl: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton onClick={() => setShowSidebar((e) => !e)}>
            <MenuIcon fontSize="small" />
          </IconButton>

          <Link to="/" style={{ cursor: "pointer" }}>
            <img
              style={{ height: 25, opacity: showSidebar ? 1 : 0 }}
              src={logo}
              alt="Poket Dokan"
            />
          </Link>
        </Box>

        {/* <Divider sx={{ backgroundColor: "rgba(255,255,255,.15)" }} /> */}
        <List sx={{ p: 0 }}>
          {/* {(variant === "admin" ? adminMenuPrimary : sellerMenuPrimary).map( */}
          {(["super", "admin"].includes(user?.role)
            ? adminMenuPrimary
            : sellerMenuPrimary
          ).map((item, index) => {
            if (item.role && item.role !== user?.role) return null;
            return (
              <Link key={index} to={item.to} style={{ textDecoration: "none" }}>
                <CustomListItem {...item} />
              </Link>
            );
          })}
        </List>
        {/* <Divider sx={{ backgroundColor: "rgba(255,255,255,.15)" }} /> */}
      </Box>

      <Box>
        <Divider sx={{ backgroundColor: "rgba(255,255,255,.15)" }} />
        <Link to="/settings" style={{ textDecoration: "none" }}>
          <CustomListItem
            label="Setting"
            to="/settings"
            icon={<SettingsIcon sx={{ fill: "currentcolor" }} />}
          />
        </Link>

        <CustomListItem
          label="Logout"
          icon={<LogoutRoundedIcon />}
          onClick={() => logout()}
        />
      </Box>
    </Drawer>
  );
}

const CustomListItem = ({ label, icon, to, onClick }) => {
  const { pathname } = useLocation();
  const { theme, showSidebar } = useContext(ThemeContext);

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    // const newPathname = pathname.slice(1);

    if (pathname === "/" && to === "/") {
      setIsOpened(true);
    } else if (to?.length > 1 && pathname?.includes(to)) {
      setIsOpened(true);
    } else {
      setIsOpened(false);
    }
  }, [pathname]);

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",

        backgroundColor: isOpened
          ? theme.buttonColor + `22 !important`
          : "transparent",
        transition: "all 0.1s ease-in-out",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, .15)",
        },
        "*": {
          fill: isOpened ? theme.buttonColor : theme.textColor2,
          color: isOpened ? theme.buttonColor : theme.textColor2,
        },
      }}
    >
      <Tooltip
        disableHoverListener={showSidebar}
        title={label}
        placement="right"
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: showSidebar ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => onClick && onClick()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: showSidebar ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {label}
              </Typography>
            }
            sx={{ opacity: showSidebar ? 1 : 0 }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

// UI Stuff
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
