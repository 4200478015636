import { Avatar, Box, Divider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

import { AuthContext } from "src/context/AuthContext";
import { ThemeContext } from "src/context/ThemeContext";
import httpClient from "src/libs/httpClient";

import PDInput from "src/components/_pdStyle/PDInput";
import PDButton from "src/components/_pdStyle/PDButton";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import ActionDialog from "src/dialogs/ActionDialog";
import uploadPhoto from "src/libs/uploadPhoto";
import isURL from "src/libs/isURL";
import deletePhoto from "src/libs/deletePhoto";

export default function MyProfileSection() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme, bp } = useContext(ThemeContext);
  const { user, checkAuth } = useContext(AuthContext);

  const [files, setFiles] = useState([]);

  const [showActionButton, setShowActionButton] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("firstName", user?.firstName);
    setValue("lastName", user?.lastName);
    setValue("email", user?.email);
  }, []);

  const handleUpdateUser = (data) => {
    setLoading(true);

    httpClient()
      .put(`/users/${user?._id}`, data)
      .then((res) => {
        enqueueSnackbar("Updated successfully", { variant: "success" });
        setLoading(false);
        checkAuth();
      })
      .catch((err) => {
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
        console.log("ERROR from MyProfileSection : ", err.message);
        setLoading(false);
      });
  };

  const uploadImage = () => {
    setLoading(true);
    uploadPhoto(files[0], `users/${user?._id}/avatar`, {
      maxWidth: 512,
      maxHeight: 512,
    })
      .then((data) => {
        console.log(data);
        // onSubmit(data);

        httpClient()
          .put(`/users/${user?._id}`, { avatar: data })
          .then((res) => {
            enqueueSnackbar("Updated successfully", { variant: "success" });
            setShowActionButton(false);
            setLoading(false);
            setFiles([]);

            checkAuth();
          })
          .catch((err) => {
            enqueueSnackbar(
              removeUndefinedFromString(err?.response?.data?.message),
              { variant: "error" }
            );
            console.log("ERROR from MyProfileSection : ", err.message);
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function handleDelete() {
    deletePhoto(user?.avatar)
      .then((data) => {
        setLoading(true);

        httpClient()
          .put(`/users/${user?._id}`, { avatar: "" })
          .then((res) => {
            console.log(res.data);
            setLoading(false);
            setShowActionButton(true);
            checkAuth();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            // enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
          });
      })
      .catch((err) => {
        console.log(err, err.stack);
      });
  }

  return (
    <Box
      sx={{
        border: `1px ${theme.borderColor} solid`,
        borderRadius: 1,
        p: 2,
        overflow: "hidden",
        width: "100%",

        backgroundColor: theme.ui,
      }}
    >
      <Typography variant="h3" fontWeight={600} sx={{ color: theme.textColor }}>
        My Profile
      </Typography>

      <Box
        sx={{
          marginTop: 2,
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "2fr 1px 1fr",
          gap: 2,
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          component="form"
          onSubmit={handleSubmit(handleUpdateUser)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: bp?.sm ? "column" : "row",
              gap: bp?.sm ? 3 : 2,
            }}
          >
            <PDInput
              required
              label="First Name"
              placeholder="Enter first name here"
              form={{ ...register("firstName", { required: true }) }}
              error={errors.firstName}
              helperText={errors.firstName ? "First Name is required" : ""}
            />
            <PDInput
              required
              label="Last Name"
              placeholder="Enter last name here"
              form={{ ...register("lastName", { required: true }) }}
              error={errors.lastName}
              helperText={errors.lastName ? "Last Name is required" : ""}
            />
          </Box>

          <PDInput
            label="Email Address"
            placeholder="jonBob@example.com"
            required
            disabled={true}
            value={user?.email}
          />
          <PDButton style={{ maxWidth: 150 }} type="submit" loading={loading}>
            Save Changes
          </PDButton>
        </Box>

        <Divider orientation={bp?.md ? "horizontal" : "vertical"} flexItem />

        <div style={{ display: "flex", gap: 20 }}>
          <div
            style={{
              // minWidth: 150,
              // width: "100%",
              // maxWidth: 150,
              width: 150,
              height: 150,
              backgroundColor: theme.backgroundColor,
              borderRadius: theme.borderRadius,

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {user?.avatar ? (
              <Avatar
                style={{
                  width: "100%",
                  height: "100%",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  borderRadius: 0,
                  fontSize: "6rem",
                }}
                src={
                  isURL(user?.avatar)
                    ? user?.avatar
                    : process.env.REACT_APP_S3_URL + user?.avatar
                }
                alt={user?.firstName}
              />
            ) : (
              <PersonRoundedIcon
                style={{ fontSize: "6rem", color: theme.textColor3 }}
              />
            )}
          </div>
          <div
            style={{
              height: 150,
              display: "flex",
              gap: 20,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ color: theme.textColor }}>
              Upload or change your photo
            </p>
            <PDButton
              style={{ maxWidth: 150 }}
              onClick={() => {
                if (user?.avatar && !isURL(user?.avatar)) handleDelete();
                else setShowActionButton(true);
              }}
            >
              Upload New
            </PDButton>
          </div>
        </div>
      </Box>

      {/* <ProfileAvatarDialog
        open={showProfileAvatarDialog}
        setOpen={setShowProfileAvatarDialog}
      /> */}

      <ActionDialog
        open={showActionButton ? true : false}
        setOpen={setShowActionButton}
        title="Upload Images"
        // description={`Are you sure you want to upload these (${files?.length}) images?`}
        content={
          <>
            <PDDropzone
              required
              label="Add Images"
              value={files}
              setValue={setFiles}
              maxFiles={1}
            />
          </>
        }
        primaryButtonLabel="Upload"
        primaryButtonLoading={loading}
        primaryButtonOnClick={() => uploadImage()}
      />
    </Box>
  );
}
