import { Box } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";
import MyProfileSection from "./sections/MyProfileSection";
import ChangePasswordSection from "./sections/ChangePasswordSection";
import ConnectedAccountsSection from "./sections/ConnectedAccountsSection";
import CPanelContainer from "src/components/CPanelContainer";

export default function SettingsPage({ sx }) {
  const { bp } = useContext(ThemeContext);

  return (
    <CPanelContainer title="Settings">
      <Box
        className="content fullHeight"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          height: "100%",
          ...sx,
        }}
      >
        <MyProfileSection />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: bp?.sm ? "column" : "row",
            gap: 2,
            height: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ChangePasswordSection />
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: bp?.sm ? "100%" : 350,
            }}
          >
            <ConnectedAccountsSection />
          </Box>
        </Box>
      </Box>
    </CPanelContainer>
  );
}
