import { TabContext, TabPanel } from "@mui/lab";
import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";

import httpClient from "src/libs/httpClient";
import PDDateRangePicker from "src/components/_pdStyle/PDDateRangePicker";
import ProductTab from "./ProductTab";
import OverviewTab from "./OverviewTab";
import capitalizeWords from "src/libs/capitalizeWords";

export default function ViewStatisticsSection() {
  const { theme } = useContext(ThemeContext);
  const [viewType, setViewType] = useState(7);

  const [grouping, setGrouping] = useState("daily"); // ['daily', 'weekly', 'monthly'
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <Box
      sx={{
        border: `1px ${theme.borderColor} solid`,
        borderRadius: 1,
        backgroundColor: theme.ui,
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx>
          View Statistics ( {capitalizeWords(grouping)} )
        </Typography>

        <Box
          sx={{
            display: "flex",
            backgroundColor: "#E9EBFD99",
            padding: "3px",
            borderRadius: 1,
            gap: 0.5,
          }}
        >
          <PDDateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          {/* <TimeRangeButton
            selected={viewType === 7}
            onClick={() => setViewType(7)}
          >
            Week
          </TimeRangeButton>

          <TimeRangeButton
            selected={viewType === 30}
            onClick={() => setViewType(30)}
          >
            Month
          </TimeRangeButton>

          <TimeRangeButton
            selected={viewType === 365}
            onClick={() => setViewType(365)}
          >
            Year
          </TimeRangeButton> */}
        </Box>
      </Box>

      {/*  */}
      <CJTabs
        viewType={viewType}
        startDate={startDate}
        endDate={endDate}
        grouping={grouping}
        setGrouping={setGrouping}
      />
    </Box>
  );
}

const CJTabs = ({ viewType, startDate, endDate, grouping, setGrouping }) => {
  const { theme } = useContext(ThemeContext);
  const [tabIndex, setTabIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [totalViews, setTotalViews] = useState([]);

  return (
    <TabContext value={tabIndex}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { background: theme.accent.purple } }}
        >
          <Tab
            sx={{
              textTransform: "none",
              fontWeight: 600,
              color: `${
                tabIndex === 0 ? theme.textColor : theme.textColor3
              } !important`,
            }}
            label="Overview"
            value={0}
          />
          <Tab
            sx={{
              textTransform: "none",
              fontWeight: 600,
              color: `${
                tabIndex === 1 ? theme.textColor : theme.textColor3
              } !important`,
            }}
            label="Products"
            value={1}
          />
          <Tab
            sx={{
              textTransform: "none",
              fontWeight: 600,
              color: `${
                tabIndex === 2 ? theme.textColor : theme.textColor3
              } !important`,
            }}
            label="Stores"
            value={2}
          />
        </Tabs>
      </Box>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          sx={{
            margin: "20px 20px 0 20px",
            maxWidth: "calc(100% - 40px)",
            height: tabIndex === 0 ? 470 : 360,
          }}
        />
      ) : (
        <>
          <TabPanel value={0} style={{ padding: 0 }}>
            <OverviewTab
              startDate={startDate}
              endDate={endDate}
              setGrouping={setGrouping}
            />
          </TabPanel>
          <TabPanel value={1}>
            <ProductTab
              startDate={startDate}
              endDate={endDate}
              setGrouping={setGrouping}
            />
          </TabPanel>
          {/* <TabPanel value={2}>
            <JobStatisticsJobsAppliedTab
              viewType={viewType}
              jobView={totalViews?.jobView}
              jobApplied={totalViews?.jobApplied}
            />
          </TabPanel> */}
        </>
      )}
    </TabContext>
  );
};

// const TimeRangeButton = ({ children, onClick, selected }) => {
//   const { theme } = useContext(ThemeContext);

//   return (
//     <Button
//       sx={{
//         textTransform: "none",
//         fontWeight: 600,
//         backgroundColor: selected ? theme.buttonColor : "#E9EBFD",
//         color: selected ? theme.buttonTextColor : theme.textColor,
//         "&:hover": {
//           backgroundColor: selected ? theme.buttonColor + 99 : "#d9dcfc" + 99,
//         },
//       }}
//       onClick={onClick}
//     >
//       {children}
//     </Button>
//   );
// };
