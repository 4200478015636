import { Box, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import { useForm } from "react-hook-form";
import PDInput from "src/components/_pdStyle/PDInput";
import PDButton from "src/components/_pdStyle/PDButton";

// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const { loginWithEmail, signInWithGoogle, signInWithFacebook, loading } =
    useContext(AuthContext);
  const { theme, bp } = useContext(ThemeContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => loginWithEmail(data.email, data.password);

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: bp?.sm ? theme.ui : theme.backgroundColor,
        backgroundImage:
          "url(https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3269&q=100)",
        backgroundSize: "cover",
        backgroundPosition: "center center",

        minHeight: "max(100vh, 700px)",
      }}
    >
      <form
        className="container"
        style={{
          backgroundColor: theme.ui,
          height: bp?.sm ? "100vh" : "100%",
          maxWidth: bp?.sm ? "100%" : 450,
          width: "100%",
          padding: 16,
          border: bp?.sm ? "none" : `1px ${theme.borderColor} solid`,
          borderRadius: 4,
          gap: 24,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor, my: 4 }}
        >
          Login
        </Typography>
        <PDInput
          required
          type="email"
          label="Email"
          placeholder="Enter email here"
          form={{ ...register("email", { required: true }) }}
          error={errors.email}
          helperText={errors.email ? "Email is required" : ""}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <PDInput
            required
            type="password"
            label="Password"
            placeholder="Enter password here"
            form={{ ...register("password", { required: true }) }}
            error={errors.password}
            helperText={errors.password ? "Password is required" : ""}
          />

          <Link to="/forgot-password" style={{ marginTop: 8 }}>
            <Typography
              variant="p"
              sx={{
                transition: "all 0.1s ease-in-out",
                color: theme.textColor,
                "&:hover": { color: theme.buttonColor },
              }}
            >
              Forgot password?
            </Typography>
          </Link>
        </Box>
        <PDButton type="submit" loading={loading}>
          Login
        </PDButton>
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 40px 1fr",
            alignItems: "center",
          }}
        >
          <Divider sx={{ width: "100%" }} />
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ textAlign: "center", color: theme.textColor2 }}
          >
            OR
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </Box>
        <PDButton
          sx={{
            width: "100%",
            backgroundColor: "rgba(240,240,240)",
            color: theme.textColor,
            position: "relative",
          }}
          onClick={() => signInWithGoogle()}
        >
          <img
            style={{ height: 25, position: "absolute", left: 8 }}
            src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
            alt="google"
          />
          Login with Google
        </PDButton>

        {/* 
          appId={process.env.REACT_APP__FACEBOOK_OAUTH_CLIENT_ID}
          autoLoad={false}
          fields="first_name,last_name,email,picture.type(large)"
          callback={(e) => signInWithFacebook(e)}
          render={(renderProps) => ( 
        */}

        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID}
          fields="first_name,last_name,email,picture.type(large),pages_show_list"
          onSuccess={(response) => {
            console.log("Login Success!", response);
          }}
          onFail={(error) => {
            console.log("Login Failed!", error);
          }}
          onProfileSuccess={(response) => {
            console.log("Get Profile Success!", response);
            signInWithFacebook(response);
          }}
          render={({ onClick, logout }) => (
            <PDButton
              sx={{
                width: "100%",
                backgroundColor: "rgba(240,240,240)",
                color: theme.textColor,
                position: "relative",
              }}
              onClick={() => onClick()}
            >
              <img
                style={{ height: 25, position: "absolute", left: 8 }}
                src="https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
                alt="google"
              />
              Login with Facebook
            </PDButton>
          )}
        />
        {/* )} /> */}
        <Box sx={{ my: 2, display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="p" sx={{ color: theme.textColor2 }}>
            Don&apos;t have an account?{" "}
          </Typography>
          <Link to="/signup" style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="p"
              sx={{
                color: theme.buttonColor,
                fontWeight: 600,
              }}
            >
              Join Now
            </Typography>
          </Link>
        </Box>
      </form>
    </Box>
  );
}
