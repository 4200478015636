import { ButtonBase, Menu, Typography } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { ThemeContext } from "src/context";

import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file

export default function PDDateRangePicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const { theme, bp } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  // Initialize date range with default or existing values
  const initialRange = {
    startDate: startDate || moment().subtract(7, "days").toDate(),
    endDate: endDate || moment().toDate(),
    key: "selection",
  };

  const [state, setState] = useState([initialRange]);
  const [dateDisplay, setDateDisplay] = useState({
    start: moment(initialRange.startDate).format("DD/MM/YYYY"),
    end: moment(initialRange.endDate).format("DD/MM/YYYY"),
  });

  useEffect(() => {
    if (state[0].startDate !== startDate) {
      setStartDate(state[0].startDate);
      setDateDisplay((prev) => ({
        ...prev,
        start: moment(state[0].startDate).format("DD/MM/YYYY"),
      }));
    }

    if (state[0].endDate !== endDate) {
      setEndDate(state[0].endDate);
      setDateDisplay((prev) => ({
        ...prev,
        end: moment(state[0].endDate).format("DD/MM/YYYY"),
      }));
    }
  }, [state, setStartDate, setEndDate]);

  const handleOnChange = (ranges) => {
    setState([ranges.selection]);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase
        sx={{
          borderRadius: 1,
          backgroundColor: "#d9dcfc",
          padding: "8px 16px",
          whiteSpace: "nowrap",
          width: "100%",
        }}
        aria-owns={anchorEl ? "more-menu" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <Typography
          variant="body_small_400"
          sx={{ color: theme.textColor3, paddingRight: 10 }}
        >
          {dateDisplay.start} - {dateDisplay.end}
        </Typography>
        <TodayRoundedIcon
          sx={{ fontSize: "1.25rem", color: theme.buttonColor }}
        />
      </ButtonBase>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: 225,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <DateRangePicker
          onChange={handleOnChange}
          months={1}
          ranges={state}
          direction="horizontal"
          rangeColors={["#2E5BFF"]}
        />
      </Menu>
    </>
  );
}
