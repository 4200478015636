import { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import ActionButtons from "../../../components/FullScreenDialogActionButtons";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";

export default function ContactDetailsStep({
  open,
  store,
  setStore,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (open && store) {
  //     setValue("number", store?.number);
  //     setValue("email", store?.email);
  //   }
  // }, [open, store]);
  useEffect(() => {
    if (store) {
      setValue("number", store?.number);
      setValue("email", store?.email);
    }
  }, [store]);

  const onSubmit = (data) => {
    setLoading(true);
    httpClient()
      .put(`/sellers/stores/${store?._id}`, data)
      .then((res) => {
        setActiveStep((e) => e + 1);
        console.log(data);
        setStore(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SmartphoneRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          How will customers contact you?
        </Typography>

        <PDInput
          type="number"
          required
          label="Contact Number"
          placeholder="Enter your phone number"
          maxlength={11}
          form={{
            ...register("number", {
              required: {
                value: true,
                message: "Number is required",
              },
              pattern: {
                value: /^(?:(?:\+|00)88|01)?\d{11}$/,
                message:
                  "The number must start with 01 and be 11 characters long",
              },
              maxLength: {
                value: 11,
                message: "The maximum length is 11",
              },
            }),
          }}
          error={errors.number}
          helperText={errors.number && errors.number.message}
        />

        <PDInput
          required
          type="email"
          label="Email"
          placeholder="Enter email here"
          form={{
            ...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Please enter a valid email address.",
              },
              maxLength: {
                value: 75,
                message: "The maximum length is 75",
              },
            }),
          }}
          error={errors?.email}
          helperText={errors?.email?.message}
        />
      </Box>

      <ActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
      />
    </Box>
  );
}
