import { Box, Typography } from "@mui/material";
// import Link from "next/link";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import httpClient from "src/libs/httpClient";
import { AuthContext, ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";
import { useSnackbar } from "notistack";
import PDInput from "src/components/_pdStyle/PDInput";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import { Link, useParams } from "react-router-dom";

export default function ConfirmEmailPage() {
  // const { query } = useRouter();
  const query = useParams();

  const { theme, bp } = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const { saveUserData } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("email", query?.email);
  }, [query?.email]);

  const [token, setToken] = useState("");

  const [loadingConfirmEmail, setLoadingConfirmEmail] = useState(false);
  const [showTokenInput, setShowTokenInput] = useState(false);
  const handleConfirmEmail = async () => {
    if (token.length < 6) {
      enqueueSnackbar(
        "Please enter a valid token. Token must be at least 6 characters",
        { variant: "error" }
      );
      return;
    }

    if (loadingConfirmEmail) return;

    setLoadingConfirmEmail(true);
    httpClient()
      .post(`/auth/validation/confirm/${token}`)
      .then((res) => {
        console.log("handleConfirmEmail : ", res.data);
        saveUserData(res.data);
        setLoadingConfirmEmail(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingConfirmEmail(false);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message),
          { variant: "error" }
        );
      });
  };

  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(true);
  const handleResendCode = async (e) => {
    if (loadingResendCode) return;

    setLoadingResendCode(true);
    httpClient()
      .get(`/auth/validation/request/${e?.email}`)
      .then((res) => {
        console.log("handleResendCode : ", res.data);
        enqueueSnackbar("Email sent successfully. Please check your inbox.", {
          variant: "success",
        });
        setLoadingResendCode(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingResendCode(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: bp?.sm ? theme.ui : theme.backgroundColor,
        backgroundImage:
          "url(https://images.unsplash.com/photo-1524678606370-a47ad25cb82a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3269&q=100)",
        backgroundSize: "cover",
        backgroundPosition: "center center",

        minHeight: "max(100vh, 700px)",
      }}
    >
      <form
        className="container"
        style={{
          backgroundColor: theme.ui,
          height: bp?.sm ? "100vh" : "100%",
          maxWidth: bp?.sm ? "100%" : 450,
          width: "100%",
          padding: 16,
          border: bp?.sm ? "none" : `1px ${theme.borderColor} solid`,
          borderRadius: 4,
          gap: 24,
        }}
        onSubmit={(e) => e?.preventDefault && e?.preventDefault()}
      >
        <Box
          sx={{
            my: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{ color: theme.textColor }}
          >
            Confirm your email
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            sx={{ color: theme.textColor3 }}
          >
            Please enter the 6-digit code from your email inbox
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 3,
          }}
        >
          <PDInput
            disabled={query?.email}
            required
            type="email"
            label="Email"
            placeholder="Enter email here"
            form={{ ...register("email", { required: true }) }}
            error={errors.email}
            helperText={errors.email ? "Email is required" : ""}
          />

          <PDInput
            required
            label="Verification code"
            placeholder="XXXXXX"
            value={token}
            onChange={(e) => {
              if (e.target.value?.length <= 6)
                setToken(e.target.value.toUpperCase());
            }}
          />
        </Box>

        <PDButton
          // type="submit"
          loading={loadingConfirmEmail}
          disabled={loadingConfirmEmail}
          onClick={() => {
            if (showEmailInput) {
              console.log("Confirm Email Button Clicked");
              handleConfirmEmail();
            } else {
              setShowEmailInput(true);
              setShowTokenInput(false);
            }
          }}
        >
          Confirm
        </PDButton>
        <PDButton
          sx={{
            backgroundColor: theme.backgroundColor2,
            color: theme.textColor + "BF",
          }}
          loading={loadingResendCode}
          disabled={loadingResendCode}
          onClick={
            showTokenInput
              ? handleSubmit(handleResendCode)
              : () => {
                  setShowTokenInput(true);
                  setShowEmailInput(false);
                }
          }
        >
          Resend Code
        </PDButton>

        <Link to="/login" style={{ padding: "8px 0 16px 0" }}>
          <Typography
            variant="p"
            sx={{
              color: theme.textColor3,
              transition: "all .2s ease-in-out",
              "&:hover": {
                color: theme.buttonColor,
              },
            }}
          >
            Back to Login
          </Typography>
        </Link>
      </form>
    </Box>
  );
}
