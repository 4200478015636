import { Button, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";

export default function PDButton(props) {
  const { theme } = useContext(ThemeContext);

  const { loading, sx, variant, ...otherProps } = props;

  const primaryStyle = {
    width: "100%",
    height: 40,

    boxShadow: "none",
    transition: "all .2s ease-in-out",
  };
  const outlineStyle = {
    ...primaryStyle,

    backgroundColor: sx?.backgroundColor
      ? sx.backgroundColor + 22
      : theme.buttonColor + 22,
    color: sx?.color || theme.buttonColor,

    "&:hover": {
      backgroundColor: sx?.backgroundColor
        ? sx.backgroundColor + 44
        : theme.buttonColor + 44,
      boxShadow: "none",
      filter: "invert(15%)",
    },

    "&:disabled": {
      backgroundColor: theme.buttonColor + 22,
      color: theme.buttonTextColor + 22,
    },
    ...sx,
  };
  const normalStyle = {
    ...primaryStyle,
    backgroundColor: sx?.backgroundColor || theme.buttonColor,
    color: sx?.color || theme.buttonTextColor,

    "&:hover": {
      backgroundColor: sx?.backgroundColor || theme.buttonColor,
      boxShadow: "none",
      filter: "invert(15%)",
    },

    "&:disabled": {
      backgroundColor: theme.textColor + 22,
      color: theme.textColor + 99,
    },
    ...sx,
  };

  return (
    <Button
      // style={{ margin: "0px" }}
      sx={variant === "outline" ? outlineStyle : normalStyle}
      //   color="primary"
      //   variant="contained"
      //   autoFocus
      {...otherProps}
      //   loading={true}
    >
      {loading ? (
        <CircularProgress
          sx={{ color: sx?.color || "#FFF" }}
          thickness={7}
          size={18}
        />
      ) : (
        props.children
      )}
    </Button>
  );
}
