import { Bar, Line } from "react-chartjs-2";
import { Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";
import { DataBox } from "./JobStatisticsOverviewTab";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

export default function OverviewTab({ startDate, endDate }) {
  const max580 = useMediaQuery("(max-width:580px)");

  const [data, setData] = useState();

  useEffect(() => {
    if (!startDate || !endDate) return;
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    httpClient()
      .get(
        `/admin/analytics/view?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      )
      .then((res) => {
        console.log("Res from getData : ", res.data);
        // setTotalViews(res.data);
        const data = res.data;

        const labels = data.map((entry) => entry._id);
        const datasets = [
          {
            label: "Products",
            data: data.map((entry) =>
              entry.views
                .filter((v) => v.type === "product")
                .reduce((acc, v) => acc + v.totalViews, 0)
            ),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            stack: "Products",
          },
          {
            label: "Stores",
            data: data.map((entry) =>
              entry.views
                .filter((v) => v.type === "store")
                .reduce((acc, v) => acc + v.totalViews, 0)
            ),
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            stack: "Stores",
          },
        ];

        setData({ labels, datasets });
      })
      .catch((err) => {
        console.log("Error from getData : ", err.message);
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: max580 ? "column" : "row" }}>
      <div style={{ flex: 1 }}>
        <div style={{ paddingBottom: 20 }} />
        {data?.datasets?.length > 0 ? (
          <CustomChart data={data} />
        ) : (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{
              margin: "20px 20px 0 20px",
              maxWidth: "calc(100% - 40px)",
              height: 360,
            }}
          />
        )}

        {/* <div
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            padding: 20,
          }}
        >
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <div
              style={{
                backgroundColor: theme.accent.yellow,
                width: 20,
                height: 20,
                borderRadius: 5,
              }}
            />
            <Typography
              variant="button_small"
              style={{ color: theme.textColor2 }}
            >
              Job Applied
            </Typography>
          </div>
        </div> */}
      </div>
      <div
        style={{
          padding: 20,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <DataBox
          label="Total Views"
          value={data?.datasets?.reduce(
            (acc, dataset) => acc + dataset.data.reduce((acc, v) => acc + v, 0),
            0
          )}
          icon={
            <RemoveRedEyeOutlinedIcon
              style={{
                color: "#FFF",
                fontSize: "1.25rem",
              }}
            />
          }
          iconColor="#FFB836"
        />
        <DataBox
          label="Products"
          value={
            data?.datasets
              ?.find((dataset) => dataset.label === "Products")
              ?.data.reduce((acc, v) => acc + v, 0) || 0
          }
          icon={
            <RemoveRedEyeOutlinedIcon
              style={{
                color: "#FFF",
                fontSize: "1.25rem",
              }}
            />
          }
          iconColor="#FFB836"
        />
        <DataBox
          label="Stores"
          value={
            data?.datasets
              ?.find((dataset) => dataset.label === "Stores")
              ?.data.reduce((acc, v) => acc + v, 0) || 0
          }
          icon={
            <RemoveRedEyeOutlinedIcon
              style={{
                color: "#FFF",
                fontSize: "1.25rem",
              }}
            />
          }
          iconColor="#FFB836"
        />
      </div>
    </div>
  );
}

const CustomChart = ({ data }) => {
  const config = {
    type: "bar",
    data: data,
    options: {
      plugins: {
        title: {
          display: true,
          text: "Chart.js Bar Chart - Stacked",
        },
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
  };

  // accumulateDataForLine should be a funtion that is same as data but its datasets valus should be this value + previous value
  const accumulateDataForLine = (data) => {
    const datasets = data.datasets.map((dataset) => {
      const data = dataset.data.map((d, i) => {
        return dataset.data.slice(0, i + 1).reduce((acc, v) => acc + v, 0);
      });
      return { ...dataset, data };
    });

    return { ...data, datasets };
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <div>
        <Bar options={config?.options} data={data} />
      </div>
      <div>
        <Line options={config?.options} data={accumulateDataForLine(data)} />
      </div>
    </div>
  );
};
