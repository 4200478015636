import { useContext, useEffect, useState } from "react";
import { Box, Checkbox, Fade, Typography } from "@mui/material";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";

import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import { useForm } from "react-hook-form";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import FullScreenDialogActionButtons from "src/components/FullScreenDialogActionButtons";
import PDSelect from "src/components/_pdStyle/PDSelect";

export default function DeliveryStep({
  open,
  product,
  setProduct,

  activeStep,
  setActiveStep,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [store, setStore] = useState();
  const [loadingStore, setLoadingStore] = useState(false);

  const [selectedDeliveryProvider, setSelectedDeliveryProvider] = useState(
    deliveryProvider[1]
  );
  const [selectedDeliveryProviderError, setSelectedDeliveryProviderError] =
    useState(false);
  const [isOutsideCityDeliveryEnabled, setIsOutsideCityDeliveryEnabled] =
    useState(
      product?.delivery?.isOutsideCityDeliveryEnabled ||
        store?.delivery?.isOutsideCityDeliveryEnabled ||
        false
    );

  useEffect(() => {
    if (open && product) {
      setValue("boxContent", product?.boxContent);
      setValue("packageWeight", product?.packageWeight);
      setValue("length", product?.dimension?.length);
      setValue("width", product?.dimension?.width);
      setValue("height", product?.dimension?.height);

      setSelectedDeliveryProvider({
        label: product?.delivery?.deliveryProvider,
        value: product?.delivery?.deliveryProvider,
      });
      setValue(
        "insideCityDeliveryFee",
        product?.delivery?.insideCityDeliveryFee
      );
      setIsOutsideCityDeliveryEnabled(
        product?.delivery?.isOutsideCityDeliveryEnabled
      );
      setValue(
        "outsideCityDeliveryFee",
        product?.delivery?.outsideCityDeliveryFee
      );
      setValue("deliveryTime", product?.delivery?.deliveryTime);
      setValue("maxReturnTime", product?.maxReturnTime);
      setValue("warranty", product?.warranty);
    }
  }, [open, product]);

  useEffect(() => {
    if (open && !store && !product?.delivery?.deliveryProvider) {
      setLoadingStore(true);
      httpClient()
        .get(`/sellers/stores/${product?.store}`)
        .then((res) => {
          console.log("Get the store: ", res.data);
          setStore(res.data);

          setSelectedDeliveryProvider({
            label: res.data?.delivery?.deliveryProvider,
            value: res.data?.delivery?.deliveryProvider,
          });
          setValue(
            "insideCityDeliveryFee",
            res.data?.delivery?.insideCityDeliveryFee
          );
          setIsOutsideCityDeliveryEnabled(
            res.data?.delivery?.isOutsideCityDeliveryEnabled
          );
          setValue(
            "outsideCityDeliveryFee",
            res.data?.delivery?.outsideCityDeliveryFee
          );

          setLoadingStore(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingStore(false);
        });
    }
  }, [open]);

  const onSubmit = (data) => {
    const dataToSend = {
      boxContent: data.boxContent,
      packageWeight: data.packageWeight,
      dimension: {
        length: data.length,
        width: data.width,
        height: data.height,
      },

      delivery: {
        deliveryProvider: selectedDeliveryProvider.value,
        isOutsideCityDeliveryEnabled: isOutsideCityDeliveryEnabled,
        insideCityDeliveryFee: data.insideCityDeliveryFee,
        outsideCityDeliveryFee: data.outsideCityDeliveryFee,

        deliveryTime: data.deliveryTime,
      },

      maxReturnTime: data.maxReturnTime,
      warranty: data.warranty,
    };

    console.log("Data : ", dataToSend);

    setLoading(true);
    httpClient()
      .put(`/sellers/products/${product?._id}`, dataToSend)
      .then((res) => {
        setActiveStep((e) => e + 1);
        setProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        width: "100%",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        mb: 1,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.textColor,
            border: `1px ${theme.borderColor} solid`,
            borderRadius: 1,
            height: 50,
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SmartphoneRoundedIcon sx={{ color: theme.buttonColor }} />
        </Box>

        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor }}
        >
          How do you want to deliver?
        </Typography>

        <PDInput
          label="Box Content"
          placeholder="Enter the box content"
          form={{
            ...register("boxContent"),
          }}
          error={errors?.boxContent}
          helperText={errors?.boxContent?.message}
        />

        <PDInput
          type="number"
          label="Package Weight (g)"
          placeholder="Enter the weight in gram (g)"
          form={{
            ...register("packageWeight"),
          }}
          error={errors?.packageWeight}
          helperText={errors?.packageWeight?.message}
        />

        <Box>
          <Typography
            variant="h5"
            sx={{
              color: theme.textColor2,
              fontWeight: 600,
              mt: 4,
              mb: 2,
            }}
          >
            Dimension:
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <PDInput
              type="number"
              label="Length (cm)"
              placeholder="Length (cm)"
              form={{
                ...register("length"),
              }}
              error={errors?.length}
              helperText={errors?.length?.message}
            />
            <PDInput
              type="number"
              label="Width (cm)"
              placeholder="Width (cm)"
              form={{
                ...register("width"),
              }}
              error={errors?.width}
              helperText={errors?.width?.message}
            />
            <PDInput
              type="number"
              label="Height (cm)"
              placeholder="Height (cm)"
              form={{
                ...register("height"),
              }}
              error={errors?.height}
              helperText={errors?.height?.message}
            />
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h5"
            sx={{
              color: theme.textColor2,
              fontWeight: 600,
              mt: 4,
              mb: 2,
            }}
          >
            Delivery:
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <PDSelect
              required
              label="Delivery Provider"
              placeholder="Select type"
              defaultValue={deliveryProvider[1]}
              options={deliveryProvider}
              //
              value={selectedDeliveryProvider}
              onChange={(e) => {
                setSelectedDeliveryProvider(e);
                setSelectedDeliveryProviderError(false);
              }}
              error={selectedDeliveryProviderError}
              helperText={
                selectedDeliveryProviderError && "Delivery provider is required"
              }
            />

            <PDInput
              type="number"
              required
              label="Delivery Fee (Inside City)"
              placeholder="60 BDT"
              form={{
                ...register("insideCityDeliveryFee", {
                  required: {
                    value: true,
                    message: "Delivery fee is required",
                  },
                  max: {
                    value: 1000,
                    message: "Fee must be less than or equal to 1,000 BDT",
                  },
                  min: {
                    value: 60,
                    message: "Fee must be greater than or equal to 60 BDT",
                  },
                }),
              }}
              error={errors.insideCityDeliveryFee}
              helperText={errors?.insideCityDeliveryFee?.message}
            />

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  color={theme.textColor2}
                >
                  Delivery Outside Dhaka
                </Typography>

                <Checkbox
                  sx={{ mr: -1.5 }}
                  checked={isOutsideCityDeliveryEnabled}
                  onChange={(e) =>
                    setIsOutsideCityDeliveryEnabled(e.target.checked)
                  }
                />
              </Box>
              <Fade
                in={isOutsideCityDeliveryEnabled}
                mountOnEnter
                unmountOnExit
              >
                <Box sx={{ width: "100%" }}>
                  <PDInput
                    type="number"
                    required
                    label="Delivery Fee (Outside City)"
                    placeholder="120 BDT"
                    form={{
                      ...register("outsideCityDeliveryFee", {
                        required: {
                          value: isOutsideCityDeliveryEnabled,
                          message: "Delivery fee is required",
                        },
                        max: {
                          value: 1000,
                          message:
                            "Fee must be less than or equal to 1,000 BDT",
                        },
                        min: {
                          value: 60,
                          message:
                            "Fee must be greater than or equal to 60 BDT",
                        },
                      }),
                    }}
                    error={errors.outsideCityDeliveryFee}
                    helperText={errors?.outsideCityDeliveryFee?.message}
                  />
                </Box>
              </Fade>
            </Box>

            <PDInput
              type="number"
              label="Delivery Time (day)"
              placeholder="Delivery Time (day)"
              form={{
                ...register("deliveryTime"),
              }}
              error={errors?.deliveryTime}
              helperText={errors?.deliveryTime?.message}
            />

            <PDInput
              type="number"
              label="Max Return Time (day)"
              placeholder="Max Return Time (day)"
              form={{
                ...register("maxReturnTime"),
              }}
              error={errors?.maxReturnTime}
              helperText={errors?.maxReturnTime?.message}
            />

            <PDInput
              type="number"
              label="Warranty (day)"
              placeholder="Warranty (day)"
              form={{
                ...register("warranty"),
              }}
              error={errors?.warranty}
              helperText={errors?.warranty?.message}
            />

            <Box />
          </Box>
        </Box>
      </Box>

      <FullScreenDialogActionButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
      />
    </Box>
  );
}

const deliveryProvider = [
  { label: "personal", value: "personal" },
  { label: "redx", value: "redx" },
  { label: "pathao", value: "pathao" },
  { label: "dokan.gg", value: "dokan.gg" },
];
