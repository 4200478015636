import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import uploadPhoto from "src/libs/uploadPhoto";
import slugify from "src/libs/slugify";
import httpClient from "src/libs/httpClient";
import PDSelect from "src/components/_pdStyle/PDSelect";
import addToObject from "src/libs/addToObject";
import deletePhoto from "src/libs/deletePhoto";

export default function AddCategoryDialog({
  open,
  setOpen,
  getData,
  categoriesRow,
  currentCategory,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { theme } = useContext(ThemeContext);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const name = useRef({});
  name.current = watch("name", "");

  useEffect(() => {
    if (categoriesRow) {
      setCategories([]);
      categoriesRow?.map((item) => {
        setCategories((cat) => [...cat, { label: item.name, value: item }]);
      });
    }
  }, [categoriesRow]);

  useEffect(() => {
    if (currentCategory && open) {
      setValue("name", currentCategory?.name);
      if (categories?.length > 0) {
        console.log(currentCategory?.parent);
        setSelectedCategory({
          label: currentCategory?.parent?.name,
          value: currentCategory?.parent,
        });
      }
    }
  }, [currentCategory, open, categories]);

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    if (icon.length > 0) {
      handleUploadImage(data.name);
    } else {
      handleAddCategory(data.name);
    }
  };

  const handleUploadImage = async (name) => {
    await uploadPhoto(icon[0], `categories/${slugify(name)}`, {
      maxWidth: 512,
      maxHeight: 512,
    })
      .then((data) => {
        setLoading(true);
        console.log(data);
        handleAddCategory(name, data);
      })
      .catch((err) => {
        console.error("Error uploading photos: ", err);
        setLoading(false);
        // Handle the error here
      });
  };

  const handleAddCategory = (name, iconUrl) => {
    let data = {
      name,
      slug: slugify(name),
    };

    if (iconUrl) data = addToObject(data, "icon", iconUrl);

    if (selectedCategory?.value?._id)
      data = addToObject(data, "parent", selectedCategory?.value?._id);

    setLoading(true);

    if (currentCategory) {
      httpClient()
        .put(`/admin/categories/${currentCategory?._id}`, data)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setOpen(false);
          reset();
          setIcon([]);
          setSelectedCategory();
          getData();
          enqueueSnackbar("Category updated successfully.", {
            variant: "info",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            err?.response?.data?.message || "Faild to update category",
            {
              variant: "error",
            }
          );
        });
    } else {
      httpClient()
        .post(`/admin/categories`, data)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setOpen(false);
          reset();
          setIcon([]);
          getData();
          setSelectedCategory();
          enqueueSnackbar("Category added successfully.", { variant: "info" });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          enqueueSnackbar(
            err?.response?.data?.message || "Faild to add category",
            {
              variant: "error",
            }
          );
        });
    }
  };

  function handleDelete() {
    setLoading(true);

    deletePhoto(selectedCategory?.icon)
      .then((data) => {
        setLoading(true);

        alert("YOU NEED TO FIX IT");
        // httpClient()
        //   .put(`sellers/stores/${store?._id}`, { icon: "" })
        //   .then((res) => {
        //     console.log(res);
        //     setLoading(false);
        //     enqueueSnackbar(`Image deleted successfully`, {
        //       variant: "success",
        //     });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setLoading(false);
        //     // enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
        //   });
      })
      .catch((err) => {
        console.log(err, err.stack);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        {`${currentCategory ? "Update" : "Add"} Category`}
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        {/*  */}
        <PDDropzone
          label="Logo"
          maxFiles={1}
          value={icon}
          setValue={setIcon}
          oldFiles={currentCategory?.icon && [currentCategory?.icon]}
          fileToDelete={(e) => handleDelete()}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <PDInput
            required
            label="Name"
            placeholder="Enter name here"
            form={{ ...register("name", { required: true }) }}
            error={errors.name}
            helperText={errors.name ? "Name is required" : ""}
          />

          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ color: theme.textColor2, fontWeight: 500 }}
            >
              Slug:
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: theme.textColor3, fontWeight: 400 }}
            >
              {slugify(name.current || "")}
            </Typography>
          </Box>
        </Box>

        <PDSelect
          label="Parent Category"
          sx={{ backgroundColor: theme.ui }}
          options={categories}
          value={selectedCategory}
          onChange={setSelectedCategory}
        />

        {/*  */}
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => {
              setOpen((e) => !e);
            }}
          >
            Cancel
          </PDButton>
          <PDButton loading={loading} type="submit">
            {currentCategory ? "Update" : "Add"}
          </PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
