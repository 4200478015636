import { Box, Fade, Pagination } from "@mui/material";
import PDSelect from "./PDSelect";
import { ThemeContext } from "src/context";
import { useContext } from "react";

export default function PDPagiation({
  pageCount,
  totalCount,
  setPage,
  perPage,
  setPerPage,
  options,
}) {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: bp?.sm ? "column-reverse" : "row",
      }}
    >
      {totalCount > 5 ? (
        <Box sx={{ maxWidth: 100 }}>
          <PDSelect
            sx={{ backgroundColor: theme.ui }}
            options={
              options?.length > 0
                ? options?.map((i) => {
                    return { label: i, value: i };
                  })
                : [
                    { label: 5, value: 5 },
                    { label: 10, value: 10 },
                    { label: 15, value: 15 },
                    { label: 30, value: 30 },
                    { label: 50, value: 50 },
                  ]
            }
            //
            value={{ label: parseInt(perPage), value: parseInt(perPage) }}
            onChange={(e) => {
              setPerPage(parseInt(e.value));
              setPage(1);
            }}
          />
        </Box>
      ) : (
        <Box sx={{ width: 100 }} />
      )}

      <Fade in={pageCount > 1}>
        <div>
          <Pagination
            count={pageCount}
            shape="rounded"
            color="primary"
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </div>
      </Fade>
      <div style={{ minWidth: 75 }} />
    </div>
  );
}
