import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";
import { SketchPicker } from "react-color";
import PDInput from "src/components/_pdStyle/PDInput";

export default function ColorPickerDialog({
  open,
  setOpen,
  selectedColor,
  setColors,
}) {
  const { theme } = useContext(ThemeContext);

  const [colorItem, setColorItem] = useState("#FFFFFF");
  const [imageIndex, setImageIndex] = useState();

  useEffect(() => {
    if (selectedColor) {
      setColorItem(selectedColor?.color);
      setImageIndex(selectedColor?.imageIndex);
    }
  }, [selectedColor]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        Color Picker
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Box
          Box
          sx={{
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            sx={{ color: theme.textColor2 }}
          >
            Colors
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SketchPicker
              color={colorItem}
              onChangeComplete={(e) => setColorItem(e?.hex)}
            />
          </Box>
        </Box>

        <PDInput
          label="Image Index"
          placeholder="1"
          value={imageIndex}
          onChange={(e) => setImageIndex(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <PDButton
            sx={{
              color: "#bc002d",
              backgroundColor: "#fcf5f7",
              "&:hover": {
                backgroundColor: "#bc002d11",
              },
            }}
            onClick={() => {
              if (selectedColor) {
                setColors((oldArray) =>
                  oldArray.filter((e) => e !== selectedColor)
                );
              }
              setOpen((e) => !e);
            }}
          >
            {selectedColor ? "Delete" : "Cancel"}
          </PDButton>
          <PDButton
            onClick={() => {
              setColors((oldArray) =>
                oldArray.filter((e) => e !== selectedColor)
              );

              setColors((oldArray) => [
                ...oldArray,
                { color: colorItem, imageIndex },
              ]);
              setOpen(false);
            }}
          >
            Add
          </PDButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
