function isURL(value = "") {
  var urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-zA-Z]{2,})(\/\S*)?$/i;
  if (urlPattern.test(value)) {
    return true;
  } else {
    return false;
  }
}

export default isURL;
