import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import { useForm } from "react-hook-form";
import PDInput from "src/components/_pdStyle/PDInput";
import PDButton from "src/components/_pdStyle/PDButton";
// import Link from "next/link";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { Link } from "react-router-dom";

export default function SellerSignupPage() {
  const { theme, bp } = useContext(ThemeContext);
  const { signUpWithEmail, signInWithGoogle, signInWithFacebook, loading } =
    useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    signUpWithEmail(
      data.email,
      data.password,
      data.firstName,
      data.lastName,
      "seller"
    );
  };

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: bp?.sm ? theme.ui : theme.backgroundColor,
        backgroundImage:
          "url(https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=100)",
        backgroundSize: "cover",
        backgroundPosition: "center center",

        minHeight: "max(100vh, 700px)",
      }}
    >
      <form
        className="container"
        style={{
          backgroundColor: theme.ui,
          height: bp?.sm ? "100vh" : "100%",
          maxWidth: bp?.sm ? "100%" : 450,
          width: "100%",
          padding: 16,
          border: bp?.sm ? "none" : `1px ${theme.borderColor} solid`,
          borderRadius: 4,
          gap: 24,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          sx={{ color: theme.textColor, my: 4 }}
        >
          Signup
        </Typography>

        <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
          <PDInput
            required
            label="First Name"
            placeholder="Enter first name here"
            form={{ ...register("firstName", { required: true }) }}
            error={errors.firstName}
            helperText={errors.firstName ? "First Name is required" : ""}
          />
          <PDInput
            required
            label="Last Name"
            placeholder="Enter last name here"
            form={{ ...register("lastName", { required: true }) }}
            error={errors.lastName}
            helperText={errors.lastName ? "Last Name is required" : ""}
          />
        </Box>

        <PDInput
          required
          type="email"
          label="Email"
          placeholder="Enter email here"
          form={{ ...register("email", { required: true }) }}
          error={errors.email}
          helperText={errors.email ? "Email is required" : ""}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <PDInput
            required
            type="password"
            label="Password"
            placeholder="Enter password here"
            form={{ ...register("password", { required: true }) }}
            error={errors.password}
            helperText={errors.password ? "Password is required" : ""}
          />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              sx={{ mr: 0 }}
              control={
                <Checkbox
                  {...register("acceptEUlA", { required: true })}
                  color="primary"
                />
              }
              label={
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{ color: theme.textColor }}
                >
                  I accept the EULA.{" "}
                </Typography>
              }
            />

            <Link
              to="/terms-and-conditions"
              passHref
              target="_blank"
              style={{
                paddingBottom: 2,
                paddingLeft: 6,
                textDecoration: "none",
                color: theme.buttonColor,
                textTransform: "none",
                fontWeight: 500,
              }}
            >
              Terms of Use
            </Link>

            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ px: 1, paddingBottom: "2px", color: theme.textColor }}
            >
              and
            </Typography>

            <Link
              to="/privacy-policy"
              passHref
              target="_blank"
              style={{
                paddingBottom: 2,
                textDecoration: "none",
                color: theme.buttonColor,
                textTransform: "none",
                fontWeight: 500,
              }}
            >
              Privacy Policy
            </Link>
          </Box>

          <PDButton type="submit" loading={loading}>
            Signup
          </PDButton>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 40px 1fr",
            alignItems: "center",
          }}
        >
          <Divider sx={{ width: "100%" }} />
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ textAlign: "center", color: theme.textColor2 }}
          >
            OR
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </Box>

        <PDButton
          sx={{
            width: "100%",
            backgroundColor: "rgba(240,240,240)",
            color: theme.textColor,
            position: "relative",
          }}
          onClick={() => signInWithGoogle()}
        >
          <img
            style={{ height: 25, position: "absolute", left: 8 }}
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            alt="google"
          />
          Signup with Google
        </PDButton>

        {/* <FacebookLogin
          appId={process.env.env.REACT_APP__FACEBOOK_OAUTH_CLIENT_ID}
          autoLoad={false}
          fields="first_name,last_name,email,picture.type(large)"
          callback={(e) => signInWithFacebook({ ...e, role: "seller" })}
          render={(renderProps) => ( */}
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID}
          onSuccess={(response) => {
            console.log("Login Success!");
          }}
          onFail={(error) => {
            console.log("Login Failed!", error);
          }}
          onProfileSuccess={(response) => {
            console.log("Get Profile Success!");
            signInWithFacebook(response);
          }}
          render={({ onClick, logout }) => (
            <PDButton
              sx={{
                width: "100%",
                backgroundColor: "rgba(240,240,240)",
                color: theme.textColor,
                position: "relative",
              }}
              // onClick={renderProps.onClick}>
              onClick={() => onClick()}
            >
              <img
                style={{ height: 25, position: "absolute", left: 8 }}
                src="https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
                alt="google"
              />
              Signup with Facebook
            </PDButton>
          )}
        />

        <Box sx={{ my: 2, display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="p" sx={{ color: theme.textColor2 }}>
            Already have an account?{" "}
          </Typography>
          <Link to="/login" style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="p"
              sx={{
                color: theme.buttonColor,
                fontWeight: 600,
              }}
            >
              Login
            </Typography>
          </Link>
        </Box>
      </form>
    </Box>
  );
}
