import { useContext, useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { useDebounce } from "use-debounce";
import { stringify } from "querystring";
import { Box } from "@mui/material";
import { ThemeContext } from "src/context";
import PDInput from "src/components/_pdStyle/PDInput";
import OrdersTable from "./_components/OrdersTable";
import PDPagiation from "src/components/_pdStyle/PDPagiation";
import CPanelContainer from "src/components/CPanelContainer";

export default function OrdersPage() {
  const { theme } = useContext(ThemeContext);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [search, setSearch] = useState("");
  const [searchDebounce] = useDebounce(search, 250);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const query = {
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        search: searchDebounce !== undefined ? searchDebounce : "",
      }),
    };

    httpClient()
      .get(`/sellers/orders?${stringify(query)}`)
      .then((res) => {
        let data = res?.data?.result || [];

        setOrders(data);

        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <CPanelContainer title="Orders">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PDInput
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
      </Box>

      <OrdersTable
        orders={orders}
        loading={loading}
        perPage={perPage}
        getData={getData}
      />

      <PDPagiation
        pageCount={pageCount}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </CPanelContainer>
  );
}
